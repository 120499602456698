import { render, staticRenderFns } from "./locale.vue?vue&type=template&id=5c48f1f4&scoped=true&"
import script from "./locale.vue?vue&type=script&lang=js&"
export * from "./locale.vue?vue&type=script&lang=js&"
import style0 from "./locale.vue?vue&type=style&index=0&id=5c48f1f4&lang=scss&scoped=true&"
import style1 from "./locale.vue?vue&type=style&index=1&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5c48f1f4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VList,VListItem,VListItemContent,VListItemIcon,VMenu})
