<script>
  import Locale from '@components/layout/locale';
  import {authComputed, authMethods} from '@state/helpers';

  export default {
    components: { Locale },
    data() {
      return {
        isAuto: true,
        isUpdating: false,
        showConfig: false,
      }
    },
    computed: {
      ...authComputed,

      routeName: function () {
        return this.$router.currentRoute.name
      },

      isOpen: {
        get() {
          return this.currentCompany.isOpen;
        },
        async set(value) {
          this.isUpdating = true;
          try {
            await this.companyChangeOpen(value);
            this.$showSuccess();
          } catch(error) {
            this.$showError();
          }
          this.isUpdating = false;
        }
      },

      acceptDelivery: {
        get() {
          return this.currentCompany.acceptDelivery;
        },
        async set(value) {
          this.isUpdating = true;
          try {
            await this.companyChangeOpen({type:'acceptDelivery', active: value});
            this.$showSuccess();
          } catch(error) {
            this.$showError();
          }
          this.isUpdating = false;
        }
      },

      acceptTakeout: {
        get() {
          return this.currentCompany.acceptTakeout;
        },
        async set(value) {
          this.isUpdating = true;
          try {
            await this.companyChangeOpen({type:'acceptTakeout', active: value});
            this.$showSuccess();
          } catch(error) {
            console.error(error);
            this.$showError();
          }
          this.isUpdating = false;
        }
      },

      acceptOnSite: {
        get() {
          return this.currentCompany.acceptOnSite;
        },
        async set(value) {
          this.isUpdating = true;
          try {
            await this.companyChangeOpen({type:'acceptOnSite', active: value});
            this.$showSuccess();
          } catch(error) {
            this.$showError();
          }
          this.isUpdating = false;
        }
      },

      strippedName: function(){
        if(!this.currentCompany || !this.currentCompany.name) {
          return '';
        }
        if(this.currentCompany.name.length > 20) {
          return this.currentCompany.name.slice(0,19) + '...';
        }
        return this.currentCompany.name;
      },

      strippedUserName: function(){
        if(!this.currentUser || !this.currentUser.name) {
          return '';
        }
        if(this.currentUser.name.length > 18) {
          return this.currentUser.name.slice(0,17) + '...';
        }
        return this.currentUser.name;
      }
    },
    methods: {
      ...authMethods,
      logout: function (event) {
        this.$router.push({ name: 'logout' }).catch(()=>{});
      },
      goRegister: function (event) {
        this.$router.push({ name: 'company/register' }).catch(()=>{});
      },
      changeShowConfig() {
        this.showConfig = !this.showConfig;
      },
      awayShowConfig() {
        this.showConfig = false;
      },
      clientLogin: function (event) {
        this.$router.push({ name: 'client/login' }).catch(()=>{});
      },
    }
  }
</script>

<template>
  <nav class="app-navbar--company">
    <div class="app-navbar--company__container">

      <div class="app-brand-content">
        <RouterLink class="encapsuled-link" to="/" v-bind="$attrs">
          <img class="image-brand" :src="require('@assets/images/img-navbar-logo@3x.png')" alt="logo" />
        </RouterLink>
      </div>

      <div v-if="loggedIn&&currentCompany" class="company-information">
        <img v-if="currentCompany.logoImgUrl" class="company-information__logo" :src="currentCompany.logoImgUrl" />
        <div class="company-information__content">
          <span class="company-name">{{strippedName}}</span>
          <!-- <span class="user-name"> {{'Dr. ' + strippedUserName}}</span> -->
        </div>
      </div>

      <div class="workspaces-navigation">
          <div class="nav-buttons nav-buttons--disabled">
            <v-btn
              v-if="loggedIn"
              class="button--logout"
              text
              outlined
              @click="logout"
            >
              <v-icon class="btn-icon" small>fas fa-sign-out-alt</v-icon>
              <span class="btn-label">{{$t('navBar.logOut')}}</span>
            </v-btn>

            <button
              v-else
              class="button--switch  app-button button--softBlue"
              @click="clientLogin"
            >
              <v-icon class="button-icon icon--left" small>mdi-account-circle-outline</v-icon>
              <span class="button-label">{{$t('navBar.forClients')}}</span>
            </button>
          </div>

          <div class="locale" :style="!loggedIn ? 'margin-right: 0px !important' : ''">
            <Locale />
          </div>
      </div>

    </div>
  </nav>
</template>


<style lang="scss">
  .configContent .md-switch .md-switch-thumb .md-ripple {
    height: 26px!important;
    border: 1px solid red;
  }
</style>

<style lang="scss" scoped>
  @import '@design';

  .nav-buttons--disabled {
    display: block;
    // @media screen and (max-width: $size-menu-actions+px) {
    //   display: none!important;
    // }
  }

  .app-navbar--company {
    width: 100%;
    height: 100%;
    max-height: $slin-height-navbar !important;
    @include flexbox(row, nowrap, center, flex-start);
    overflow: hidden;
    box-shadow: $v-shadow;
    z-index: 10;
    position: relative;
    border-bottom: 2px solid $soft-gray-1;
    border-radius: 0px;

    @include for-desktop-up {
      width: 100%;
      @include flexbox(row, nowrap, center, center);
    }

    @include for-phone-only {
      @include flexbox(row, nowrap, center, space-between);
    }

    &__container {
      width: 100%;
      height: 100%;
      min-width: $size-content-width-min;
      @include flexbox(row, nowrap, center, space-between);
      box-shadow: none;
      // background-color: rgba(255, 255, 255, 0.4);
      z-index: 20;
      padding: 8px;
      margin: 0 auto;
      // border: 1px solid red;

      @include for-desktop-up {
        max-width: $max-width-navbar;
        @include flexbox(row, nowrap, center, space-between);
      }

      .app-brand-content {
        min-width: 100px;
        padding: 0;
        // border: 1px solid red;

        .encapsuled-link {
          padding: 8px !important;
          @include flexbox(row, nowrap, center, center);

          .image-brand {
            max-height: 75px;
            max-width: 130px;
            // border: 1px solid red;
            // margin: auto;

            @media screen and (max-width: $size-company-menu-max+px) {
              align-self: flex-start;
              max-height: 52px;
            }
          }
          @media screen and (max-width: $size-company-menu-max+px) {
            min-width: 160px;
            padding-bottom: 20px;
          }
        }
      }

      .company-information {
        @extend %font-navbar;
        @include flexbox(row, nowrap, center, flex-start);
        flex: 1;
        text-align: start;
        margin-left: 64px;

        &__logo {
          border-radius: 50%;
          align-self: center;
          max-height: 75px;
          flex: 0;
          @media screen and (max-width: $size-company-menu-max+px) {
            align-self: flex-start;
            max-height: 52px;
          }
        }

        &__content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-self: center;
          max-width: 300px;
          text-align: start;
          padding-left: 16px;

          .company-name {
            margin-bottom: 4px;
            @include text(16px, 600, 1em, $color-text, normal);
            font-family: $font-input;
          }

          .user-name {
            @extend %font-content;
            @include text(16px, 600, 1em, $color-text, normal);
            font-family: $font-input;
            margin-left: 8px;
            font-size: 18px;
            font-weight: 400;
          }

          .iconsCompanyStatus {
            .icon {
              width: 35px;
              height: 35px;
              margin-right: 4px;
            }
            .active {
              filter: saturate(20000%) hue-rotate(320deg);
            }
            .config {
              cursor: pointer;
              z-index:100;
            }
            z-index:100;
          }

          .outside {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0px;
            left: 0px;
            z-index:99;
          }

          .configContainer {
            position: relative;
            .configContent {
              position: absolute;
              z-index:100;
              background-color: white;
              padding: 14px;
              border-radius: 3px;
              min-width: 210px;

              >div {
                margin-bottom: 10px;
              }
            }
          }
        }

        .switch {
          @extend %font-content;
          margin: 1px 5px 5px 0;
        }
      }

      .workspaces-navigation {
        @include flexbox(row, nowrap, center, space-between);
        margin: 0 32px;

        .nav-buttons {
          @include flexbox(row, nowrap, center, space-between);
          align-self: center;
          margin-right: 24px;

          @include for-desktop-down {
            margin-right: 16px;
          }

          div {
            align-self: center;
            BaseButton {

            }
          }

          .button--logout {
            .icon {
              margin: 0 8px 0 0;
            }

            .btn-label {
              @include text(14px, 600, 1em, $color-primary, lowercase);
              font-family: $font-input;
            }
          }

          .button--switch {
            min-width: 140px;
            margin: 0 60px 0 0;
            color: $color-primary;
            border: none;

            @media screen and (max-width: $size-company-menu-max+px) {
              display: none;
            }
          }

        }

        .locale {
          margin: 0 32px !important;
        }
      }

    }
  }


</style>
