import moment from 'moment';
import {firestore, FieldValue} from "@src/db";
let {translateDb, i18nConfig, languages} = require('@i18n');
const StringUtils = require("@common/StringUtils");
const status = require("@common/status");
const _ = require('lodash');
import {eventHub} from "@src/eventHub";
import {authComputed} from "@state/helpers";

export default {

  install (Vue, options) {
    /*
    Vue.mixin({
      beforeDestroy: function () {
        console.log('unsubscribe function', Vue.prototype.$fcmUnsubscriber);
        // for(let us of Vue.prototype.$fcmUnsubscriber) {
        //   if(typeof us === 'function') {
        //     us();
        //   }
        // }
      //  Vue.prototype.$fcmUnsubscriber = [];
      }
    });
    Vue.prototype.$fcmUnsubscriber = [];
    */

    /*
    if(typeof window!=='undefined' && window.visualViewport) {
      const listener = () => {
        const MIN_KEYBOARD_HEIGHT = 300
        const isMobile = window.innerWidth < 768
        const isKeyboardOpen = isMobile && window.innerHeight<768
          && window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height;
      }
      window.visualViewport.addEventListener('resize', listener);
    }
     */

    Vue.mixin({
      computed: {
        ...authComputed,
        $isDark: function() {
          return this.$vuetify.theme.dark;
        },
      }
    });

    Vue.prototype.$eventHub = eventHub; // Global event bus

    Vue.prototype.$trdb = function (obj) {
      if(this.$i18n && this.$i18n.locale) {
        const t = translateDb(this.$i18n.locale, obj);
        if(t) {
          return t;
        }
      }
      return translateDb('pt-BR', obj);
    };

    Vue.prototype.$trdbLocale = function () {
      if(this.$i18n && this.$i18n.locale) {
        return (languages[this.$i18n.locale] || {}).database;
      }
      return (languages[i18nConfig.fallbackLocale] || {}).database;
    };

    Vue.prototype.$showMsg = function ({title='', text=null, timer=2500}={}) {
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showWarning = function ({title='', text=null, timer=3500}={}) {
      if(!title && !text) {
        title = this.$t('form.updateSuccess');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'warning',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showSuccess = function ({title='', text=null, timer=2500}={}) {
      if(!title && !text) {
        title = this.$t('form.updateSuccess');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'success',
        title: title ,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showError = function ({title='', text=null, timer=4500}={}) {
      if(!title && !text) {
        title = this.$t('form.error.update');
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        icon: 'error',
        title: title,
        text: text,
        showConfirmButton: false,
        timer: timer
      });
    };

    Vue.prototype.$showConfirm = async function ({title='', text=null}={}) {
      if(!title && !text) {
        title = this.$t('form.confirmQuestion');
      }
      let result = await this.$swal({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: this.$t('form.confirmQuestionYes'),
        cancelButtonText: this.$t('form.confirmQuestionCancel')
      });
      return !!result.value;

    };
    Vue.prototype.$showPrompt = async function ({title='', text=null, input='text',
                                                  confirmButtonText=this.$t('form.confirmQuestionYes'),
                                                  cancelButtonText=this.$t('form.confirmQuestionCancel'),
                                                  inputAttributes = {},
                                                  inputValue=''

                                                }={}) {
      if(!title && !text) {
        title = this.$t('form.promptQuestion');
      }
      let result = await this.$swal.fire({
        title: title,
        text: text,
        input: input,
        inputAttributes: inputAttributes,
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        inputValue: inputValue,
        inputValidator: ()=>{return false;}
      });
      // console.log('result', result);
      if(result.isConfirmed) {
        return result.value;
      }
      return false;
    };


    Vue.prototype.$newId = function () {
      return firestore.collection('id').doc().id;
    };

    Vue.prototype.$metaInfo = function (isNew=false, isAdmin=false) {
      let metaInfo = {};
      if (isNew){
        metaInfo = {
          createdAt: FieldValue.serverTimestamp(),
          createdBy: this.currentUser?.id || '',
          createdByName: this.currentUser?.name || '',
          createdEnv: status.source.WEB,
        }
      }
      metaInfo = {
        ...metaInfo,
        updatedAt: FieldValue.serverTimestamp(),
        updatedBy: this.currentUser?.id || '',
        updatedByName: this.currentUser?.name || '',
        updatedEnv: status.source.WEB,
        updatedByAdmin: isAdmin,
      }
      return metaInfo;
    };

    Vue.prototype.$fcmGetDark = function () {
      let isDark = typeof window!=='undefined'?window.localStorage.getItem('darkMode'):null;
      if(isDark==='true') {
        return true;
      }
      if(isDark==='false') {
        return false;
      }
      return true; // Setando dark mode como default
      // isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      // return !!isDark;
    };

    Vue.prototype.$fcmSetDark = function (isDark) {
      if(typeof window!=='undefined' && window.localStorage) {
        window.localStorage.setItem('darkMode', isDark?'true':'false');
      }
      if(this.$vuetify && this.$vuetify.theme) {
        if(this.$vuetify.theme.dark!==isDark) {
          this.$vuetify.theme.dark = isDark;
        }
      }
    };

    Vue.prototype.$validateRules = function () {
      return {
        required: (value) => !!value || this.$t('form.error.required'),
        counter: (value) =>
          value.length <= 20 || this.$t('form.error.maxLength'),
        minLength: (value) =>
          value.length > 5 || this.$t('form.error.minLength'),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('form.error.email')
        },
        length: (len) => (v) =>
          (v || '').length >= len ||
          `Invalid character length, required ${len}`,
        password: (v) =>
          !!(v || '').match(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
          ) || this.$t('form.error.password'),
        numberDotComma: (value) => {
          const pattern = /^[0-9.,]*$/
          return pattern.test(value) || this.$t('form.error.invalidValue')
        },
        number: (value) => {
          const pattern = /^[0-9]*$/
          return pattern.test(value) || this.$t('form.error.justNumbers')
        },
        numberAndDot: (value) => {
          const pattern = /^[0-9.]*$/
          return pattern.test(value) || this.$t('form.error.justNumbers')
        },
        hour: (value) => {
          if (!value) {
            return true
          }
          if (value.length < 5) {
            return this.$t('form.error.hour')
          }
          if (
            parseInt(value.substr(0, 2)) > 23 ||
            parseInt(value.substr(3, 2)) > 59
          ) {
            this.$showError({ text: this.$t('form.error.hour') })
            return this.$t('form.error.hour')
          }
          return true
        },
        cpf: (value) => {
          if (!value) {
            return false
          }

          value = value.replace('.', '')
          value = value.replace('.', '')
          value = value.replace('.', '')
          value = value.replace('-', '')

          let sum
          let rest
          let returnInvalid = false
          sum = 0
          // if (value === "00000000000")
          //   returnInvalid = true;

          for (let i = 1; i <= 9; i++)
            sum = sum + parseInt(value.substring(i - 1, i)) * (11 - i)

          rest = (sum * 10) % 11

          if (rest === 10 || rest === 11) rest = 0
          if (rest !== parseInt(value.substring(9, 10))) returnInvalid = true

          sum = 0
          for (let i = 1; i <= 10; i++) {
            sum = sum + parseInt(value.substring(i - 1, i)) * (12 - i)
          }

          rest = (sum * 10) % 11

          if (rest === 10 || rest === 11) rest = 0
          if (rest !== parseInt(value.substring(10, 11))) returnInvalid = true

          if (returnInvalid) {
            return this.$t('form.error.cpf')
          }
          return true
        },
      }
    }
    Vue.prototype.$deburr = function (txt) {
      return _.deburr(txt|| '').toLowerCase().trim()
    };

    // Obs: extends Error não funciona com o Babel
    Vue.prototype.JwGetError = function(message, code=''){
      const err = new Error(message);
      err.code = code;
      return err;
    };
    Vue.prototype.$money2Float = function (num) {
      if (num === "") {
        num = 0;
      } else {
        num = num.replace("R$ ", "");
        num = num.replace(/\./g, "");
        num = num.replace(",", ".");
        num = parseFloat(num);
      }
      return num;
    };

    Vue.prototype.$cpfExist =  async function(cpf, id, table) {
      if(!cpf){
        return 'cpfInvalid';
      }
      if(StringUtils.validateCpf(cpf)!==true) {
        return 'cpfInvalid';
      }
      cpf = cpf.replace(/[.-]/g, '');
      if (cpf.length === 11) {
        try {
          const doc = await firestore.collection(table).where('cpf', '==', cpf).get();
          if(!doc.empty) {
            let find = false;
            if(id) {
              doc.forEach((obj)=>{
                if(obj.id===id) {
                  find = id;
                }
              });
            }
            if(!find) {
              return 'cpfExist';
            }
          }
          return 'cpfOk';
        } catch(error) {
          console.error('$cpfExist:', error);
          return 'cpfError';
        }
      }
      return 'cpfInvalid';
    }

    Vue.prototype.$sortByTimeStamp = function(items, field){
      return items.sort((a, b) => {
        if(a?.[field] && b?.[field]) {
          return b?.[field]?.seconds - a?.[field]?.seconds;
        }
        else if(a?.[field]) {
          return 1;
        }
        else if(b?.[field]) {
          return -1;
        }
        else {
          return 0;
        }
      });
    };

    Vue.prototype.$fcmClearAndReload = function() {
      if(window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations()
          .then(function(registrations) {
            for(let registration of registrations) {
              registration.unregister?.();
            }
            window.location.reload();
          });
      }
      else {
        window.location.reload();
      }
    }

    Vue.prototype.moment = moment;
    Vue.prototype._ = _;
  }
}
