import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
    currentPayAccount: (state) => state.currentPaymentAccount,
    currentCompany: (state) => state.currentCompany,
    currentWorkspace: (state) => state.currentWorkspace,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const loadingActions = {
  ...mapActions('loading', [
    'initLoading',
    'removeLoading',
  ])
}

export const loadingState = {
  ...mapState('loading', {
    hasLoading: (state) => state.loading,
  })
}

export const doubtsGetters = {
  ...mapGetters('doubts', [
    'getHasNotification',
  ]),
}

export const doubtsActions = mapActions('doubts', [
  'updateHasNotification',
  'seenDoubt',
]);

export const breadcrumbsGetters = {
  ...mapGetters('breadcrumb', [
    'getCurrentBreadcrumbs',
  ]),
}

export const breadcrumbsActions = mapActions('breadcrumb', [
  'initCurrentBreadcrumbs',
  'clearBreadcrumbs',
]);

export const paymentAccountsActions = mapActions('paymentAccounts', [
  'toggleFreeTrial',
]);

export const companiesActions = mapActions('companies', [
  'registerCompany',
]);

export const companiesGetters = {
  ...mapGetters('companies', [
    'getRegisterSuccess',
    'getRegisterFail',
  ]),
}

export const usersGetters = {
  ...mapGetters('users', [
    'getHasPendingDoctors',
    'getHasPendingPatients',
    'getHasPendingUsers',
  ]),
}

export const usersActions = mapActions('users', [
  'updateHasPendingDoctors',
  'updateHasPendingPatients',
  'updateHasPendingUsers',
])

export const authMethods = mapActions('auth', [
  'logIn',
  'logOut',
  'companyRegister',
  'modelCompanyUpdate',
  'listCompanies',
  'onAuthStateChanged',
  'getAdminMailByFakeMail',
  'modelClientUpdate',
  'modelAdminUpdate',
  'modelCompanyUserUpdate',
  'logInSocial',
  'startLogin',
  'endLogin',
  'logInPhone',
  'logInClient',
  'companyChangeOpen',
  'registerClient',
])

export const configMethods = mapActions('config', ['updateConfigLocal'])

export const configComputed = {
  ...mapState('config', {
    config: (state) => state.config,
    localConfig: (state) => state.localConfig,
  }),
}

export const chatComputed = {
  ...mapState('chat', {
    chatHighlighteds: (state) => state.highlighteds,
    chatContacts: (state) => state.contacts,
    chatCurrent: (state) => state.current,
    chatCurrentMessages: (state) => state.currentMessages,
    chatCurrentId: (state) => state.currentId,
    chatUserKey: (state) => state.userKey,
    chatNewMessages: (state) => state.newMessages,
  }),
}

export const chatMethods = mapActions('chat', ['setCurrent', 'close'])
