export const state = {
  loading: false,
};

export const actions = {
  initLoading({ commit }) {
    commit('setLoading', true);
  },
  removeLoading({ commit }) {
    commit('setLoading', false);
  },
};

export const mutations = {
  setLoading($state, payload) {
    const stateCopy = $state;
    stateCopy.loading = payload;
  },
};
