// Conveniently import this file anywhere to use db
import { config, listConfig } from '@src/config'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/functions'
// import 'firebase/storage' // Use lazy loading, on include common/FileUtils.js firebase/storage will be load
// import 'firebase/performance';
// import 'firebase/analytics';

//
// <!-- Load the Firebase SDKs before loading this file -->
// <script src="/__/firebase/init.js"></script>
//
// fetch('/__/firebase/init.json').then(async response => {
//   firebase.initializeApp(await response.json());
// });

firebase.initializeApp(config.firebase)

// firebase.firestore().settings({cacheSizeBytes: 3e+8});
// firebase.firestore().enablePersistence({synchronizeTabs: true}).catch((err)=>{
//   console.error('Firestore, Erro ao habilitar Persistência, pode ser que tenha outra aba aberta do sistema', err);
// });

// firebase.firestore().enablePersistence({experimentalTabSynchronization: true});
// firebase.firestore().enablePersistence({synchronizeTabs: true});
// firebase.analytics();

if (window) {
  window.firebaseDebug = firebase
}

// Debug functions local http and onCall
if (process.env.NODE_ENV !== 'production') {
  // firebase.functions().useFunctionsEmulator("http://localhost:5000"); // old
  // firebase.firestore().useEmulator('localhost', 8080)                 // old

  firebase.app().functions(config.region).useEmulator('localhost', 5001);

  window.firebaseDev = firebase.initializeApp(
    listConfig.uhealthjwdev.firebase,
    'SauverDev'
  )
  window.firebaseProd = firebase.initializeApp(
    listConfig.uhealthjw.firebase,
    'SauverProd'
  )
}

const { Timestamp, GeoPoint, FieldValue } = firebase.firestore
const functions = firebase.app().functions(config.region)
const firestore = firebase.firestore()
export { Timestamp, GeoPoint, FieldValue, firestore, functions, firebase }
