import axios from 'axios'
import firebase from 'firebase/app'
import router from '@router'
import defaults from '@/src/router/defaults'
import {FcmEvent} from '@common/FcmEvent';
const timeUtils = require('@common/time_utils')
export const workspaceDefine = require('@common/status').WORKSPACE

export const state = {
  currentUser: getSavedState('auth.currentUser'),
  currentPaymentAccount: getSavedState('auth.currentPaymentAccount'),
  currentCompany: getSavedState('auth.currentCompany'),
  currentWorkspace: getSavedState('auth.currentWorkspace'),
}

export const userLoadedObj = new FcmEvent(30000);
export function getUserLoadedObj() {
  return userLoadedObj.getPromise();
}

userLoadedObj.getPromise().then(()=>{
}).catch(()=>{});

function setDone() {
  if (state.currentUser) {
    userLoadedObj.resolve();
  } else {
    userLoadedObj.reject();
  }
}

export let unsubscribeUserCompany = null
export let unsubscribeUserClient = null
export let unsubscribeUserAdmin = null
export let unsubscribeCompanies = null

export let unsubscribeUserCompanyId = null
export let unsubscribeUserClientId = null
export let unsubscribeUserAdminId = null
export let unsubscribeCompaniesId = null
export let onAuthStateChanged = null

export let ON_LOGIN = false

function unsubscriber() {
  if (typeof unsubscribeUserCompany === 'function') {
    unsubscribeUserCompany()
  }
  if (typeof unsubscribeUserClient === 'function') {
    unsubscribeUserClient()
  }
  if (typeof unsubscribeUserAdmin === 'function') {
    unsubscribeUserAdmin()
  }
  if (typeof unsubscribeCompanies === 'function') {
    unsubscribeCompanies()
  }
  unsubscribeUserCompanyId = null
  unsubscribeUserClientId = null
  unsubscribeUserAdminId = null
  unsubscribeCompaniesId = null
}

export const mutations = {
  SET_CURRENT_WORKSPACE(state, newValue) {
    state.currentWorkspace = newValue
    saveState('auth.currentWorkspace', newValue)
  },
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('auth.currentUser', newValue)
    setDefaultAuthHeaders(state)
  },
  SET_CURRENT_PAYMENT(state, newValue) {
    state.currentPaymentAccount = newValue
    saveState('auth.currentPaymentAccount', newValue)
    setDefaultAuthHeaders(state)
  },
  SET_CURRENT_COMPANY(state, newValue) {
    state.currentCompany = newValue
    saveState('auth.currentCompany', newValue)
  },

  SET_CURRENT(state, { workspace, user, company, payment } = {}) {
    state.currentWorkspace = workspace || null
    state.currentUser = user || null
    state.currentPaymentAccount = payment || null
    state.currentCompany = company || null

    setDefaultAuthHeaders(state)
    saveState('auth.currentWorkspace', workspace || null)
    saveState('auth.currentUser', user || null)
    saveState('auth.currentPaymentAccount', payment || null)
    saveState('auth.currentCompany', company || null)
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    let isValid = false
    switch (state.currentWorkspace) {
      case workspaceDefine.COMPANY:
        if (state.currentUser && state.currentCompany) {
          isValid = true
        }
        break
      case workspaceDefine.ADMIN:
        if (state.currentUser) {
          isValid = true
        }
        break
      case workspaceDefine.CLIENT:
        if (state.currentUser) {
          isValid = true
        }
        break
      default:
        if (state.currentUser) {
          console.warn(
            'Inconsistencia encontrada, usuário sem workspace definido'
          )
        }
    }
    if (isValid) {
      return state.currentWorkspace
    }
    return false
  },
  getPayAccountId(state) {
    return state.currentUser?.paymentAccount?.id || null
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ commit, state, dispatch }) {
    if (!onAuthStateChanged) {
      onAuthStateChanged = firebase.auth().onAuthStateChanged((user) => {
        dispatch('onAuthStateChanged', {
          firebaseUser: user,
        })
      })
    }
    setDefaultAuthHeaders(state)
    dispatch('validate')
  },

  async logIn({ commit, dispatch, getters, state }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')
    ON_LOGIN = true
    let authUser = false
    userLoadedObj.startTimer();
    // console.log('auth email', email)
    try {
      authUser = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
    } catch (error) {
      console.error('Erro em signInWithEmailAndPassword: ' + email, error)
      ON_LOGIN = false
      return Promise.reject(error)
    }
    await dispatch('onAuthStateChangedLogin', {
      firebaseUser: authUser.user,
      provider: 'e',
    })
    ON_LOGIN = false
  },

  async logInClient(
    { commit, dispatch, getters, state },
    { email, password } = {}
  ) {
    if (getters.loggedIn) return dispatch('validate')
    userLoadedObj.startTimer();
    ON_LOGIN = true
    let authUser = false

    try {
      authUser = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
    } catch (error) {
      console.error('Erro em signInWithEmailAndPassword2: ' + email, error)
      ON_LOGIN = false
      return Promise.reject(error)
    }
    await dispatch('onAuthStateChangedLogin', {
      firebaseUser: authUser.user,
      provider: 'e',
      isClient: true,
    })
    ON_LOGIN = false
  },

  /**
   *
   * @param locale
   * @param provider   'facebook.com' | 'google.com' | 'apple.com'
   */
  async logInSocial({ commit, dispatch, getters }, { locale, provider }) {
    // if (getters.loggedIn) return dispatch('validate')
    ON_LOGIN = true
    let authUser = false
    userLoadedObj.startTimer();
    try {
      let providerObj = null
      switch (provider) {
        case 'facebook.com':
          providerObj = new firebase.auth.FacebookAuthProvider()
          break
        case 'google.com':
          providerObj = new firebase.auth.GoogleAuthProvider()
          break
        case 'apple.com':
          providerObj = new firebase.auth.OAuthProvider('apple.com')
          break
        default:
          return Promise.reject(new Error('Unknown provider'))
      }
      if (locale) {
        providerObj.setCustomParameters({
          locale: locale,
        })
      }
      authUser = await firebase.auth().signInWithPopup(providerObj)
    } catch (error) {
      console.error('Erro em logInFacebook', error)
      ON_LOGIN = false
      return Promise.reject(error)
    }
    await dispatch('onAuthStateChangedLogin', {
      firebaseUser: authUser.user,
      provider: provider,
      isClient: true,
    })
    ON_LOGIN = false
  },

  async startLogin({ commit, dispatch, getters }) {
    ON_LOGIN = true
  },
  async endLogin({ commit, dispatch, getters }) {
    ON_LOGIN = false
  },

  async logInPhone({ commit, dispatch, getters }, { authUser }) {
    await dispatch('onAuthStateChangedLogin', {
      firebaseUser: authUser.user,
      provider: 'phone',
      isClient: true,
    })
    ON_LOGIN = false
  },

  async getAdminMailByFakeMail({ commit }, { email }) {
    let userList = await firebase
      .firestore()
      .collection('companiesAdminPublicMirror')
      .where('email', '==', email.toLowerCase())
      .get()
    if (!userList) {
      return Promise.reject(new Error('Nenhuma empresa encontrada'))
    }
    let fakeMail = false
    userList.forEach(function (doc) {
      let user = doc.data()
      if (user.fakeMail) {
        fakeMail = user.fakeMail
      }
    })
    return fakeMail
  },

  async listCompanies({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    let userList = await firebase
      .firestore()
      .collection('companiesUsersPublicMirror')
      .where('email', '==', email.toLowerCase())
      .get()
    if (!userList) {
      return Promise.reject(new Error('Nenhuma empresa encontrada'))
    }

    let promises = []
    let hasCompany = false
    let ret = {}

    userList.forEach(function (doc) {
      let user = doc.data()
      if (!user.companyId || !user.fakeMail) {
        return
      }
      let companyId = user.companyId
      let userId = doc.id

      ret[userId] = {
        company: false,
        fakeMail: user.fakeMail,
      }
      promises.push(
        new Promise(async function (resolve, reject) {
          let companyDoc = await firebase
            .firestore()
            .collection('companies')
            .doc(companyId)
            .get()
          if (companyDoc.exists) {
            let companyTmp = companyDoc.data()
            companyTmp.id = companyDoc.id
            ret[userId]['company'] = companyTmp
            hasCompany = true
          }
          resolve()
        })
      )
    })

    await Promise.all(promises)
    for (let i in ret) {
      if (!ret[i].company) {
        delete ret[i]
      }
    }

    if (hasCompany) {
      return ret
    }
    return false
  },

  async modelCompanyUpdate(
    { commit, dispatch, getters, state },
    { form = {}, itemId } = {}
  ) {
    if (!form || !itemId) {
      return Promise.reject(new Error('id não encontrado'))
    }

    if (itemId !== state.currentCompany.id) {
      return Promise.reject(
        new Error('id inválido', itemId, state.currentCompany.id)
      )
    }

    let companyId = state.currentCompany.id
    let company = Object.assign({}, state.currentCompany, form)

    delete form.companyId
    form.id = companyId
    commit('SET_CURRENT_COMPANY', company)
    setDone()
    await firebase
      .firestore()
      .collection('companies')
      .doc(companyId)
      .set(form, {
        merge: true,
      })
  },

  async modelCompanyUserUpdate(
    { commit, dispatch, getters, state },
    form = {}
  ) {
    if (!form || !form.id) {
      return Promise.reject(new Error('id não encontrado'))
    }
    if (state.currentUser.id === form.id) {
      // self update
      let user = Object.assign({}, state.currentUser, form)
      commit('SET_CURRENT_USER', user)
      setDone()
    }
    let userId = form.id
    delete form.id
    await firebase
      .firestore()
      .collection('companiesUsers')
      .doc(userId)
      .set(form, {
        merge: true,
      })
  },

  async modelClientUpdate({ commit, dispatch, getters, state }, form = {}) {
    let userId = state.currentUser.id
    let user = Object.assign({}, state.currentUser, form)
    delete form.id
    commit('SET_CURRENT_USER', user)
    setDone()
    await firebase.firestore().collection('users').doc(userId).set(form, {
      merge: true,
    })
  },

  async modelAdminUpdate({ commit, dispatch, getters, state }, form = {}) {
    let userId = state.currentUser.id
    let user = Object.assign({}, state.currentUser, form)
    delete form.id
    commit('SET_CURRENT_USER', user)
    setDone()
    await firebase.firestore().collection('adminUsers').doc(userId).set(form, {
      merge: true,
    })
  },

  async registerClient(
    { commit, dispatch, getters },
    {
      name,
      lastName,
      phone,
      gender,
      birthDate,
      email,
      password,
      governmentDocumentId,
      governmentDocumentImage,
      createdType,
      createdEnv,
      doctorRequest,
    } = {}
  ) {
    if (getters.loggedIn) {
      return dispatch('validate')
    }
    ON_LOGIN = true
    let authUser = null
    try {
      authUser = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
      console.log('authUser', authUser.user)
      userLoadedObj.startTimer();
      await firebase
        .firestore()
        .collection('users')
        .doc(authUser.user.uid)
        .set(
          {
            id: authUser.user.uid,
            email: email,
            name: name || '',
            lastName: lastName || '',
            phone: phone,
            gender: gender || '',
            birthDate: birthDate || null,
            governmentDocumentId: governmentDocumentId || '',
            governmentDocumentImage: governmentDocumentImage || '',
            createdType: createdType || '',
            createdEnv: createdEnv || '',
            doctorRequest: doctorRequest || '',
          },
          { merge: true }
        )

      authUser = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
    } catch (error) {
      console.error('Erro em signInWithEmailAndPassword', error)
      ON_LOGIN = false
      return Promise.reject(error)
    }

    await dispatch('onAuthStateChangedLogin', {
      firebaseUser: authUser.user,
      provider: 'email',
    })
    ON_LOGIN = false
    return 'ok'
  },

  async companyRegister(
    { commit, dispatch, getters },
    { name, email, password, companyName } = {}
  ) {
    if (getters.loggedIn) {
      return dispatch('validate')
    }
    ON_LOGIN = true
    userLoadedObj.startTimer();
    let result = await firebase
      .functions()
      .httpsCallable('auth-companyRegister')({
      name,
      email,
      password,
      companyName,
    })
    console.log('result', result)
    // let authUser = null

    // try {
    //   authUser = await firebase
    //     .auth()
    //     .signInWithEmailAndPassword(result.data.fakeMail, password)
    // } catch (error) {
    //   console.error('Erro em signInWithEmailAndPassword', error)
    //   ON_LOGIN = false
    //   return Promise.reject(error)
    // }

    // await dispatch('onAuthStateChangedLogin', {
    //   firebaseUser: authUser.user,
    //   provider: 'email',
    // })
    // ON_LOGIN = false
    return 'ok'
  },

  // Logs out the current user.
  logOut({ commit, dispatch }) {
    return dispatch('makeLogOut')
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  async validate({ commit, state, dispatch }, { workspace, rule } = {}) {
    if (workspace && workspace !== state.currentWorkspace) {
      console.error('Workspace incorreto')
      console.log('workspace', workspace)
      console.log('state.currentWorkspace', state.currentWorkspace)
      console.log('state.currentUser', state.currentUser)
      console.log(
        'getIdTokenResult',
        await firebase.auth().currentUser.getIdTokenResult()
      )
      await dispatch('makeLogOut')
      throw new Error('Usuário com workspace incorreto')
    }

    let isValid = false
    switch (state.currentWorkspace) {
      case workspaceDefine.COMPANY:
        if (state.currentUser && state.currentCompany) {
          isValid = true
        }
        break
      case workspaceDefine.ADMIN:
        if (state.currentUser) {
          isValid = true
        }
        break
      case workspaceDefine.CLIENT:
        if (state.currentUser) {
          isValid = true
        }
        break
      default:
        if (state.currentUser) {
          console.warn(
            'Inconsistencia encontrada, usuário sem workspace definido'
          )
        }
    }

    if (!isValid) {
      await dispatch('makeLogOut')
      return false
    }
    // TODO Ao acessar uma URL inválida, o usuário é desconectado
    /*    if(rule && rule.length>0) {
          isValid = false;
          if(!state.currentUser.rule) {
            console.warn('Acesso requerido');
            router.push({name: defaults.routeLogged[workspace] || 'login'});
            return false;
          }
          for(let r of rule) {
            if(state.currentUser.rule[r]) {
              isValid = true;
              break;
            }
          }
          if (!isValid){
            console.warn('Acesso requerido');
            router.push({name: defaults.routeLogged[workspace] || 'login'})
            return false;
          }
        } */

    return Promise.resolve(state.currentUser)
  },

  async onAuthStateChangedLogin(
    { commit, getters, state, dispatch },
    { firebaseUser, provider, isClient }
  ) {
    // console.log('onAuthStateChangedLogin', firebaseUser, provider)
    if (firebaseUser && firebase.auth().currentUser) {
      let workspace = state.currentWorkspace

      if (!workspace) {
        let idTokenResult = await firebase.auth().currentUser.getIdTokenResult()
        if (
          idTokenResult &&
          idTokenResult.claims &&
          idTokenResult.claims.type
        ) {
          if (idTokenResult.claims.type.cli) {
            workspace = workspaceDefine.CLIENT
          } else if (idTokenResult.claims.type.adm) {
            workspace = workspaceDefine.ADMIN
          } else if (idTokenResult.claims.type.comp) {
            workspace = workspaceDefine.COMPANY
          } else {
            console.warn('idTokenResult', idTokenResult)
          }
        }
      }

      if (!workspace) {
        console.log('buscar workspace')
        let result = await firebase
          .functions()
          .httpsCallable('auth-checkWorkspace')({
          id: firebaseUser.uid,
        })
        if (result.data && result.data.result) {
          if (result.data.result.type.cli) {
            workspace = workspaceDefine.CLIENT
          } else if (result.data.result.type.adm) {
            workspace = workspaceDefine.ADMIN
          } else if (result.data.result.type.comp) {
            workspace = workspaceDefine.COMPANY
          } else {
            console.warn('auth-checkWorkspace', result)
          }
          await firebase.auth().currentUser.getIdToken(true)
        }
      }

      switch (workspace) {
        case workspaceDefine.COMPANY:
          await dispatch('loadUserCompany', firebaseUser.uid)
          return dispatch('chat/changeAuth', null, {
            root: true,
          })
        case workspaceDefine.ADMIN:
          await dispatch('loadUserAdmin', firebaseUser.uid)
          return dispatch('chat/changeAuth', null, {
            root: true,
          })
        case workspaceDefine.CLIENT:
          await dispatch('loadUserClient', firebaseUser.uid)

          return dispatch('chat/changeAuth', null, {
            root: true,
          })
      }

      if (isClient) {
        console.warn(
          'ERRO ao procurar workspace do usuário, criando client: ' + workspace,
          firebaseUser
        )
        await firebase
          .firestore()
          .collection('users')
          .doc(firebaseUser.uid)
          .set(
            {
              createdEnv: 'web',
              createdType: provider || 'unknown',
            },
            {
              merge: true,
            }
          )

        let result = await firebase
          .functions()
          .httpsCallable('auth-checkWorkspace')({
          uid: firebaseUser.uid,
        })
        if (result.data && result.data.result) {
          if (result.data.result.type.cli) {
            workspace = workspaceDefine.CLIENT
          } else if (result.data.result.type.adm) {
            workspace = workspaceDefine.ADMIN
          } else if (result.data.result.type.comp) {
            workspace = workspaceDefine.COMPANY
          } else {
            console.warn('Erro ao buscar workspace, result', result)
          }
          await firebase.auth().currentUser.getIdToken(true)
        }
        if (workspace) {
          await dispatch('loadUserClient', firebaseUser.uid)

          return dispatch('chat/changeAuth', null, {
            root: true,
          })
        }
      }
      console.error(
        'ERRO ao procurar workspace do usuário: ' + workspace,
        firebaseUser
      )
    } else {
      console.error('not firebaseUser', firebaseUser)
    }
    await dispatch('makeLogOut')
    return dispatch('chat/changeAuth', null, {
      root: true,
    })
  },

  async onAuthStateChanged(
    { commit, getters, state, dispatch },
    { firebaseUser }
  ) {
    if (ON_LOGIN) {
      return
    }

    if (firebaseUser && firebase.auth().currentUser) {
      let workspace = state.currentWorkspace

      if (!workspace) {
        let idTokenResult = await firebase.auth().currentUser.getIdTokenResult()
        if (
          idTokenResult &&
          idTokenResult.claims &&
          idTokenResult.claims.type
        ) {
          if (idTokenResult.claims.type.cli) {
            workspace = workspaceDefine.CLIENT
          } else if (idTokenResult.claims.type.adm) {
            workspace = workspaceDefine.ADMIN
          } else if (idTokenResult.claims.type.comp) {
            workspace = workspaceDefine.COMPANY
          } else {
            console.warn('idTokenResult', idTokenResult)
          }
        }
      }

      if (!workspace) {
        console.warn('buscar workspace')
        let result = await firebase
          .functions()
          .httpsCallable('auth-checkWorkspace')({
          id: firebaseUser.uid,
        })
        if (result.data && result.data.result) {
          if (result.data.result.type.cli) {
            workspace = workspaceDefine.CLIENT
          } else if (result.data.result.type.adm) {
            workspace = workspaceDefine.ADMIN
          } else if (result.data.result.type.comp) {
            workspace = workspaceDefine.COMPANY
          } else {
            console.warn('Erro ao buscar workspace, result', result)
          }
          await firebase.auth().currentUser.getIdToken(true)
        }
      }

      switch (workspace) {
        case workspaceDefine.COMPANY:
          await dispatch('loadUserCompany', firebaseUser.uid)
          break
        case workspaceDefine.ADMIN:
          await dispatch('loadUserAdmin', firebaseUser.uid)
          break
        case workspaceDefine.CLIENT:
          await dispatch('loadUserClient', firebaseUser.uid)
          break
        default:
          console.error('ERRO ao procurar workspace do usuário', firebaseUser)
          userLoadedObj.reject();
          await dispatch('makeLogOut')
      }
    } else {
      userLoadedObj.reject();
      await dispatch('makeLogOut')
    }
    await dispatch('chat/changeAuth', null, {
      root: true,
    })
  },

  loadUserCompany({ commit, getters, state, dispatch }, userId) {
    return new Promise(function (resolve, reject) {
      if (unsubscribeUserCompanyId === userId) {
        return resolve()
      }
      unsubscriber()
      unsubscribeUserCompanyId = userId
      unsubscribeUserCompany = firebase
        .firestore()
        .collection('companiesUsers')
        .doc(userId)
        .onSnapshot(async (userDoc) => {
          let userData = userDoc.data()
          if (!userData || !userData.companyId) {
            dispatch('makeLogOut')
            console.error('Usuário sem companyId ou userData userId: ' + userId)
            console.log('Tentando corrigir o erro de workspace incorreto')
            await firebase.functions().httpsCallable('auth-checkWorkspace')({
              id: userId,
            })
            await dispatch('makeLogOut')
            return reject(
              new Error('Usuário sem companyId ou userData userId: ' + userId)
            )
          }
          userData.id = userId
          unsubscribeCompanies = firebase
            .firestore()
            .collection('companies')
            .doc(userData.companyId)
            .onSnapshot(async function (companyDoc) {
              let companyData = companyDoc.data()
              if (!companyData) {
                dispatch('makeLogOut')
                console.error(
                  'company não encontrada userId: ' +
                    userId +
                    ' companyId:' +
                    userData.companyId
                )
                return reject(
                  new Error(
                    'company não encontrada userId: ' +
                      userId +
                      ' companyId:' +
                      userData.companyId
                  )
                )
              }
              companyData.id = companyDoc.id
              await commit('SET_CURRENT', {
                workspace: workspaceDefine.COMPANY,
                user: userData,
                company: companyData,
              })

              setDone()
              resolve()
            })
        })
    })
  },

  loadUserClient({ commit, getters, state, dispatch }, userId) {
    return new Promise(function (resolve, reject) {
      if (userId === unsubscribeUserClientId) {
        return resolve()
      }
      unsubscriber()
      unsubscribeUserClientId = userId
      unsubscribeUserClient = firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .onSnapshot(async function (userDoc) {
          let userData = userDoc.data()
          if (!userData) {
            console.error('Usuário sem userData userId: ' + userId)
            console.log('Tentando corrigir o erro de workspace incorreto')
            await firebase.functions().httpsCallable('auth-checkWorkspace')({
              id: userId,
            })
            await dispatch('makeLogOut')
            return reject(new Error('Usuário sem userData userId: ' + userId))
          }
          userData.id = userId

          await commit('SET_CURRENT', {
            workspace: workspaceDefine.CLIENT,
            user: userData,
          })
          setDone()
          await dispatch('loadUserPayment', getters.getPayAccountId)

          resolve()
        })
    })
  },

  loadUserPayment({ commit, getters, state, dispatch }, accountId) {
    // console.log('Teste 01');
    return new Promise(function (resolve, reject) {
      if (!accountId) {
        console.error('user haven`t a payment account')
        commit('SET_CURRENT_PAYMENT', null)
        return resolve()
      }
      firebase
        .firestore()
        .collection('paymentAccounts')
        .doc(accountId)
        .onSnapshot(async function (paymentDoc) {
          let paymentData = paymentDoc?.data() || null
          if (!paymentData) {
            // console.error('Usuário sem paymentData: ');
            // return reject(new Error('Usuário sem paymentData: ' + paymentData))
            return null
          }
          // console.log('Teste 02', paymentDoc.data());
          await commit('SET_CURRENT_PAYMENT', paymentData)
          resolve()
        })
    })
  },

  loadUserAdmin({ commit, getters, state, dispatch }, userId) {
    return new Promise(function (resolve, reject) {
      if (userId === unsubscribeUserAdminId) {
        return resolve()
      }
      unsubscriber()
      unsubscribeUserAdminId = userId
      unsubscribeUserAdmin = firebase
        .firestore()
        .collection('adminUsers')
        .doc(userId)
        .onSnapshot(async function (userDoc) {
          let userData = userDoc.data()
          if (!userData) {
            console.error('Usuário sem userData userId: ' + userId)
            console.log('Tentando corrigir o erro de workspace incorreto')
            await firebase.functions().httpsCallable('auth-checkWorkspace')({
              id: userId,
            })
            await dispatch('makeLogOut')
            return reject(new Error('Usuário sem userData userId: ' + userId))
          }
          userData.id = userId

          await commit('SET_CURRENT', {
            workspace: workspaceDefine.ADMIN,
            user: userData,
          })
          setDone()
          resolve()
        })
    })
  },

  async makeLogOut({ commit, dispatch }, { disableRedirect } = {}) {
    unsubscriber()
    await commit('SET_CURRENT')
    setDone()
    await dispatch('chat/changeAuth', null, {
      root: true,
    })
    //  if(firebase.apps.length>0) {
    try {
      await firebase.auth().signOut()
    } catch (error) {
      // An error happened.
      console.error('Error on logout', error)
    }
    // }
    if (
      router &&
      router.currentRoute &&
      router.currentRoute.meta &&
      router.currentRoute.meta.authRequired
    ) {
      let workspace = router.currentRoute.meta.workspace
      console.log(defaults.routeNotLogged[workspace] || 'login')
      router.push({
        name: defaults.routeNotLogged[workspace] || 'login',
      })
    }
  },

  async companyChangeOpen(
    { commit, getters, state, dispatch },
    { type, active }
  ) {
    if (state.currentCompany && state.currentCompany.id) {
      let isOpen = false
      let name = ''
      switch (type) {
        case 'acceptTakeout':
          if (
            active ||
            state.currentCompany.acceptDelivery ||
            state.currentCompany.acceptOnSite
          ) {
            isOpen = true
          }
          name = 'takeout'
          break
        case 'acceptDelivery':
          if (
            active ||
            state.currentCompany.acceptTakeout ||
            state.currentCompany.acceptOnSite
          ) {
            isOpen = true
          }
          name = 'delivery'
          break
        case 'acceptOnSite':
          if (
            active ||
            state.currentCompany.acceptDelivery ||
            state.currentCompany.acceptTakeout
          ) {
            isOpen = true
          }
          name = 'onSite'
          break
      }
      let obj = {
        isOpen: isOpen,
      }
      obj[type] = active || false
      let nextChange = timeUtils.companyNextClose(
        state.currentCompany,
        name,
        active ? 'end' : 'ini'
      )
      obj.nextChange = nextChange.toDate()
      console.log('nextChange', nextChange.format())

      await firebase
        .firestore()
        .collection('companies')
        .doc(state.currentCompany.id)
        .set(obj, {
          merge: true,
        })
    }
  },
}

// ===
// Private helpers
// ===

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(
    key,
    JSON.stringify(state, function (k, v) {
      if (
        (typeof v === 'object' &&
          v &&
          (v.wa === 'FieldValue.serverTimestamp' ||
            v.fa === 'FieldValue.serverTimestamp')) ||
        k === 'updatedAt'
      ) {
        return null
      }
      return v
    })
  )
}

function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.currentUser
    ? state.currentUser.token
    : ''
}
