module.exports = {
  order: {
    title: 'Order',
    order: 'Order',
    date: 'Date/Time',
    via: 'Via',
    paymentTitle: 'Payment',
    paymentOnTakeout: 'Withdrawal Payment',
    clientName: 'Name',
    clientPhone: 'telephone',
    clientDocumentID: 'CPF',
    product: 'Product',
    qtd: 'Qtd',
    viaOption: {
      '0': 'Box / Counter',
      '1': 'Internal / Kitchen',
      '2': 'Deliveryman',
    },
    subTotal: 'Subtotal',
    total: 'Total',
    credits: 'Credits',
    deliveryTax: 'Delivery',
    changeFor: 'Change for',
    change: 'Change',
    address: 'Address',
    paymentType: 'Type of payment',
    type: 'Type',
    deliveryAddress: 'Address',
    deliveryType: 'Type of order',
    value: 'Value',
    itemName: 'Product',
    itemQtd: 'Amount',
    itemValueTotal: 'Total',
    payment: 'Payment',
    status: 'State',
    time: 'Date',
    newOrderNotificationTitle: 'New request',
    newOrderNotificationBody: 'A new request has just been received',
    changeForStatus: 'Change to',
    number: 'Request number',
    code: 'Request code',
    numberSlim: 'Number',
    codeSlim: 'Code',
    distance: 'Distance',
    itemObservation: 'Note',
    obsOrder: 'Note request',
    ingredientsRestrictions: 'Restriction of ingredients',
  },
  paymentOptions: {
    bankCheck: 'Payment in check',
    creditCard: 'Credit Card Payment',
    debitCard: 'Debit Card Payment',
    money: 'Cash payment',
    paymentOptionsSub: 'Payment options',
    acceptPaymentOnApp: 'Accept payment on the App',
    acceptCashPayment: 'Accept cash',
    acceptPaymentOnDelivery: 'Payment accepted on delivery',
    placeholderCards: 'Add accepted cards',
  },
  format: {
    data: 'MM/DD/YYYY',
  },
  deliveryType: {
    delivery: 'Delivery',
    pickup: 'Withdraw in store',
    onSite: 'On Request',
  },
  message: {
    hello: 'hello world2',
  },
  login: {
    access: 'Access Clinic',
    accessCompany: 'Access Clinic',
    errorCompany: 'No company associated with this email',
    login: 'Log In',
    goReset: 'I forgot my password',
    goLogin: 'Return to login',
    welcome: 'Welcome to Sauver',
    accessClient: 'Customer Access',
    accessAdmin: 'Admin access',
    facebook: 'Sign in with Facebook',
    google: 'Sign in with Google',
    phone: 'Login with Phone',
    apple: 'Sign in with Apple',
    getPhoneNumber: 'Enter your phone number',
    getPhoneCode: 'Enter your verification code sent to your mobile phone',
    resetCompany: 'Reset Password',
    reset: 'Send email',
    error: {
      generic: 'There was an error logging in',
      phoneEmpty: 'Empty phone number',
      phoneCodeEmpty: 'Empty verification code',
      loginTitle: 'Error logging in',
      providerTitle: 'Error adding provider',
    },
    providerCode: {
      'auth/invalid-email': 'Invalid email',
      'auth/user-disabled': 'Account disabled',
      'auth/user-not-found': 'User not found',
      'auth/wrong-password': 'Incorrect password',
      'auth/credential-already-in-use': 'Credential already used',
      'auth/too-many-requests': 'Too many attempts, try again later',
      'auth/operation-not-allowed':
        'Login type not configured, try another form of login',
    },
    addFacebook: 'Add Facebook',
    addGoogle: 'Add Google',
    addApple: 'Add Apple',
    providers: 'Authentication methods',
    remove: 'To remove',
    providerId: 'provider',
    email: 'email',
    password: 'password',
    uid: 'uid',
    changePassword: 'Change Password',
    addPassword: 'Add Password',
    resetClient: 'Reset password',
    registerClient: 'Register',
    register: 'Register',
    googleRegister: 'Register with Google',
    facebookRegister: 'Register with Facebook',
    emailRegister: 'Register with email',
    appleRegister: 'Register with Apple',
    phoneRegister: 'Registration with Telephone',
    resetPassword: 'Reset password',
    makeRegister: 'Register',
    accessOng: 'Access Ong',
    makeResetPassword: 'Define new password',
  },
  all: {
    registerNewUser: 'create a pre-user',
    telemedicine: 'Telemedicine',
    store: 'Sauver Store',
    emptyListMessage: 'No results available yet ...',
    logout: 'logout',
    examsTemplates: 'Exams Templates',
    admin: 'Admin',
    pending: 'Pendencies',
    uploads: 'Uploads',
    layout: 'Layout',
    technicalArea: 'Technical Area',
    doctors: 'Doctors',
    about: 'On',
    additionals: 'Additional',
    allRight: 'All rights reserved © 2020',
    android: 'Android App',
    ask: 'Common questions',
    attendance: 'Attendance',
    blog: 'Blog',
    blogCategories: 'Blog Categories',
    cancel: 'Cancel',
    cards: 'Cards',
    categories: 'Categories',
    categoriesProducts: 'Product Categories',
    category: 'Category',
    tuss_upload: 'Upload Tuss Tabel',
    changeImage: 'Change image',
    chat: 'messages',
    clients: 'Customers',
    clinic: 'Clinic',
    clinics: 'Clinics',
    close: 'Close',
    companiesTags: 'Tags Company',
    companyConfigs: 'Settings',
    company_tags: 'Tags Company',
    conclude: 'Conclude',
    create: 'New',
    createProduct: 'Create Product',
    dashboard: 'Dashboard',
    delivery: 'Delivery',
    demographics: 'Demographics',
    edit: 'To edit',
    edit_category: 'Edit Category',
    editProduct: 'Edit Product',
    editSubCategory: 'Edit Subcategory',
    examCategories: 'Exam categories',
    exams: 'My Exams',
    examTemplates: 'Exam Templates',
    exam_request: 'My Exams Requests',
    exam_template: 'My Exams Templates',
    examsClients: 'Customer exams',
    faq: 'FAQ',
    forDoctors: 'For doctors',
    healthDataFields: 'Health data type',
    help: 'Help',
    history: 'History',
    information: 'Information',
    informations: 'Information',
    insurances: 'Covenants',
    ios: 'IOS App',
    isPreUser: 'Pre registration',
    jobs: 'Jobs',
    knowMore: 'Learn More',
    medicament_upload: 'Upload drug list',
    new: 'new',
    newPrescription: 'New Prescription',
    prescription: 'Prescription',
    next: 'Next',
    nothingToList: 'Empty List',
    notImplemented: 'Not implemented',
    of: 'of',
    orders: 'Orders',
    otherOptions: 'Others',
    pages: 'Pages',
    paymentOptions: 'Payment',
    performance: 'Performance',
    prescriptions: 'My prescriptions',
    prescriptionsClients: 'Customer requirements',
    press: 'Press',
    products: 'Products',
    productsCategories: 'Categories',
    productsIngredients: 'Ingredients',
    productsNags: 'Product Tags',
    productsNutritional: 'Nutritional',
    productsSubCategories: 'Sub Categories',
    productsTags: 'Product Tags',
    product_nutritional: 'Nutritional',
    product_tags: 'Tags Product',
    profile: 'Profile',
    promotions: 'Promotions',
    recent: 'Recent news',
    region: 'Region',
    regions: 'Regions',
    regionUtcOffset: 'UtcOffset',
    resources: 'App features',
    restaurant: 'Clinic',
    restaurants: 'Clinics',
    company: 'Clinic',
    companies: 'Clinics',
    reviews: 'Reviews',
    schedule: 'Schedule',
    search: 'Search',
    searchInList: 'Find in list',
    navigation: 'menu',
    settings: 'Settings',
    settingsProducts: 'Menu Settings',
    specialities: 'Specialties',
    status: 'Service status',
    step: 'Stage',
    subscriptions: 'Subscriptions',
    support: 'Support',
    tagAssociate: 'Associated tag',
    tags: 'Tags',
    units: 'Unit of measurement',
    users: 'Users',
    vdTotal: 'VD total',
    clients_pending_doctor: 'Pending doctors',
    clients_pending_profile: 'Pending patients',
    exams_template: 'Exam templates',
  },
  company: {
    profile: {
      info: 'Company Info',
      contact: 'Contacts',
      insurances: 'Insurances',
      address: 'Localization',
      email: 'e-mail',
      phone: 'phone',
    },
    doctors: {
      associateSuccess: 'Associated Doctors',
      associateSuccessMsg: 'Associated Doctors updated',
      associateErrorMsg: 'Associated Doctors fail',
      searchInList: 'search in company doctors',
      deleteDoctor: 'Exclude this doctor',
      deleteDoctorHint:
        'so that this doctor comes back to this list, add it in the side tab.',
      tabTitles: {
        add: 'Add',
        search: 'Search',
        associatedDoctors: 'Associated doctors',
      },
    },
    users: {
      addUserTitle: 'New user info',
      fieldsRequired: 'all fields are mandatory',
      rulesTitle: 'Permissions for this user',
      rulesHint: 'these options can be changed',
      optionsTitle: 'Other options',
      optionsHint: 'hint',
      passwordTitle: 'Access Information',
      password_hint: 'hint',
      associateSuccess: 'Associated Doctors',
      associateSuccessMsg: 'Associated Doctors updated',
      associateErrorMsg: 'Associated Doctors fail',
      searchInList: 'search in company users',
      deleteDoctor: 'Exclude this user',
      deleteDoctorHint:
        'so that this user comes back to this list, add it in the side tab.',
      tabTitles: {
        add: 'Add',
        search: 'Search',
        associatedDoctors: 'Associated users',
      },
    },
    nav: {
      company: 'Company profile',
      users: 'Users',
      onwUsers: 'own users',
      clients: 'Clients',
      ownClients: 'own clients',
      doctors: 'Doctors',
      ownDoctors: 'own doctors',
      associatedDoctors: 'associated doctors',
      preusers: 'pre-users',
    },
    general: {
      preuserEdit: 'pre-user edit',
    },
  },
  admin: {
    pendingDoctors: {
      approve: 'Aprovar',
      reject: 'Rejeitar',
    },
    search: {
      findClient: 'Find the patient',
      searchClient: 'Search for a patient',
      clientActive: 'active',
      clientUnactive: 'inactive',
      add: 'add',
      new: 'new',
    },
    list: {
      status: {
        approved: 'Approved',
        rejected: 'Rejected',
        pending: 'Pending',
        unknow: 'unknow',
        preUser: 'pre-user',
        notSend: 'not sent',
      },
      options: {
        quickOptions: 'quick actions',
        some: 'example: unactive',
        hint: 'define quick actions',
        filter: 'sort',
        options: 'options',
      },
      filter: {
        approved: 'Approved',
        rejected: 'Rejected',
        pending: 'Pending',
        doctors: 'Doctors',
        patient: 'Patients',
        preUser: 'Pre-users',
        itemsPerPage: 'items per page',
        clear: 'clear',
      },
      type: {
        doctor: 'Médico',
        patient: 'Paciente',
      },
      error: {
        inconsistency: 'inconsistent user',
      },
    },
    edit: {
      client: 'Edit this client information',
      turnDoctor: 'Register as a Doctor',
    },
  },
  schedule: {
    welcome: {
      title: "Agenda Sauver",
      label: "",
    },
    actions: {
      enable: "Habilitar",
      disable: "Desabilitar",
      config: "Configurar",
    },
    event: {
      title: 'Evento da Agenda',
      name: 'Nome do Evento',
      description: 'Descrição do evento',
      dateBegin: 'Data de início',
      dateEnd: 'Data de fim',
      timeBegin: 'Hora de início',
      timeEnd: 'Hora de fim',
      actions: {
        create: "Criar Evento",
        read: "Visualizar Evento",
        update: "Salvar Evento",
        delete: "Excluir Evento",
        block: "Bloquear",
      },
      time: {
        start: "Início",
        end: "Fim"
      },
      date: {
        start: "Inicia em",
        end: "Finaliza em",
      },
      status: {
        locked: 'bloquado',
      }
    },
    week: {
      "0": "Domingo",
      "1": "Segunda",
      "2": "Terça",
      "3": "Quarta",
      "4": "Quinta",
      "5": "Sexta",
      "6": "Sábado"
    },
    type: {
      week: "Semana",
      dayly: "Dia",
      month: "Mês",
    },
    to: {
      today: "Hoje",
    },
    errors: {
      onLoadSchedule: {
        title: "Error ao carregar dados da Agenda.",
        hint: "Prima o botão de recarregar. se o error persistir contate-nos.",
      },
      onReloadSchedule: {
        title: "Error ao recarregar dados da Agenda.",
        hint: "Prima o botão de recarregar. se o error persistir contate-nos.",
      },
      onDragUpdateEvent: {
        title: "Erro ao atualizar evento",
        hint: "",
      },
    },
    pickerMenu: "Calendário",
    dayWeek: "Dia da semana",
    enable: "Habilitar",
    disable: "Desabilitar",
    pattern: "Padrão",
    from: "Das",
    at: "às",
    andFrom: "das",
    split: "Dividir",
    continuous: "Contínuo",
    saveChanges: "Salvar Alterações",
    newEvent: "Novo evento",
    event: "Evento",
    dateStart: "Data Início",
    dateEnd: "Data Fim",
    hourStart: "Início",
    hourEnd: "Fim",
    services: "Serviços",
    totalPrice: "Preço Total",
    saveEvent: "Salvar Evento",
    deleteEvent: "Apagar Evento",
    title: "Título",
    block: "Bloqueio",
    toBlock: "Bloquear",
  },
  searchTabs: {
    tabTitles: {
      add: 'Add',
      search: 'Search',
      associatedDoctors: 'Associated doctors',
    },
    searchNewDoctor: 'Search a new doctor...',
    searchNewUser: 'Search a new user...',
    searchDoctor: 'Search these doctors...',
  },
  dashboard: {
    title: 'Welcome to Sauver Dashboard',
    examsOrders: 'Your updates: ',
    newOrders: 'Novos Pedidos: ',
  },
  form: {
    client: {
      name: 'Name',
      lastName: 'Surname',
      cpf: 'Social Security Number',
      email: 'Email',
      phone: 'Telephone',
    },
    error: {
      required: 'This field is required',
      minLength: 'The field is too short',
      maxLength: 'The field is too big',
      update: 'There was some error updating the data',
      warn: 'warn',
      email: 'Invalid email',
      image: {
        type: 'Tipo de arquivo inválido, esperado: PNG, JPEG',
        upload: 'Erro ao fazer ulpload da imagem, tente novamente mais tarde!',
      },
      pdf: {
        type: 'Invalid, expected file type: PDF',
        upload: 'Error uploading PDF, try again later!',
      },
      formInvalid: 'Invalid field: ',
      formUserRequired: 'Select a patient',
      dosageRequired: 'all items require dosage',
      uploadImage: 'Error while uploading image',
      closeEditor: 'Please complete or cancel the editor before proceeding',
      showOnMenu: 'Show in menu',
      regionAreaName: 'Error fetching region area name',
      schedule: 'Error reducing number of scales',
      tagType: 'The tag must be applicable to products and or companies',
      onUpdate: 'Wait for the previous operation to complete',
      selectDate: 'Select the date',
      selectPdf: 'Selecione um Pdf',
      selectImage: 'Selecione uma Imagem',
      selectPdfImage: 'Selecione um arquivo',
      selectType: 'Houve um erro com seus arquivos',
      password: 'Password must contain an upper case letter, a numeric character, and a special character',
    },
    confirmDelete: 'delete',
    cancelDelete: 'don`t delete',
    continue: 'continue',
    active: 'ative',
    unactive: 'unactive',
    save: 'save',
    undo: 'Undo',
    add: 'Add',
    edit: 'Edit',
    editAll: 'Edit all',
    permissionHint: 'Select up to 3 doctors',
    permissionHint: 'Select up to 3 doctors',
    saveTemplate: 'Save Template',
    editTemplate: 'Edit Template',
    update: 'Update',
    refAExamOrder: 'Select a Exam Order',
    companyName: 'Name of clinic',
    corporateName: 'Corporate name',
    updateSuccess: 'Saved data',
    ownerName: 'Owner name',
    email: 'Email',
    password: 'Password',
    continue: 'Continue',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    name: 'Name of Request',
    userName: 'Name of Patient',
    name: 'Name',
    lastName: 'Surname',
    prevStep: 'Previous step',
    nextStep: 'Next step',
    conclude: 'Conclude',
    close: 'Close',
    cancel: 'Cancel',
    back: 'Back',
    changePrimaryColor: 'Change primary color',
    changeSecondaryColor: 'Change secondary color',
    emailResetSuccess:
      'In a moment you will receive an email to reset your password',
    emailResetError: 'There was an error trying to send password reset email',
    changePassword: 'Change Password',
    passwordNew: 'New password',
    passwordOld: 'Current Password',
    info: 'Information of the clinic',
    address: {
      city: 'City',
      complement: 'Complement',
      country: 'Country',
      district: 'District',
      number: 'Number',
      search: 'Search',
      state: 'State',
      street: 'Street',
    },
    contact: 'Contact',
    description: 'Description',
    phone: 'Telephone',
    delete: 'Delete',
    edit: 'Edit',
    add: 'Add',
    permission: 'Permission',
    active: 'Active',
    create: 'Create',
    isLiquid: 'It is liquid',
    isMandatory: 'Mandatory',
    numberOfChoices: 'Maximum of choices (0 without limit)',
    print: 'Print',
    showIds: 'Show Ids',
    visible: 'Visible',
    required: 'Required',
    nutritionalUnit: 'Unit of measurement',
    showOnMenu: 'Show in menu',
    isCash: 'And money?',
    getDeliveryAreaName: 'Delivery area name',
    getRegionName: 'Region area name',
    isProduct: 'Applicable on products',
    isCompany: 'Applicable in companies',
    isDoctor: 'I&#39;m a doctor',
    CRM: 'CRM',
    placeholderInsurances: 'Accepted Insurances',
    specialty: 'Medical specialty',
    addressDoctor: 'Service address',
    category: 'Category',
    title: 'Title',
    slug: 'URL',
    abstract: 'abstract',
    bodyText: 'Content',
    emptyResult: 'No results',
    lang: {
      ptbr: 'Br',
      en: 'EN',
    },
    isBookmarkable: 'Can be added to favorites',
    confirmQuestion: 'Are you sure you want to perform this action?',
    confirmDeleteFile: 'Are you sure you want to remove this file?',
    confirmQuestionYes: 'Yes',
    confirmQuestionCancel: 'Cancel',
    search: {
      emptyResult: 'No results found',
    },
    placeholderSpecialities: 'Select specialties',
    approve: 'To approve',
    reject: 'Reject',
    doctors: 'Doctors',
    remove: 'Delete',
  },
  register: {
    increaseSails: '',
    discoverAdvantages: '',
    singIn: 'Register your clinic!',
    ifAccountExist: 'If you already have an account, please login below',
    orSingIn: 'or create a new account to get started.',
  },
  navBar: {
    signIn: 'Register',
    logIn: 'Enter',
    logOut: 'Exit',
    orSignIn: 'Or sign up',
    singIn: 'Become a partner',
  },
  image: {
    crop: 'Crop',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    rotateLeft: 'Rotate Left',
    rotateRight: 'Rotate Right',
    mirrorY: 'Mirror Y',
    mirrorX: 'Mirror X',
  },
  profile: {
    title: {
      client: 'Information',
      clientInfo: 'User Information',
      professionalInfo: 'Professional Information',
      patientInfo: 'Patient Information',
      admin: 'Information',
      admin: 'Admin Information',
      company: 'Company Information',
      user: 'User Information',
    },
    doctorRequest: {
      title: 'Request status:',
      status: 'status',
      request: 'Require Verification',
      hint:
        'Not yet requested, fill in your information to submit your registration for analysis.',
      rejectText: 'Reason for not approving:',
      rejectedHint: 'you can make another request in the application',
      approved: 'Okay',
      rejected: 'Accepted',
      pending: 'Pending',
      hintPending: 'Your request has been sent, please wait for a response.',
      notSend: 'Not Send',
    },
    profileRequest: {
      title: 'Request status:',
      status: 'status',
      request: 'request',
      requested: 'requested',
      resend: 'request again',
      sendRequest: 'send request',
      hint:
        'Not yet requested, add a photo that shows your face and a document',
      rejectText: 'Reason for not approving:',
      approved: 'Approved',
      rejected: 'Rejected',
      pending: 'Pending',
      hintPending: 'Your request has been sent, please wait for a response.',
      notSend: 'Not Send',
    },
    form: 'Formulário',
    save: 'Save',
    update: 'Update',
    edit: 'Edit',
    editAccount: 'Edit Account',
    cancel: 'Cancel',
    account: 'Account: ',
    healthInsurance: 'Health Insurance',
    specialty: 'Specialty',
    youAreSure: 'Reject?',
    youAreWantCancelOrder: '',
    youAreYes: 'Yes',
    youAreNo: 'Not',
    cancelReason: 'Reason for rejection',
    errorIsDoctor:
      'You must fill in your details as a doctor or unmark the Sou Médico flag',
    doctorRequestAdmin: 'Professional Request',
  },
  promotion: {
    step: 'Stage',
    of: 'of',
    createPromotion: 'Create promotion',
    editPromotion: 'Edit promotion',
    infoSub: 'Promotion Information',
    line1: 'Line1',
    line2: 'Line2',
    description: 'Promotion Description',
    behaviorSub: 'Promotion operation',
    behaviorAll: 'Apply to all orders',
    behaviorAllDesc: 'Apply promotion to all orders from any customer.',
    behaviorFirst: 'Apply only to the customer&#39;s first order',
    behaviorFirstDesc:
      'Apply promotion only upon the first request of a new customer.',
    behaviorCoupon: 'Apply with promotional code',
    behaviorCouponDesc: 'Apply promotion by means of a promotional code.',
    typeSub: 'Promotion type',
    typeDelivery: 'Delivery free',
    typeDeliveryDesc:
      'Exemption from the rate of delivery of orders via delivey.',
    typePercent: 'Percent discount',
    typePercentDesc:
      'Apply a percentage discount on the value of the products.',
    typeValue: 'Discount amount',
    typeValueDesc: 'Discard a given value at the end of the order.',
    typeGift: 'Toast',
    typeGiftDesc: 'Include one or more products for free upon request.',
    applyInSub: 'Scope of promotion',
    applyInAll: 'Full menu',
    applyInAllDesc: 'The promotion will apply to all items on your menu.',
    applyInCategories: 'Selected Categories',
    applyInCategoriesDesc:
      'Select one or more categories from the menu to apply the promotion.',
    applyInProducts: 'Selected Products',
    applyInProductsDesc:
      'Select specific products to which the promotion applies.',
    configPromotion: 'Set up promotion',
    durationSub: 'Duration and validity',
    isValidity: 'Duration determined',
    isValidityDesc: 'Determine a date to end the promotion automatically.',
    isRecurrent: 'Recurring Promotion',
    isRecurrentDesc: 'Schedule the promotion to automatically repeat itself.',
    restrictionsSub: 'Promotion Restrictions',
    isQuantity: 'Limit amount',
    isQuantityDesc: 'Determine an amount of requests to end the promotion.',
    isTakeOut: 'Take-out',
    isTakeOutDesc: 'Promotion applies to products withdrawn from the store.',
    isDelivery: 'Delivery',
    isDeliveryDesc: 'Promotion applies to products delivered via delivery.',
    isMinValue: 'Minimum value',
    isMinValueDesc: 'Promotion applies to orders with a minimum value.',
    detailsPromotion: 'Promotion Details',
    discountValueSub: 'Discount amount',
    discountPercent: 'Percent discount',
    discountValue: 'Discount amount',
    recurrentNot: 'Do not repeat the promotion',
    recurrentNotDesc: 'Promotion will not be repeated',
    recurrentSub: 'Promotion recurrence',
    recurrentWeekly: 'Weekly',
    recurrentWeeklyDesc:
      'The promotion will be repeated at the same time once a week',
    recurrentDaily: 'Daily',
    recurrentDailyDesc:
      'The promotion will be repeated every day at the same time',
    recurrentMonthly: 'Monthly',
    recurrentMonthlyDesc:
      'The promotion will be repeated on the same day and time every month',
    limitQuantity: 'Order limit',
    minValue: 'Minimum value',
    dateBegin: 'Start date',
    dateEnd: 'End date',
    hourBegin: 'Start Time',
    hourEnd: 'End time',
    recurrentWeeklySub: 'Select the days of the week',
    recurrentMonthlySub: 'Select the day of the month',
    duration: 'Duration of promotion',
  },
  calendar: {
    week: {
      '0': 'Sunday',
      '1': 'Monday',
      '2': 'Tuesday',
      '3': 'Fourth',
      '4': 'Fifth',
      '5': 'Friday',
      '6': 'Saturday',
    },
  },
  access: {
    title: 'Access',
    admin: 'Administrator',
    editor: 'Editor',
    masterEditor: 'Master Editor',
    attendant: 'Clerk',
    delivery: 'Deliveryman',
  },
  tools: {
    title: 'Tool',
  },
  deliveryOptions: {
    deliveryConfig: 'Delivery configuration',
    deliveryErrorLatlng: 'Set the business address first',
    kmValue: 'Delivery value per km',
    minValue: 'Minimum value',
    roundAt: 'Round to each',
  },
  otherOptions: {
    acceptDelivery: 'Allows delivery orders',
    acceptDeliveryDesc: 'Accept deliveries',
    acceptPaymentOutApp: 'Accepts payment outside the app',
    acceptPaymentOutAppDesc: 'Accepts payment outside the App',
    acceptSails: 'Accepts in-app sales',
    acceptSailsDesc: 'Accepts sales',
    acceptTakeAtEstablishment:
      'Allows requests to be removed at the establishment',
    acceptTakeAtEstablishmentDesc: 'Accept Takeout',
    otherOptionsSub: 'Other configurations',
    showOnlyCategoryOnMenu: 'Show only Subcategories in Menu',
    showOnlyCategoryOnMenuDesc: 'One-level menu',
    acceptTakeout: 'Accepts Takeout',
    acceptOnSite: 'Accepts pick up at store',
    timeForDelivery: 'Average delivery time',
    timeForDeliveryMin: 'In',
    timeForDeliveryMax: 'Up until',
    timeForPickupAndOnSite: 'Average withdrawal time',
    timeForPickupAndOnSiteMin: 'In',
    timeForPickupAndOnSiteMax: 'Up until',
  },
  product: {
    nutritionalName: 'Name',
    nutritionalValue: 'Value',
    nutritionalPortion: 'Portion',
    nutritionalValuePortion: 'Value Portion',
    nutritionalValueVd: 'VD %',
    vdDaily: 'VD Daily',
    description: 'description',
    name: 'Name',
    nutritional: 'Nutritional',
    portion: 'Portion',
    value: 'Price',
    placeholderTags: 'Choose Tags',
    placeholderNutritional: 'Choose nutritional items',
    placeholderIngredients: 'Choose the ingredients',
    notVisible: 'Not visible',
    category: 'Category',
    variantName: 'Variation',
    variantValue: '',
  },
  multiSelect: {
    selectLabel: 'Press enter to select',
    selectGroupLabel: 'Press enter to select group',
    selectedLabel: 'Selected',
    deselectLabel: 'Press enter to remove',
    deselectGroupLabel: 'Press enter to deselect group',
    placeholder: 'Select option',
  },
  additional: {
    additionals: 'Additional',
    editAdditional: 'Edit Additional',
    freeText: 'Free options',
    itemTitle: 'Option name',
    itemValue: 'Option value',
    placeholder: 'Applicable Categories',
    title: 'Additional',
    type: 'Type',
    variation: 'Variants',
    inputTitle: 'Title',
    flavor: 'Multiple flavors',
    allVariations: 'All variations',
  },
  listItems: {
    noGroup: 'Uncategorized Product',
    active: 'active',
    unactive: 'unactive',
  },
  orderStatus: {
    canceled: 'Order Canceled Request',
    confirmed: 'Order Confirmed',
    deliveryReady: 'Delivery Completed',
    takeoutReady: 'Order Completed',
    inPreparation: 'In preparation',
    makingOrder: 'Riding Order',
    waitDelivery: 'Awaiting Delivery',
    onDelivery: 'In delivery',
    waitConfirm: 'Waiting confirmation',
    waitPayment: 'Awaiting payment',
    waitTakeout: 'Waiting for Withdrawal',
  },
  orderType: {
    takeout: 'Takeout',
    undefined: 'Not defined',
  },
  paymentStatus: {
    confirm: 'Payment confirmed',
    undefined: 'Payment not defined',
  },
  companyInfo: {
    title: 'Company Info',
    das: 'Das',
    as: 'At',
    isClose: 'Closed',
    attendanceTitle: 'Service Hours',
    paymentTitle: 'Accepted payment methods',
  },
  timeElapsed: {
    '12': '12 hours',
    '24': '24 hours',
    '48': '2 days',
    '120': '5 days',
    '240': '10 days',
    '720': '30 days',
    label: 'Order Period',
  },
  notifications: {
    examTitle: 'Your exam is available',
    chatCall: {
      title: 'Recebendo uma chamada',
      body: 'está te convidando para uma telemedicina'
    },
    contactRequest : {
      title: 'New contact permission request',
      body: 'are requesting you to accept his contact request'
    },
    contactRequestAnswer: {
      titlePositive: 'Your contact request was accepted',
      bodyPositive: 'accepted your request, now you can contact him through Sauver!',
      titleNegative: 'Your contact request was declined',
      bodyNegative: 'declined your request to contact him!'
    },
    notifications: {
      examTitle: 'Your exam is available',
    },
  },
  rules: {
    adm: {
      adm: 'Administrator',
      sell: 'Salesman',
    },
    comp: {
      adm: 'Administrator',
      edit: 'Editor',
      mEdit: 'Master Editor',
      deli: 'Delivery',
      att: 'Clerk',
    },
  },
  router: {
    accessDenied: 'Access denied',
  },
  orderError: {
    statusError:
      'The bag is in an invalid state to be transformed into an order',
    permissionDenied: 'The user is not allowed to change this bag',
    unknown: 'Unknown error',
    paymentError: 'Error making payment',
    bagError: 'There is an error with the bag',
  },
  maps: {
    areaDeliveryInfo: 'Delivery area',
  },
  prescription: {
    searchMedicament: 'Medicament',
    dosage: 'Dosage',
    selectPatient: 'Select the patient',
    pdf: {
      medicament: 'Medicament: ',
      dosage: 'Dosage: ',
      CRM: 'CRM: ',
    },
    patient: 'Patient',
    doctor: 'Doctor',
  },
  list: {
    title: 'options',
    confirmDisable: 'Do you want disable this item?',
    visibility: 'visibility',
    hint: 'make this exam available to patient',
    turnUnavailable: 'deactivate',
    turnAvailable: 'activate',
  },
  exam: {
    view: {
      examDoctor: 'Exam requesting doctor',
      gallery: 'Images',
      galleryP: 'files',
      unevaluated: 'not evaluated',
      evaluated: 'evaluated',
    },
    doctorPermissions: 'Permissions',
    permission: {
      label: 'Permissions',
      hint: 'specify doctors who can view this exam',
      hint2: 'these doctors can see this exam',
    },
    pageLimitReached: '** limit of items on the page reached, please add another page.',
    currentClientView: 'you are viewing the exams',
    examDate: 'Exam Date',
    pickDate: 'Date',
    hintTable: 'fill all fields *',
    selectFileType: 'Select file type: ',
    label: 'Exam',
    name: 'Name',
    template: 'Template',
    newExam: 'new exam',
    viewAll: 'view all',
    newExamUpload: 'New exam upload',
    orderItemsList: 'Exams/Procedures for an order: ',
    evaluated: 'Exam status: ',
    edit: 'edit',
    exam_patient: 'Patient: ',
    selectFiles: 'Select file: ',
    selectImages: 'Select images: ',
    selectedFiles: 'Files: ',
    filesAttached: 'Images attached: ',
    select_template: 'Select Template',
    select_orderRef: 'Select Order',
    select_orderRef_items: 'Select the Exams/Procedures',
    orderItemsTagged: 'Your selected items: ',
    items_sent: 'Exams/Procedures uploaded',
    edit_order_items: 'edit exams/procedures',
    exam_upload: 'upload exam',
    exam_section: 'Exams',
    exam_page: 'Page',
    exam_template: 'Exam Template',
    exam_order: 'Exam Order',
    exam_userRef: 'I Request:',
    exam_date: 'Date: ',
    saveOrder: 'Save Order',
    generatePdf: 'generate pdf',
    patientName: 'Patient: ',
    clinical_indication: 'Clinical Indication: ',
    apply_template: 'Aplly Template',
    addItem: 'Add item',
    selectItem: 'Select exam',
    typeLst: {
      text: 'Text',
      select: 'Selection',
      checkbox: 'Options',
      number: 'Number',
    },
    type: 'Value type',
    referenceType: 'Reference value type',
    referenceText: 'Reference text',
    referenceMin: 'Above',
    referenceMax: 'Below',
    options: 'Options',
    addOption: 'Add option',
    optionName: 'Option name',
    unit: 'Unit of measurement',
    type2: 'Value type (2)',
    referenceType2: 'Reference value type (2)',
    referenceText2: 'Reference text (2)',
    referenceMin2: 'Above (2)',
    referenceMax2: 'Under (2)',
    options2: 'Options (2)',
    addOption2: 'Add Option (2)',
    optionName2: 'Option name (2)',
    unit2: 'Unit of measure (2)',
    unitLst: {
      none: 'Without drive',
      ml: 'ml',
      milhoesmm3: 'million/mm³',
      percent: '%',
      gDL: 'g/dL',
      fL: 'fL',
    },
    addSection: 'Add section',
    addPage: 'Add Page',
    nameOfSection: 'Section name',
    section: 'Section',
    has2values: 'Does it have secondary value?',
    isEvaluated: 'Has it been analyzed?',
    isReferenced:
      'This patient has exam requests. Do you want to add items from these orders?',
    orderEdit: 'Want to edit order items?',
    editOrderItems: 'Current items:',
    editOrderAddItems: 'Add more items: ',
    editOrderCompleted: 'This exam order is completed',
    nullOrderItems: 'This exam does not contain order items..',
    template: 'Template',
    addHealthDataFields: 'Add health data type',
    selectDoctors: 'Select doctors',
    selectPatient: 'Select the patient',
    selectDoctor: 'Select the requesting doctor',
    date: 'Exam date',
    fileType: {
      select: 'Select file type',
      pdf: 'PDF',
      image: 'Image',
      pdfImage: 'PDF and Image',
    },
    filePdf: 'Add PDF',
    fileImage: 'Add images',
    selectCompany: 'Clinic',
    removed: 'This exam no longer exists',
    patient: 'Patient',
    evaluatedYes: 'Examination dismembered',
    evaluatedNot: 'Examination not dismembered',
    company: 'Clinic',
    patient: 'Patient',
    notification: {
      newPermission: 'New exam permission',
      newExamOrderRef: 'Exams for an order were loaded. ',
      newExamAllowedRef: 'You have been granted a new exam permit.',
      newExamAllowedByC: 'granted him permission for an exam by',
      patient: 'Patient: ',
      newExamToDoctor: 'has a new exam for you.',
      examNotification: 'Exam Notification',
    },
  },
  blog: {
    title: '',
  },
  chat: {
    headerLabel: 'messages',
    newMessages: 'new messages',
    newChat: 'New conversation',
    writeSomething: 'Say something',
    dateFormat: 'DD MMMM YYYY',
    hourFormat: 'HH:mm',
    notification: {
      title: 'New message received',
      body: '',
    },
  },
  store: {
    title: 'Sauver Store',
    lebal: 'store',
  },
  payment: {
    pay: 'pay',
    amount: 'amount',
    currency: '$',
  },
  telemedicine: {
    tabs: {
      labels: {
        dashboard: 'Dashboard',
        credits: 'credits',
        logs: 'logs',
      },
    },
    configHeader: 'settings',
    configVideo: 'video options',
    configAudio: 'audio options',
    configRecord: 'recorder options',
    label: 'telemedicine',
    title: 'Telemedicine',
    count: 'outgoing calls',
    chat: 'messages',
    notes: 'notes',
    requestCredits: 'buy credits',
    call: 'call',
    initRoom: 'iniciar sala de atendimento',
    disconnect: 'disconnect',
    pause: 'Pausar',
    offerConnection: 'Chamar',
    answerConnection: 'Atender chamada',
    waitingConnection: 'Aguardando conexão',
    changeMicrophoneOn: 'ligar microfone',
    changeMicrophoneOff: 'desligar microfone',
    changeVideoOn: 'ligar câmera',
    changeVideoOff: 'desligar câmera',
    changeRecordOn: 'gravar',
    changeRecordOff: 'parar gravação',
    config: 'configurações',
    messages: {
      endByPatient: 'call ended by the patient.',
      endByDoctor: 'call ended by you.',
      rejected: 'call declined by the patient.',
      notAnswed: 'missed call',
    },
    credits: {
      label: 'credits',
      buyHint: 'action unavailable. contact us to request more credits.',
    },
  },
  tele: {
    offerConnection: "Start Call",
    answerConnection: "Accept Call",
    waitingConnection: "Waiting for Call",
    errorWaitConnection: "Wait for the patient to initiate the call",
    hangUp: "End Call",
    errorNavigator: "Couldn't get image, is your browser up to date?",
    errorHttp: "Use https to access this feature",
    errorStartingCam: "Error starting the camera",
    errorStartingScreen: "Error when starting screen sharing",
    errorStartRemoteVideo: "Error starting remote video component",
    errorStartLocalVideo: "Error starting local video component",
    errorOnRecord: "Error starting recording",
    errorUnknown: "Unknown error",
    errorTypeRecord: "A compatible media type was not found in your browser for recording",
    warnOnRecordType: "A compatible media type was not found in your browser for recording, we will try to record on webm",
    warnChangeTypeOnRecord: "This change will take effect on the next recording",
    warnNoTrackToRecord: "No media available for recording",
    selectVideo: "Select the video",
    selectInputAudio: "Select the microphone",
    selectOutputAudio: "Select the speaker",
    selectRecordType: "Select the recording type",
    startSharedScreen: "Share screen",
    stopSharedScreen: "Stop screen sharing",
    configHeader: "Settings",
    configVideo: "Camera",
    configAudio: "Audio",
    configRecord: "Recording",
    config: "Config",
    changeMicrophoneOn: "Mute",
    changeMicrophoneOff: "Unmute",
    changeVideoOn: "Stop Video",
    changeVideoOff: "Start Video",
    changeShareScreenOn: "Stop screen",
    changeShareScreenOff: "Share screen",
    changeRecordOn: "Stop recording",
    changeRecordOff: "Start recording",
    connectionState: {
      new: "Initializing ...",
      connecting: "Connecting ...",
      connected: "Connected",
      disconnected: "Disconnected",
      failed: "Connection fail",
      closed: "Connection closed"
    },
  },
  home: {
    yourData: 'Your data and medical care in one place',
    downloadApp:
      'Download the app and register to access your test results and the health services you are looking for anywhere',
    exam: 'Make your exams more practical',
    schedule:
      'Schedule your exams in the units of the partner laboratories and receive the results of your exams by the app within 24 hours.',
    do: 'Find experts and schedule your appointments',
    find:
      'Find the doctors closest to you, schedule appointments through the application and be attended in person or by video.',
    forward: 'Forward prescriptions and buy medication',
    send:
      'Send prescriptions, budget and buy through the application in partner pharmacies to receive medicines at home or collect on site.',
    tips: 'Health tips and articles from experts',
    practiality:
      'In addition to the convenience of making appointments, scheduling exams and receiving the result through the app, Sauver is full of content for you to find out about the most diverse treatments, answer your questions and stay on top of health news.',
    doAsk: 'Ask questions and get answers',
    talk:
      'Talk to your doctor between one appointment and another through the app and resolve any punctual questions with the Sauver specialists available at that moment for free.',
    consult: 'Audio and video inquiries and returns',
    beyond:
      'In addition to scheduling face-to-face consultations, daily appointments can be made via audio and video through the application - if the doctor deems it appropriate and the patient agrees - avoiding lines and commuting.',
  },
  emailReset: {
    subject: 'Reset password',
    head: 'Password reset email',
    body: '',
    link: 'Reset password',
  },
  profileStatus: {
    PENDING: 'Pending',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
  },
  examsOrders: {
    notification: {
      newExamOrder: 'Novo pedido de exame',
      ExamOrderBody: 'Novo pedido de exames, Dr. ',
    },
  },
}
