import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";
import en from "vuetify/es5/locale/en";
import themes from "@src/design/themes";
import Ripple from 'vuetify/lib/directives/ripple';
import '@mdi/font/scss/materialdesignicons.scss';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

function getIsDark() {
  return false;
  // let isDark = typeof window!=='undefined'?window.localStorage.getItem('darkMode'):null;
  // if(isDark==='true') {
  //   return true;
  // }
  // if(isDark==='false') {
  //   return false;
  // }
  // return true; // Setando dark mode como default
  // isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  // return !!isDark;
}


export default new Vuetify({
  lang: {
    locales: { pt, en },
    current: "pt",
  },
  iconfont: 'mdi',
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },

    dark: getIsDark(),
    themes: themes,
  }
});
