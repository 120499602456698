import firebase from 'firebase/app';

export const state = {
  hasPendingDoctors: false,
  hasPendingPatients: false,
  hasPendingUsers: false,
};

export const getters = {
  getHasPendingDoctors: () => (state.hasPendingDoctors),
  getHasPendingPatients: () => (state.hasPendingPatients),
  getHasPendingUsers: () => (state.hasPendingUsers),
};

export const actions = {
  async updateHasPendingDoctors( { commit } ) {
    commit('setHasPendingDoctors', false);

    const pendingDoctors = await firebase.firestore().collection('users').where('doctorRequest', '==', 'pending').where('active', '==', true).get();

    commit('setHasPendingDoctors', pendingDoctors.docs.length > 0)
  },
  async updateHasPendingPatients( { commit } ) {
    commit('setHasPendingPatients', false);

    const pendingPatients = await firebase.firestore().collection('users').where('patientRequest', '==', 'pending').where('active', '==', true).get();

    commit('setHasPendingPatients', pendingPatients.docs.length > 0)
  },
  async updateHasPendingUsers({ commit, dispatch }) {
    commit('setHasPendingUsers', false)

    await dispatch('updateHasPendingDoctors');
    await dispatch('updateHasPendingPatients');

    commit('setHasPendingUsers', state.hasPendingDoctors || state.hasPendingPatients);
  },
};

export const mutations = {
  setHasPendingDoctors($state, boolean) {
    const stateCopy = $state;
    stateCopy.hasPendingDoctors = boolean;
  },
  setHasPendingPatients($state, boolean) {
    const stateCopy = $state;
    stateCopy.hasPendingPatients = boolean;
  },
  setHasPendingUsers($state, boolean) {
    const stateCopy = $state;
    stateCopy.hasPendingUsers = boolean;
  }
};
