<template>
    <v-text-field
      :type="type"
      :value="value"
      :counter="maxlength || false"
      :maxlength="maxlength"
      :disabled="disabled"
      :label="label"
      @input="$emit('update', $event)"
      v-on="$listeners"
    ></v-text-field>
</template>

<script>
import {VTextField} from 'vuetify/lib';

export default {
  components: {
    'v-text-field': VTextField,
  },
  model: {
    event: 'update',
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
    label: {
      type: [String, Number],
      default: '',
    },
    maxlength: {
      type: [String, Number],
      default: null,
    },
    icon: {
      type: [String, Number,Boolean],
      default: false,
    },
    disabled: {
      type: [String, Boolean],
      default: false,
    },

  },
}
</script>

<style lang="scss" module>
@import '@design';

.input {
  @extend %typography-small;

  display: block;
  width: 100%;
  padding: $size-input-padding-vertical $size-input-padding-horizontal;
  margin-bottom: $size-grid-padding;
  line-height: 1;
  border: $size-input-border solid $color-input-border;
  border-radius: $size-input-border-radius;
}
</style>
