import firebase from 'firebase/app'
let configWeb = null;


export const state = {
  config: {},
  localConfig: {}
};

export const actions = {
  init({
    commit
  }) {
    if (!configWeb) {
      configWeb = firebase.firestore().collection('config').doc('web').onSnapshot((snapshot) => {
        let value = {
          ...snapshot.data(),
          id: snapshot.id
        };
        commit('updateConfig', value);
      });
    }
    commit('updateConfigLocal', getSavedState('examGroupView') || {});
  },

};

export const mutations = {
  updateConfig(state, newValue) {
    state.config = newValue;
  },

  updateConfigLocal(state, newValue) {
    state.localConfig = newValue;
    saveState('examGroupView', newValue)
  }
};


function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state, function (k, v) {
    if (typeof v === 'object' && v && (v.wa === 'FieldValue.serverTimestamp' || v.fa === 'FieldValue.serverTimestamp') || k === 'updatedAt') {
      return null;
    }
    return v;
  }))
}
