import firebase from 'firebase/app';

export const actions = {
  async toggleFreeTrial({ commit }, payload) {
    const paymentAccountId = payload.paymentAccountId;
    const unlimitedProductId = 'OhV0ZIdkxGfCcFgyJLYN';
    const basicProductId = 'l5H2tmDOoGfbJM5ErGcO';
    const toggleStatus = payload.toggleStatus;

    const product = {
      subscription: {
        s_prod_id: toggleStatus ? unlimitedProductId : basicProductId,
        status: toggleStatus ? 'active': 'incomplete',
        freeTrial: toggleStatus,
      }
    }

    await firebase.firestore().collection('paymentAccounts').doc(paymentAccountId).update(product);
  },
};
