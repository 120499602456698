export const state = {
  currentBreadcrumbs: [],
};

export const getters = {
  getCurrentBreadcrumbs: () => (state.currentBreadcrumbs),
};

export const actions = {
  async initCurrentBreadcrumbs({ commit }, payload) {
    commit('setCurrentBreadcrumbs', payload);
  },
  clearBreadcrumbs({ commit }) {
    commit('setCurrentBreadcrumbs', []);
  },
};

export const mutations = {
  setCurrentBreadcrumbs($state, payload) {
    const stateCopy = $state;
    stateCopy.currentBreadcrumbs = payload;
  },
};
