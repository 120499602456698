import {TinyEmitter} from 'tiny-emitter';
/*
  A partir do Vue 3, não é mais possível utilizar o Vue como eventHub, por isso, migrado para a tiny-emitter
 */

const emitter = new TinyEmitter();
export const eventHub = {
  on: (...args) => emitter.on(...args),
  once: (...args) => emitter.once(...args),
  off: (...args) => emitter.off(...args),
  emit: (...args) => emitter.emit(...args),

  /**
   * @deprecated $on foi excluído do Vue 3
   * @param args
   * @returns {TinyEmitter}
   */
  $on: (...args) => {console.warn('No eventHub, utilize "on" em vez de "$on"'); return emitter.on(...args)},

  /**
   * @deprecated $once foi excluído do Vue 3
   * @param args
   * @returns {TinyEmitter}
   */
  $once: (...args) => {console.warn('No eventHub, utilize "once" em vez de "$once"'); return emitter.once(...args)},

  /**
   * @deprecated $off foi excluído do Vue 3
   * @param args
   * @returns {TinyEmitter}
   */
  $off: (...args) => {console.warn('No eventHub, utilize "off" em vez de "$oof"'); return emitter.off(...args)},

  /**
   * @deprecated $emit foi excluído do Vue 3
   * @param args
   * @returns {TinyEmitter}
   */
  $emit: (...args) => {console.warn('No eventHub, utilize "emit" em vez de "$emit"'); return emitter.emit(...args)},
};
