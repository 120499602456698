<script>
  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      body: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      showDialog: false
    })
  }

  // class="md-simple md-success md-lg"
</script>

<template>
  <div>
    <v-btn icon :class="$style.button" @click="showDialog = true;">
      <v-icon class="app-icon" color="grey darken-1" small>mdi-help-circle-outline</v-icon>
    </v-btn>

    <v-dialog :class="$style.dialog" v-model="showDialog">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <span>{{title}}</span>
        </v-card-title>

        <v-card-text>
          <slot>
            {{body}}
          </slot>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              solo
              depressed
              dense
              color="primary"
              @click="showDialog = false;"
          >
            <span class="button-label">{{$t('all.close')}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<style lang="scss" module>
  @import '@design';

  .button {
    cursor: pointer;
  }

  .dialog {
    max-width: 600px;
    padding-left: 20px;
    padding-right: 20px;
  }


</style>
