module.exports = {
  all: {
    man: 'Homem',
    woman: 'Mulher',
    other: 'Outro',
    yes: 'Sim',
    description: 'Descrição',
    invalidCPF: 'CPF inválido',
    sauverPlans: 'Planos',
    visitor_forms: 'Formulário de Visitante',
    pending_management: 'Gestão de Pendência',
    visualizer: 'Visualizar',
    sauverSchedule: 'Agenda',
    database: 'Database View',
    editProfile: 'Editar Perfil',
    notifications: 'Notificações',
    passwordSecurity: 'Senha e ',
    awaitLoading: 'Um momento. Carregando...',
    loading: 'carregando',
    save: 'Salvar',
    change_status: 'Alterar Status',
    update: 'atualizar',
    continue: 'continuar',
    active: 'ativo',
    editItem: 'Editar item: ',
    allowEdit: 'Permitir edição',
    editMode: 'modo de edição',
    itemDetails: 'Detalhes do item: ',
    unactive: 'inativo',
    asSoon: 'Em breve Agenda Sauver',
    register: 'Cadastrar',
    register_user: "Cadastrar Usúario",
    register_company: 'Cadastrar Clínica',
    register_insurance: 'Cadastrar Convênio',
    register_exam_template: 'Cadastrar Template de Exame',
    register_exam_category: 'Cadastrar Categoria de Exame',
    register_health_data_field: 'Cadastrar Tipo de Dado',
    register_unit: 'Cadastrar Unidade de Medida',
    register_speciality: 'Cadastrar Especialidade',
    register_medicament_upload: 'Cadastrar Lista',
    register_tuss_upload: 'Cadastrar Tabela',
    register_blog_publication: 'Cadastrar Publicação',
    register_blog_category: 'Cadastrar Categoria',
    register_faq: 'Cadastrar FAQ',
    registerNewUser: 'cadastrar paciente',
    registrationPreuser: 'Formulário de Cadastro de Pré Usuário',
    telemedicine: 'Telemedicina',
    store: 'Loja Sauver',
    emptyListMessage: 'Ainda não há resultados disponíveis...',
    logout: 'sair',
    examsTemplates: 'Templates de Exames',
    admin: 'Administradores',
    pending: 'Cadastros Pendentes',
    clientsDoubts: 'Fale Conosco',
    uploads: 'Uploads',
    layout: 'Layout',
    technicalArea: 'Área Técnica',
    sauverDataInsertion: 'Configurações',
    doctors: 'Médicos',
    about: 'Sobre',
    additionals: 'Adicionais',
    sauver_description_footer_text: 'SAUVER - Tecnologia de Dados para Saúde',
    copyright: 'Todos os direitos reservados © 2019',
    android: 'App para Android',
    ask: 'Perguntas frequentes',
    attendance: 'Atendimento',
    has_no_account: 'Ainda não possui conta? Cadastre-se aqui agora',
    blog: 'Blog',
    blogCategories: 'Categorias de Blog',
    cancel: 'Cancelar',
    cards: 'Cartões',
    categories: 'Categorias',
    categoriesProducts: 'Categorias de Produtos',
    category: 'Categoria',
    tuss_upload: 'Tabela Tuss',
    changeImage: 'Alterar imagem',
    chat: 'conversas',
    clients: 'Usuários',
    clinic: 'Clínica',
    clinics: 'Clínicas',
    close: 'Fechar',
    companiesTags: 'Tags Empresa',
    companyConfigs: 'Configurações',
    company_tags: 'Tags Empresa',
    conclude: 'Concluir',
    create: 'Novo',
    createProduct: 'Criar Produto',
    product_description: 'Descrição do Produto:',
    dashboard: 'Dashboard',
    delivery: 'Entregas',
    demographics: 'Público',
    delete_item: "Deseja Deletar este Item?",
    edit: 'Editar',
    editProduct: 'Editar Produto',
    editInsurance: 'Editar Convênio',
    editSubCategory: 'Editar Subcategoria',
    edit_user: 'Editar Usúario',
    edit_company: 'Editar Clínica',
    edit_category: 'Editar Categoria',
    edit_exam_template: 'Editar Template de Exame',
    edit_exam_category: 'Editar Categoria de Exame',
    edit_health_data_field: 'Editar Tipo de Dado',
    edit_unit: 'Editar Unidade de Medida',
    edit_speciality: 'Editar Especialidade',
    edit_medicament_upload: 'Editar Lista',
    edit_blog_publication: 'Editar Publicação',
    edit_blog_category: 'Editar Categoria',
    edit_faq: 'Editar FAQ',
    edit_tuss_upload: 'Editar Tabela',
    examCategories: 'Categorias de Exame',
    exams: 'Meus Exames',
    examsLabel: 'Exames',
    examTemplates: 'Templates de Exames',
    exam_request: 'Pedidos de Exames',
    exam_template: 'Modelos de Exames',
    examsClients: 'Exames de clientes',
    faq: 'FAQ',
    follow_us: 'Siga a gente:',
    forDoctors: 'Para médicos',
    healthDataFields: 'Tipo de dados de saúde',
    help: 'Ajuda',
    history: 'Histórico',
    information: 'Informação',
    informations: 'Informações',
    insurances: 'Convênios',
    ios: 'App para iOS',
    image_main: 'Imagem Principal',
    isPreUser: 'Pré cadastro',
    jobs: 'Jobs',
    knowMore: 'Saiba Mais',
    medicament_upload: 'Lista de Medicamentos',
    message: 'Mensagem',
    new: 'novo',
    newPrescription: 'Nova Prescrição',
    prescription: 'Prescrição',
    next: 'Próximo',
    nothingToList: 'Lista vazia',
    notImplemented: 'Não implementado',
    of: 'de',
    orders: 'Pedidos',
    otherOptions: 'Diversos',
    pages: 'Páginas',
    paymentOptions: 'Pagamento',
    performance: 'Performance',
    prescriptions: 'Minhas prescrições',
    prescriptionsClients: 'Prescrições de clientes',
    press: 'Imprensa',
    products: 'Cardápio',
    productsCategories: 'Categorias',
    productsIngredients: 'Ingredientes',
    productsNags: 'Tags Produto',
    productsNutritional: 'Nutricional',
    productsSubCategories: 'Sub Categorias',
    productsTags: 'Tags de Produtos',
    product_nutritional: 'Nutritivo',
    product_tags: 'Tags Produto',
    profile: 'Perfil',
    promotions: 'Promoções',
    recent: 'Novidades recentes',
    region: 'Região',
    regions: 'Regiões',
    regionUtcOffset: 'UtcOffset',
    resources: 'Rescursos do app',
    company: 'Clínica',
    companies: 'Clínicas',
    reviews: 'Avaliações',
    schedule: 'Horários',
    search: 'Pesquisar',
    searchInList: 'Buscar na lista',
    navigation: 'navegação',
    settings: 'Configurações',
    settingsProducts: 'Configuração do Cardápio',
    specialities: 'Especialidades',
    status: 'Status do serviço',
    step: 'Etapa',
    subscriptions: 'Assinaturas',
    support: 'Suporte',
    tagAssociate: 'Tag associada',
    tags: 'Tags',
    units: 'Unidades de medida',
    users: 'Administradores',
    preusers: 'Pré Cadastros',
    vdTotal: 'VD total',
    clients_pending_doctor: 'Médicos Pendentes',
    clients_pending_profile: 'Pacientes Pendentes',
    sizeInfo: 'Tamanho dos arquivos',
  },
  admin: {
    success: {
      account_updated: 'Conta atualizada',
      password_updated: 'Senha alterada com sucesso',
      rejected_profile: 'Perfil Rejeitado',
    },
    errors: {
      item_loading: 'Erro ao carregar Item',
      specialty_loading: 'Erro ao carregar especialidades',
      government_document_required: 'Foto com documento é obrigatório',
      password_email_required: 'Email e senha são requeridos',
      authentication_account_creation: 'Algo deu errado durante o processo de criação',
      account_update: 'Erro ao atualizar conta',
      password_update: 'Error ao alterar senha',
      deactivate_account: 'Erro ao desativar conta de usuário',
      user_reference_lost: 'Referência de usuário perdida',
      address_not_found: 'Error ao buscar endereço',
      reject_profile: 'Error ao Rejeitar',
      approve_profile: 'Erro ao Aprovar Perfil',
      user_exists: 'Já existe um usuário aprovado com este ',
      user_exists_cpf: 'Já existe um usuário com este cpf',
      blank_name_last_name: 'Nome e sobrenome não podem ficar em branco',
      blank_name: 'Nome não pode ficar em branco',
      blank_cpf: 'CPF não pode ficar em branco',
      blank_phone_number: 'Telefone não pode ficar em branco',
      blank_crm_ufcrm: 'CRM e UF/CRM não podem ficar em branco',
      blank_insurances: 'Convênio não pode ficar em branco',
      blank_specialities: 'Especialidades não podem ficar em branco',
      blank_location: 'Informações sobre localização não podem ficar em branco',
      invalid_password: 'Senha inválida',
      blank_names: 'Nomes não podem ficar em branco',
      unprocessable_entity: 'Algo deu errado ao processar sua requisição',
      invalid_email: 'E-mail inválido',
      blank_role: 'Tipo de administrador não pode ficar em branco'
    },
    clients: {
      name: 'Nome',
      doctor: 'Médico',
      patient: 'Paciente',
      pre_user: 'Pré-usuário',
      status: {
        approved: 'Aprovado',
        rejected: 'Rejeitado',
        pending: 'Pendente',
        incomplete: 'Incompleto',
        unknow: 'Indefinido',
        preUser: 'Pré-usuário',
        notSend: 'Não enviado',
        canceled: 'Cancelado',
        active: 'Ativo',
        inactive: 'Inativo',
        none: 'Nenhum',
      },
    },
    telemedicine: {
      free_trial: 'Teste Grátis - Sauver Ilimitado',
    },
    pendingDoctors: {
      approve: 'Aprovar Médico',
      reject: 'Rejeitar Médico',
    },
    pendingPatients: {
      approve: 'Aprovar paciente',
      reject: 'Rejeitar paciente',
    },
    rejectForm: {
      title: 'Formulário de Recusa',
      sendReject: 'Enviar',
      customReason: 'Ou escreva o motivo',
    },
    rejectReasons: {
      imageFail: 'Imagem do documento não cumpre os requisitos;',
      imageRequired: 'Imagem de documento requerida;',
      imageCoherence: 'Imagem não condiz com o paciente;',
      patientDataFail: 'Dados do paciente não estão corretos;',
      patientDataRequired: 'Faltam alguns dos dados requeridos no cadastro;',
    },
    search: {
      findClient: 'Busque um cliente',
      searchClient: 'Pesquise por um cliente',
      clientActive: 'ativo',
      clientUnactive: 'inativo',
      add: 'adicionar',
      new: 'novo',
    },
    list: {
      status: {
        approved: 'Aprovado',
        rejected: 'Rejeitado',
        pending: 'Pendente',
        incomplete: 'Incompleto',
        unknow: 'Indefinido',
        preUser: 'Pré-usuário',
        notSend: 'Não enviado',
        canceled: 'Cancelado',
        active: 'Ativo',
        inactive: 'Inativo',
        none: 'Nenhum',
      },
      options: {
        quickOptions: 'ações rápidas',
        some: 'exemplo: desativar',
        hint: 'definir ações rápidas',
        filter: 'filtrar',
        options: 'opções',
      },
      filter: {
        approved: 'Aprovados',
        rejected: 'Rejeitados',
        pending: 'Pendentes',
        doctors: 'Médicos',
        patients: 'Pacientes',
        preUser: 'Pré usuário',
        itemsPerPage: 'items por página',
        clear: 'limpar',
      },
      type: {
        doctor: 'Médico',
        patient: 'Paciente',
        preuser: 'Pré-usuário',
      },
      error: {
        inconsistency: 'usuário inconsistente',
      },
    },
    actions: {
      toEditClient: 'Ir para edição de Cliente',
      forceApprovedState: 'Alterar Status',
      approve_patient: 'Aprovar paciente',
      approve_doctor: 'Aprovar médico',
    },
    store: {
      productName: 'Nome do Produto',
      labels: {
        creditsManagement: 'Gestão de Items',
        telemedicine: 'Telemedicina',
        transactions: 'Transações',
        itemDetails: 'detalhes deste item:',
        manualCreditation: 'Gestão Manual de Créditos',
        productManagement: 'Gestão de Produtos',
      },
      actions: {
        edit: 'Edição',
      },
    },
  },
  site: {
    home: {
      identifiers: {
        company: 'Sou clínica',
        doctor: 'Sou médico',
        patient: 'Sou paciente',
      },
    },
    companies: {
      register: {
        form: {
          title: 'Cadastre sua clínica',
          name: 'Nome',
          corporate_name: 'Razão Social',
          cnpj: 'CNPJ',
          email: 'E-mail',
          phone: 'Telefone',
          password: 'Senha',
          submit: 'Efetuar cadastro',
          errors: {
            blank_name: 'Nome não pode ficar em branco',
            blank_corporate_name: 'Razão Social não pode ficar em branco',
            blank_cnpj: 'CNPJ não pode ficar em branco',
            blank_email: 'E-mail não pode ficar em branco',
            blank_phone: 'Telefone não pode ficar em branco',
            blank_password: 'Senha não pode ficar em branco',
          },
        },
        messages: {
          register_success: 'Clínica cadastrada com sucesso',
          register_fail: 'Algo deu errado durante o processo de criação da clínica',
        },
      },
    },
  },
  schedule: {
    welcome: {
      title: "Agenda Sauver",
      label: "",
    },
    actions: {
      enable: "Habilitar",
      disable: "Desabilitar",
      config: "Configurar",
    },
    event: {
      title: 'Evento da Agenda',
      name: 'Nome do Evento',
      description: 'Descrição do evento',
      dateBegin: 'Data de início',
      dateEnd: 'Data de fim',
      timeBegin: 'Hora de início',
      timeEnd: 'Hora de fim',
      type: 'Tipo do Evento',
      participants: 'Participantes do evento',
      actions: {
        create: "Criar Evento",
        read: "Visualizar Evento",
        update: "Salvar Evento",
        delete: "Excluir Evento",
        block: "Bloquear",
        deleteLocker: 'Excluir Bloqueio',
      },
      time: {
        start: "Início",
        end: "Fim"
      },
      date: {
        start: "Inicia em",
        end: "Finaliza em",
      },
      status: {
        locked: 'bloqueado',
        serviceLocker: 'bloqueio de serviço',
      }
    },
    week: {
      "0": "Domingo",
      "1": "Segunda",
      "2": "Terça",
      "3": "Quarta",
      "4": "Quinta",
      "5": "Sexta",
      "6": "Sábado"
    },
    type: {
      week: "Semana",
      dayly: "Dia",
      month: "Mês",
    },
    to: {
      today: "Hoje",
    },
    errors: {
      onLoadSchedule: {
        title: "Error ao carregar dados da Agenda.",
        hint: "Prima o botão de recarregar. se o error persistir contate-nos.",
      },
      onReloadSchedule: {
        title: "Error ao recarregar dados da Agenda.",
        hint: "Prima o botão de recarregar. se o error persistir contate-nos.",
      },
      onDragUpdateEvent: {
        title: "Erro ao atualizar evento",
        hint: "",
      },
    },
    pickerMenu: "Calendário",
    dayWeek: "Dia da semana",
    enable: "Habilitar",
    disable: "Desabilitar",
    pattern: "Padrão",
    from: "Das",
    at: "às",
    andFrom: "das",
    split: "Dividir",
    continuous: "Contínuo",
    saveChanges: "Salvar Alterações",
    newEvent: "Novo evento",
    dateStart: "Data Início",
    dateEnd: "Data Fim",
    hourStart: "Início",
    hourEnd: "Fim",
    services: "Serviços",
    totalPrice: "Preço Total",
    saveEvent: "Salvar Evento",
    deleteEvent: "Apagar Evento",
    title: "Título",
    block: "Bloqueio",
    toBlock: "Bloquear",
  },
  company: {
    profile: {
      info: 'Informações da Empresa',
      contact: 'Contatos',
      insurances: 'Convênios',
      address: 'Endereço',
      email: 'e-mail',
      phone: 'telefone',
    },
    actions: {
      title: 'Ações da Clínica',
      associate: {
        title: 'Médicos da Clínica',
        succeeded: 'Médicos associados com sucesso.',
        failed: 'Falha na associação de Médicos.',
      },
      disassociate: {
        title: 'Médicos da Clínica',
        succeeded: 'Médicos associados com sucesso.',
        failed: 'Falha na associação de Médicos.',
      },
    },
    doctors: {
      associateSuccess: 'Médicos da Clínica',
      associateSuccessMsg: 'Médicos associados com sucesso.',
      associateErrorMsg: 'Falha na associação de Médicos.',
      searchInList: 'Pesquisar por médico',
      deleteDoctor: 'Excluir este Médico?',
      deleteDoctorHint:
        'para que este médico volte a esta lista, o adicione na aba ao lado.',
      tabTitles: {
        add: 'Adicionar novo',
        search: 'Pesquisar por Médico',
        associatedDoctors: 'Nossos médicos',
      },
    },
    users: {
      addUserTitle: 'Informações do novo usuário',
      fieldsRequired: 'Todos os campos são obrigatórios',
      rulesTitle: 'Permissões deste usuário',
      rulesHint: 'estas opções podem ser alteradas',
      optionsTitle: 'Demais opções',
      optionsHint: 'hint',
      passwordTitle: 'Informações de Acesso',
      password_hint: 'hint',
      associateSuccess: 'Usuários da Clínica',
      associateSuccessMsg: 'Atualizado',
      associateErrorMsg: 'falha na atualização',
      searchInList: 'Pesquisar por usuário',
      deleteUser: 'Excluir este usuário',
      deleteUserHint:
        'para que este médico volte a esta lista, o adicione na aba ao lado.',
      tabTitles: {
        add: 'Adicionar novo',
        search: 'Pesquisar',
        associatedUsers: 'Nossos usuários',
      },
    },
    nav: {
      company: 'Perfil da Clínica',
      users: 'Usuários',
      ownUsers: 'Nossos Usuários',
      clients: 'Clientes',
      ownClients: 'Nossos Clientes',
      doctors: 'Médicos',
      ownDoctors: 'Nossos Médicos',
      associatedDoctors: 'Médicos associados',
      preusers: 'Pré Cadastros',
    },
    general: {
      preuserEdit: 'Edição pré cadastro',
    },
  },
  profile: {
    commom_profile: {
      messages: {
        form_incomplete: 'Complete os campos do formulário',
        form_complete: 'Formulário concluído',
      }
    },
    professional_profile: {
      status_title: {
        approved: 'Seja bem vindo ao Sauver Dr.',
        rejected: 'Algum dos dados estão incorretos.',
        pending: 'Pedido enviado e aguardando análise.',
        incomplete: 'Ao completar o formulário, uma requisição será enviada.',
      },
      errors: {
        form_incomplete: 'Este formulário deve estar completo',
        find_address: 'Erro ao buscar endereço',
      }
    },
    myTabs: {
      accountDetails: 'Detalhes da Conta',
      professionalProfile: 'Perfil Profissional',
      subscriptionsService: 'Minha Assinatura',
      telemedicineService: 'Loja Telemedicina',
      paymentAccount: 'Carteira e Transações',
      myNotifications: 'Notificações',
      accountSecurity: 'Conta e Segurança',
    },
    title: {
      client: 'Informações',
      clientInfo: 'Informações do Usuário',
      clientProfileImage: 'Foto de Perfil',
      clientPassword: 'Senha do usuário',
      professionalInfo: 'Informações Profissionais',
      patientInfo: 'Verificação do Paciente',
      admin: 'Informações',
      adminInfo: 'Informações do Administrador',
      company: 'Informações da Clínica',
      user: 'Informações do usuário',
    },
    doctorRequest: {
      title: 'Estado da solicitação ',
      status: 'status',
      request: 'Solicitar',
      requested: 'Solicitado',
      resend: 'solicitar novamente',
      sendRequest: 'Enviar solicitação',
      hint: 'Ainda não solicitado, preencha as suas informações para submeter o seu cadastro para análise.',
      rejectText: 'Motivo de não aprovar: ',
      approved: 'Aprovado',
      rejected: 'Rejeitado',
      pending: 'Pendente',
      hintPending: 'Sua solicitação foi enviada, por gentileza aguarde resposta.',
      notSend: 'Não enviado',
      request_text: 'Requisição Médica será enviada',
    },
    profileRequest: {
      crm_uf: 'CRM/UF:',
      provider: 'Provedor:',
      status_request: 'Status da Requisição Profissional:',
      title: 'Estado da solicitação ',
      status: 'status',
      request: 'Solicitar',
      requested: 'Solicitado',
      resend: 'solicitar novamente',
      sendRequest: 'Enviar solicitação',
      hint: 'Adicione uma foto que mostre o seu rosto e um documento',
      rejectText: 'Motivo de não aprovar: ',
      rejectedHint: 'Você pode requisitar novamente no aplicativo Sauver.',
      approved: 'Aprovado',
      rejected: 'Rejeitado',
      pending: 'Pendente',
      hintPending: 'Sua solicitação foi enviada, por gentileza aguarde resposta.',
      notSend: 'Não enviado',
    },
    choosePlan: 'Planos Sauver para Você',
    planDetails: 'Detalhes do seu Plano Sauver',
    upgradeMyPlan: 'Seja Premium',
    cancelMyPlan: 'Cancelar o plano',
    downgradeMyPlan: 'Seja Basic',
    currentPlanHint: 'Atualmente você é um usuário: ',
    forward: 'Prosseguir',
    toCommmomForm: 'Voltar aos Detalhes da Conta',
    toProfessionalForm: 'Seguir para Perfil Profissional',
    form: 'Formulário',
    save: 'Salvar',
    update: 'Atualizar',
    edit: 'Editar',
    deleteAccount: 'Excluir conta',
    cancelAccount: 'Cancelar conta',
    setupAccount: 'Configurar conta',
    activateAccount: 'Ativar conta',
    deactivateAccount: 'Desativar conta',
    editAccount: 'Editar conta',
    pending: {
      approvedRequired: 'Aguarde aprovação do seu cadastro médico.',
      subscriptionRequired: 'Disponível nos planos de assinatura Sauver.',
    },
    accountDangerous: {
      title: 'Danger Zone',
      cancelLabel: 'Cancelar conta',
      cancelHint: 'Ao cancelar conta, a mesma não retornará a aparecer nas listagens.',
      deleteLabel: 'Excluir esta Conta',
      deleteHint:
        'Ao deletar esta conta, seus dados pessoais, exames, prescrições e demais registros serão apagados do nosso Sistema',
      activateLabel: 'Reativar esta Conta',
      activateHint:
        'Ativar conta de usuário, torna a conta disponível para buscas, notificações e emails dentro do Sistema.',
      deactivateLabel: 'Desativar esta Conta',
      deactivateHint:
        'Desativar conta de usuário, torna a conta indisponível para buscas, notificações e emails dentro do Sistema.',
    },
    soluti: {
      title: 'Soluti - Certificação Digital',
      add: 'adicionar',
      addLabel: 'Inserir novo',
      slotHint: 'Selecione o CRM/UF a ser usado na sua assinatura digital',
    },
    subscription: {
      hint: 'Aqui estarão listadas as ações pertinentes a este item',
      label: 'Gestão de item Sauver',
    },
    cancel: 'Cancelar',
    account: 'Conta: ',
    healthInsurance: 'Convênio',
    specialty: 'Especialidade',
    youAreSure: 'Rejeitar?',
    youAreWantCancelOrder: '',
    youAreYes: 'Sim',
    youAreNo: 'Não',
    cancelReason: 'Motivo da rejeição',
    errorIsDoctor:
      'É necessário preencher os seus dados como médico ou desmacar a flag Sou Médico',
    doctorRequestAdmin: 'Verificação Profissional (Médico)',
  },
  recover_password: {
    title: 'Recuperar senha',
    subtitle: 'Enviaremos um e-mail com informações para sua recuperação',
    field: {
      email: 'E-mail',
    },
    actions: {
      send: 'Enviar',
      comeback_to_login: 'Retornar ao login',
    },
    messages: {
      error: {
        unprocessable_email: 'Algo deu errado ao enviar o e-mail',
      },
      success: 'E-mail enviado',
    },
  },
  login: {
    title: 'Entrar',
    label: 'Login médico',
    welcome: 'Bem vindo novamente Doutor!',
    email: 'E-mail',
    reset: {
      password_hint: 'Dica: Não use seu nome ou datas que facilitem outras pessoas descobrirem sua senha.',
      title: 'Enviar',
      mailHint: 'Enviaremos um email para a recuperação',
      admin: 'Resetar Admin',
    },
    password: {
      title: 'Senha',
      change: 'Alterar senha',
      reset: 'Resetar senha',
      typeNew: 'Digite a nova senha',
    },
    access: {
      admin: 'Acessar Admin',
      company: 'Acessar sua Clínica',
      client: 'Acessar sua Conta',
    },
    to: {
      login: 'Retornar ao login',
      reset: 'Esqueci minha senha',
      register: 'Não tem uma conta? Cadatre-se',
      getApp: 'Baixe nosso app e cadastre-se!',
    },
    providers: {
      title: 'Métodos de autenticação',
      facebook: 'Entrar com Facebook',
      google: 'Entrar com Google',
      phone: 'Entrar com Telefone',
    },
    providers: 'Métodos de autenticação',
    apple: 'Entrar com Apple',
    google: 'Entrar com Google',
    facebook: 'Entrar com Facebook',
    phone: 'Entrar com Telefone',
    addApple: 'Adicionar Apple',
    addGoogle: 'Adicionar Google',
    addFacebook: 'Adicionar Facebook',
    getPhoneNumber: 'Insira seu número de telefone',
    getPhoneCode: 'Insira seu código de verificação enviado ao seu celular',
    resetClient: 'Resetar senha',
    registerClient: 'Cadastro Médico',
    register_company: 'Registrar Clínica',
    goToContact: 'Ir para contato',
    register: 'Efetuar cadastro',
    googleRegister: 'Registrar com Google',
    facebookRegister: 'Registrar com Facebook',
    emailRegister: 'Registrar com email',
    appleRegister: 'Registrar com Apple',
    phoneRegister: 'Cadastro com Telefone',
    resetPassword: 'Resetar senha',
    accessOng: 'Acessar Ong',
    makeResetPassword: 'Definir nova senha',
    providerCode: {
      'auth/invalid-email': 'Email inválido',
      'auth/user-disabled': 'Conta desabilitada',
      'auth/user-not-found': 'Usuário não encontrado',
      'auth/wrong-password': 'Senha incorreta',
      'auth/credential-already-in-use': 'Credencial já utilizada',
      'auth/too-many-requests': 'Muitas tentativas, tente novamente mais tarde',
      'auth/operation-not-allowed':
        'Tipo de login não configurado, tente outra forma de login',
    },
    error: {
      generic: 'Houve algum erro ao efetuar login',
      phoneEmpty: 'Número de telefone vazio',
      phoneCodeEmpty: 'Código de verificação vazio',
      loginTitle: 'Erro Ao efetuar login',
      providerTitle: 'Erro ao adicionar provider',
      companyNotFound: 'Nenhuma empresa associada a esse email',
    },
  },
  register: {
    title: 'Cadastre sua clínica!',
    ifAccountExist: 'Se já possui uma conta, faça login aqui embaixo',
    status: {
      unavailable: 'Indisponível nesta versão.',
    },
    to: {
      contacts: 'Por favor, entre em contato e solicite o seu cadastro.',
    },
  },
  dashboard: {
    title: 'Bem vindo ao Dashboard Sauver',
    examsOrders: 'Pedidos recentemente atualizados: ',
    newOrders: 'Meus Novos Pedidos: ',
  },
  blog: {
    title: '',
    status: {
      notFound: 'Não encontrado',
    },
    blog_categories: {
      name_not_found: 'Sem Nome',
      active: 'Ativo',
      inactive: 'Inativo',
    },
  },
  image: {
    crop: 'Crop',
    zoomIn: 'Zoom In',
    zoomOut: 'Zoom Out',
    rotateLeft: 'Girar Esquerda',
    rotateRight: 'Girar Direita',
    mirrorY: 'Espelhar Y',
    mirrorX: 'Espelhar X',
  },
  form: {
    client: {
      profilePhoto: 'Foto do Perfil Profissional',
      professionalInformation: 'Informações Profissionais',
      documentIdentification: 'Foto com documento para validação de usuário',
      signatureInformation: 'Certificação Digital',
      name: 'Nome',
      lastName: 'Sobrenome',
      cpf: 'CPF',
      email: 'Email',
      phone: 'Telefone',
      birthDate: 'Data de Nascimento',
      gender: 'Sexo',
    },
    error: {
      required_update_password: 'Campos Senha e Nova Senha são obrigatórios',
      permissionDeniedByPatient: 'Paciente não permitiu interação',
      required: 'Campo obrigatório',
      minLength: 'O campo deve conter no mínimo 6 caracteres',
      maxLength: 'O campo é muito longo',
      update: 'Houve algum erro',
      warn: 'alerta',
      email: 'Email inválido',
      email_blank: 'Email não pode ficar em branco',
      cpf: 'CPF inserido inválido',
      cpfExist: 'O CPF já foi cadastrado',
      cpfPreMatch: 'CPF confere a um pré-usuário cadastrado',
      cpfPreMatchHint: 'CPF já utilizado, tente buscar por este CPF.',
      name: 'o nome inserido deve ter no mínimo 3 digitos',
      image: {
        type: 'Tipo de arquivo inválido, esperado: PNG, JPEG',
        upload: 'Erro ao fazer ulpload da imagem, tente novamente mais tarde!',
      },
      pdf: {
        type: 'Tipo de arquivo inválido, esperado: PDF',
        upload: 'Erro ao fazer ulpload do PDF, tente novamente mais tarde!',
      },
      apply: 'Aplicar',
      formInvalid: 'Campo inválido: ',
      nameMissing: 'Nome não pode ficar em branco',
      formUserRequired: 'Selecione um paciente',
      dosageRequired: 'Todos os items requerem dosagem',
      itemsRequired: 'Deve haver ao menos um item',
      dateRequired: 'Data requerida',
      uploadImage: 'Erro ao fazer upload de imagem',
      closeEditor: 'Conclua ou cancele o editor antes de proseguir',
      showOnMenu: 'Mostrar no menu',
      regionAreaName: 'Erro ao obter nome da área da região',
      schedule: 'Erro a reduzir número de escalas',
      tagType: 'A tag deve ser aplicável a produtos e ou empresas',
      onUpdate: 'Espere a conclusão da operação anterior',
      selectDate: 'Selecione a data',
      selectPdf: 'Selecione um Pdf',
      selectImage: 'Selecione uma Imagem',
      selectPdfImage: 'Selecione um arquivo',
      selectType: 'Houve um erro com seus arquivos',
      password: 'A senha deve conter pelo menos uma letra maiúscula, minúscula, um número e um caracter especial',
    },
    input: {
      freeInsertion: 'Inserção Livre',
      searchInsertion: 'Pesquisar Item',
    },
    question: 'Pergunta',
    answer: 'Resposta',
    requiredField: 'Campo Obrigatório',
    sheetOptions: 'Opções da Folha',
    confirmDelete: 'Excluir',
    cancelDelete: 'cancelar',
    proceed: 'prosseguir',
    unactive: 'inativo',
    save: 'salvar',
    undo: 'Desfazer',
    add: 'Adicionar',
    apply: 'Aplicar',
    applyTemplate: "Aplicar Modelo",
    typeHere: 'Digite aqui...',
    messageHere: 'Digite sua mensagem aqui...',
    visibility: 'Visibilidade',
    cancelEdit: 'Cancelar Edição',
    editAll: 'Editar tudo',
    headerEdit: 'Editor de Cabeçalho de Impressão',
    enableEdit: 'Habilitar edicão',
    permissionHint: 'Selecione até 3 médicos',
    saveTemplate: 'Salvar Modelo',
    editTemplate: 'Editar Modelo',
    update: 'Atualizar',
    refAExamOrder: 'Selecione o Pedido de Exame',
    companyName: 'Nome da clínica',
    corporateName: 'Razão social',
    updateSuccess: 'Dados salvos',
    ownerName: 'Nome do proprietário',
    email: 'Email',
    password: 'Senha',
    continue: 'Continuar',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    requestName: 'Nome do Pedido',
    userName: 'Nome do Paciente',
    name: 'Nome',
    lastName: 'Sobrenome',
    prevStep: 'Etapa Anterior',
    nextStep: 'Próxima etapa',
    conclude: 'Concluir',
    close: 'Fechar',
    cancel: 'Cancelar',
    back: 'Voltar',
    changePrimaryColor: 'Alterar cor primária',
    changeSecondaryColor: 'Alterar cor secundária',
    emailResetSuccess:
      'Em instantes você receberá um email para redefinir a sua senha',
    emailResetError:
      'Houve um erro ao tentar enviar email de redefinição de senha',
    changeEmail: 'Alterar Email',
    changePassword: 'Alterar Senha',
    passwordNew: 'Nova Senha',
    passwordOld: 'Senha atual',
    info: 'Informação do clínica',
    address: {
      city: 'Cidade',
      complement: 'Complemento',
      country: 'Pais',
      district: 'Bairro',
      number: 'Número',
      search: 'Buscar',
      state: 'Estado',
      street: 'Rua',
    },
    contact: 'Contato',
    description: 'Descrição',
    phone: 'Telefone',
    delete: 'Deletar',
    edit: 'Editar',
    permission: 'Permissão',
    active: 'Ativo',
    create: 'Criar',
    isLiquid: 'É líquido',
    isMandatory: 'Obrigatório',
    numberOfChoices: 'Máximo de escolhas (0 sem limite)',
    print: 'Imprimir',
    showIds: 'Mostrar Ids',
    visible: 'Visísvel',
    required: 'Obrigatório',
    nutritionalUnit: 'Unidade de medida',
    showOnMenu: 'Mostrar no menu',
    isCash: 'É dinheiro?',
    getDeliveryAreaName: 'Nome da área de entrega',
    getRegionName: 'Nome da área da região',
    isProduct: 'Aplicável em produtos',
    isCompany: 'Aplicável em empresas',
    isDoctor: 'Sou Médico',
    CRM: 'CRM',
    UFCRM: 'UF deste CRM',
    sendContactMessage: 'Enviar',
    placeholderInsurances: 'Convênios Aceitos',
    specialty: 'Especialidade Médica',
    companySpecialty: 'Especialidade da Clínica',
    infoDoctor: 'Informações do Médico',
    addressDoctor: 'Endereço de atendimento',
    category: 'Categoria',
    title: 'Título',
    slug: 'URL',
    abstract: 'Resumo',
    bodyText: 'Conteúdo',
    emptyResult: 'Nenhum resultado',
    lang: {
      label: 'Idiomas',
      ptbr: 'Br',
      en: 'EN',
    },
    isBookmarkable: 'Pode ser adicionado aos favoritos',
    confirmQuestion: 'Você tem certeza que deseja efetuar essa ação?',
    confirmDeleteFile: 'Você tem certeza que deseja Remover esse arquivo?',
    confirmQuestionYes: 'Sim',
    confirmQuestionCancel: 'Cancelar',
    search: {
      emptyResult: 'Nenhum resultado encontrado',
    },
    placeholderSpecialities: 'Selecione as especialidades',
    approve: 'Aprovar',
    reject: 'Rejeitar',
    doctors: 'Médicos',
    remove: 'Excluir',
  },
  list: {
    title: 'Listagem',
    visibility: {
      title: 'Visibilidade',
      hint: 'marcar esta opção permite que o paciente visualize este exame.',
    },
    options: {
      title: 'Opções',
    },
    actions: {
      activate: 'Ativar item',
      deactivate: 'Inativar item',
      confirmDeactivate: 'Deseja desabilitar este item?',
    },
  },
  listItems: {
    status: {
      active: 'Ativo',
      inactive: 'Inativo',
      unknown: 'desconhecido',
      uncategorized: 'Item sem categoria',
    }
  },
  searchTabs: {
    tabTitles: {
      add: 'Adicionar novo',
      search: 'Pesquisar',
      associatedDoctors: 'Nossos médicos',
      findClient: 'Buscar por Paciente',
      registerPreuser: 'Cadastro Pré-usuário',
    },
    searchNewDoctor: 'Procurar por um novo médico...',
    searchNewUser: 'Procurar por um novo usuário...',
    searchDoctor: 'Procurar nestes médicos...',
  },
  multiSelect: {
    selectLabel: 'Pressione enter para selecionar',
    selectGroupLabel: 'Pressione enter para selecionar o grupo',
    selectedLabel: 'Selecionado',
    deselectLabel: 'Pressione enter para remover',
    deselectGroupLabel: 'Pressione enter para cancelar a seleção do grupo',
    placeholder: 'Selecione a opção',
    selectThis: 'selecionar',
    deselectThis: 'remover',
  },
  orderStatus: {
    canceled: 'Pedido Cancelado',
    confirmed: 'Pedido Confirmado',
    deliveryReady: 'Entrega Concluída',
    takeoutReady: 'Pedido Concluído',
    inPreparation: 'Em Preparação',
    makingOrder: 'Montando Pedido',
    waitDelivery: 'Aguardando Entrega',
    onDelivery: 'Em Entrega',
    waitConfirm: 'Aguardando Confirmação',
    waitPayment: 'Aguardando Pagamento',
    waitTakeout: 'Aguardando Retirada',
  },
  orderType: {
    takeout: 'Retirada',
    undefined: 'Não definido',
  },
  notifications: {
    companyAssociation: {
      title: 'Associação a Clínica',
      label: 'Uma Clínica',
      text: 'acaba de associa-lo a sua lista de médicos',
      category: 'COMPANY_INSERTION',
      failed: 'Falha ao notificar Médico.',
    },
    hasChangedRegistration: {
      title: 'Mudança no Cadastro',
      body: 'Olá, houve alguma mudança no seu cadastro.',
    },
    patientApproved: {
      title: 'Cadastro Aprovado',
      body: 'Seja bem vindo. Seu cadastro de Paciente foi Aprovado.',
    },
    patientRejected: {
      title: 'Cadastro Rejeitado',
      body: 'Seu cadastro de Paciente foi Rejeitado. Por gentileza, revise seu Cadastro.',
    },
    patientnotViewedExams: {
      title: 'Exames disponíveis',
      body: 'exames disponíveis para visualização.',
    },
    doctorApproved: {
      title: 'Cadastro Médico Aprovado',
      body: 'Seja bem vindo Doutor(a). Seu cadastro profissional foi Aprovado.',
    },
    doctorRejected: {
      title: 'Cadastro Médico Rejeitado',
      body: 'Seu cadastro de Médico foi Rejeitado. Por gentileza, revise seu Cadastro.',
    },
    examTitle: 'O seu exame está disponível',
    chatCall: {
      title: 'Recebendo uma chamada',
      body: 'está te convidando para uma telemedicina',
    },
    contactRequest: {
      title: 'Nova solicitação de permissão de contato',
      body: 'está solicitando permissão para contatar você',
    },
    contactRequestAnswer: {
      titlePositive: 'Sua solicitação de contato foi aceita',
      bodyPositive:
        'aceitou sua solicitação, agora você pode entrar em contato com o paciente pelo Sauver!',
      titleNegative: 'Sua solicitação de contato foi recusada',
      bodyNegative: 'recusou sua solicitação para contatá-lo!',
    },
  },
  rules: {
    adm: {
      adm: 'Administrador',
      sell: 'Vendedor',
    },
    comp: {
      adm: 'Administrador',
      edit: 'Editor',
      mEdit: 'Master Editor',
      deli: 'Delivery',
      att: 'Atendente',
    },
  },
  router: {
    accessDenied: 'Acesso Negado',
    routesNamed: {
      dashboard: 'Dashboard',
      profile: 'Meu Perfil Médico',
      examsClients: 'Exames de Clientes',
      prescriptionsClients: 'Prescrições de Clientes',
      examsRequests: 'Meus Pedidos de Exames',
      examsTemplates: 'Meus Modelos de Exames',
      telemedicine: 'Telemedicina',
      pharmacy: 'Farmácia',
      store: 'Loja Sauver',
      schedule: 'Agenda',
      companyAllUsers: 'Todos Usuários',
      companyProfile: 'Perfil da Clínica',
      companyExamsClients: 'Exames de Pacientes',
      companyUsers: 'Nossos Usuários',
      companyDoctors: 'Nossos Médicos',
      companyPreusers: 'Pré Cadastros',
      welcome: 'Bem Vindo',
      nullRouter: '...',
      adminUsers: 'Administradores',
      adminClients: 'Nossos Clientes',
      adminCompanies: 'Nossas Clínicas',
      productsManagement: 'Gestão de Produtos',
      adminTelemedicine: 'Gestão Telemedicina',
      adminPendingDoctors: 'Médicos Pendentes',
      adminPendingClients: 'Clientes Pendentes',
      medicamentUpload: 'Upload de Medicamentos',
      tussUploadUpload: 'Upload Tabela Tuss',
      adminBlog: 'Gestão do Blog Sauver',
      adminBlogCategories: 'Gestão de Categorias do Blog',
      adminFaq: 'Gestão do Faq',
      adminPages: 'Gestão de Páginas',
      adminInsurances: 'Convênios',
      adminExamTemplates: 'Templates de Exames',
      adminExamCategories: 'Categorias de Exames',
      adminHealtDataFields: 'Tipos de dados de Saúde',
      adminUnits: 'Unidades de Medida',
      adminSpecialties: 'Especialidades',
    },
  },
  exam: {
    view: {
      examDoctor: 'Médico solicitante',
      gallery: 'Imagens',
      galleryP: 'Arquivos',
      unevaluated: 'não avaliado',
      evaluated: 'avaliado',
    },
    doctorPermissions: 'Permissões',
    permission: {
      label: 'Permissões',
      hint: 'especifique médicos que podem visualizar esse exame.',
      hint2: 'esses médicos também podem visualizar esse exame',
    },
    pageLimitReached:
      '** limite de items na pagina atingido, por favor adicione outra página.',
    currentItemsTo: 'estes são os items encontrados para',
    examDate: 'Data do exame: ',
    hintTable: 'preencha todos os campos *',
    pickDate: 'selecione a data',
    selectFileType: 'Selecione o tipo de Arquivo: ',
    pickADate: 'data do exame',
    label: 'Exame',
    name: 'Nome para este exame',
    requestName: 'Nome do Pedido',
    clinicalIndication: 'Indicação Clínica',
    template: 'Modelo',
    newExam: 'novo exame',
    viewAll: 'visualizar todos',
    newExamUpload: 'Novo upload de Exames',
    orderItemsList: 'Exames/Procedimentos de um pedido: ',
    evaluated: 'Exame avaliado: ',
    edit: 'editar',
    exam_patient: 'Paciente: ',
    selectFiles: 'Selecione seu arquivo: ',
    selectImages: 'Selecione suas Imagens: ',
    selectedFiles: 'Seus arquivos: ',
    filesAttached: 'Imagens anexadas: ',
    select_template: 'Escolher Modelo',
    select_orderRef: 'Selecionar um pedido',
    select_orderRef_items:
      'Selecione os Exames/Procedimentos deste pedido, que você deseja carregar',
    orderItemsTagged: 'Exames/Procedimentos marcados',
    items_sent: 'Exames/Procedimentos de um pedido incluídos neste exame: ',
    edit_order_items: 'editar exames/procedimentos',
    exam_upload: 'adicionar exame',
    examSection: 'Exames/Procedimentos',
    examPage: 'Página',
    exam_template: 'Modelo de Exame',
    exam_order: 'Pedido de Exames',
    saveOrder: 'Salvar Pedido',
    generatePdf: 'Gerar PDF',
    exam_userRef: 'Solicito a:',
    exam_date: 'Data: ',
    patientName: 'Paciente: ',
    clinical_indication: 'Indicação Clínica: ',
    apply_template: 'Adicionar Modelo',
    addItem: 'Adicionar item',
    selectItem: 'Selecionar exame',
    typeLst: {
      text: 'Texto',
      select: 'Seleção',
      checkbox: 'Opções',
      number: 'Número',
    },
    type: 'Tipo de valor',
    referenceType: 'Tipo de valor de referência',
    referenceText: 'Texto de referência',
    referenceMin: 'Acima de ',
    referenceMax: 'Abaixo de',
    options: 'Opções',
    addOption: 'Adicionar opção',
    optionName: 'Nome da opção',
    unit: 'Unidade de medida',
    type2: 'Tipo de valor(2)',
    referenceType2: 'Tipo de valor de referência(2)',
    referenceText2: 'Texto de referência(2)',
    referenceMin2: 'Acima de (2)',
    referenceMax2: 'Abaixo de (2)',
    options2: 'Opções(2)',
    addOption2: 'Adicionar opção(2)',
    optionName2: 'Nome da opção(2)',
    unit2: 'Unidade de medida(2)',
    unitLst: {
      none: 'Sem unidade',
      ml: 'ml',
      milhoesmm3: 'milhões/mm³',
      percent: '%',
      gDL: 'g/dL',
      fL: 'fL',
    },
    addSection: 'Adicionar seção',
    addPage: 'Adicionar Página',
    nameOfSection: 'Nome da seção',
    section: 'Seção',
    has2values: 'Tem valor secundário?',
    isEvaluated: 'Já foi analisado?',
    isReferenced:
      'Este paciente possui pedidos de exame. Deseja marcar um pedido?',
    orderEdit: 'Deseja editar estes items?',
    editOrderItems: 'Items atuais: ',
    editOrderAddItems: 'Adicionar mais items: ',
    editOrderCompleted: 'Este pedido não possui mais items disponíveis.',
    nullOrderItems: 'Este exame não contém items de pedido..',
    addHealthDataFields: 'Adicionar tipo de dado de saúde',
    addHealthItem: 'Adicionar item',
    selectDoctors: 'Selecione os médicos',
    selectPatient: 'Selecione o paciente',
    selectDoctor: 'Selecione o médico solicitante',
    date: 'Data do exame',
    fileType: {
      select: 'Selecione o tipo de arquivo',
      pdf: 'PDF',
      image: 'Imagem',
      pdfImage: 'PDF e Imagem',
    },
    filePdf: 'Adicione PDF',
    editPdf: 'Editar PDF',
    fileImage: 'Adicione imagens',
    selectCompany: 'Clínica',
    notVisible: 'A visualização desse exame não está disponível',
    patient: 'Paciente',
    evaluatedYes: 'Exame desmembrado',
    evaluatedNot: 'Exame não desmembrado',
    company: 'Clínica',
    notification: {
      newPermission: 'Nova permissão a exame',
      newExamOrderRef: 'Exames de um pedido foram carregados. ',
      newExamAllowedRef: 'Uma nova permissão lhe foi concedida a um exame de',
      newExamAllowedByC: 'lhe concedeu permissão a um exame de',
      patient: 'Paciente: ',
      newExamToDoctor: 'tem um novo exame para você.',
      examNotification: 'Notificação de Exames',
    },
  },
  prescription: {
    insertOptions: {
      title: 'Opções de Inserção',
      findOption: 'Pesquisar',
      simpleEditorOption: 'Editor Simples',
      expandedEditorOption: 'Editor Expandido',
    },
    customMedicament: {
      title: 'nome do medicamento',
      text: 'descrição',
      dosage: 'quantidade',
      posology: 'posologia',
    },
    searchMedicament: 'Buscar Medicamento..',
    insertMedicament: 'Inserir medicamento..',
    insertDosage: 'Inserir quantidade..',
    insertPosology: 'Inserir posologia..',
    dosage: 'Quantidade',
    posology: 'Posologia',
    selectPatient: 'Selecione o paciente',
    pdf: {
      medicament: 'Medicamento: ',
      dosage: 'Posologia: ',
      CRM: 'CRM: ',
    },
    patient: 'Paciente',
    doctor: 'Médico',
  },
  chat: {
    headerLabel: 'mensagens',
    newMessages: 'novas mensagens',
    newChat: 'Nova conversa',
    writeSomething: 'Diga algo',
    dateFormat: 'DD MMMM YYYY',
    hourFormat: 'HH:mm',
    notification: {
      title: 'Nova mensagem recebida',
      body: '',
    },
  },
  store: {
    accountPrevent: {
      title: 'Bem vindo a Loja Sauver',
      subtitle:
        'Aqui estarão disponíveis items de assinatura e créditos telemedicina',
      warn: 'Os items da Loja estarão disponíveis para usuários com conta de pagamento.',
      callAction: 'Crie sua conta accesando: Perfil ---> Carteira e Transações',
    },
    add: 'adicionar',
    availableItems: 'Items Disponíveis',
    telemedicineItems: 'Items Telemedicina',
    title: 'Loja Sauver',
    label: 'loja',
    buyLabel: 'Compra de',
    welcome: 'Bem Vindo a Telemedicina',
    processing: 'processando...',
    currencyLabel: 'Moeda',
    costModeLabel: 'Tipo de compra',
    currCreditsBalance: 'Saldo atual',
    creating: {
      start: 'Aguarde enquanto criamos sua conta...',
      end: 'Conta criada',
      error: 'Houve um erro ao criar sua conta',
    },
    telemedicine: {
      item: {
        name: {
          br: 'Nome do Item (PT-BR)',
          us: 'Nome do Item (EN-US)',
        },
        activeHint:
          'Esta propriedade muda a visibilidade deste item para o usuário.',
        currency: {
          label: 'Moeda',
        },
        priceLabel: 'Valor deste item:',
        creditLabel: 'Quantidade de Créditos:',
        discountLabel: 'Desconto',
      },
      actions: {
        newItem: 'novo',
      },
      credits: {
        label: 'créditos',
        caseLabel: 'Créditos',
        currCreditsBalance: 'Saldo atual',
      },
      costMode: {
        unity: 'unitário',
        plan: 'plano',
        package: 'pacote',
      },
      unity: {
        label: 'créditos',
        unityValue: 'unidade',
        caseLabel: 'Créditos Telemedicina',
      },
      package: {
        label: 'Pacote',
        caseLabel: 'Pacote Telemedicina',
      },
      plan: {
        label: 'plano',
        caseLabel: 'Plano Telemedicina',
      },
    },
    cart: {
      empty: 'Carrinho vazio',
      paymentMethod: 'Forma de pagamento: ',
      changeCard: 'mudar cartão',
      cancel: 'cancelar',
      insertPaymentMethod: 'Informações de pagamento.',
      subtotal: 'subtotal',
      pay: 'pagar',
      buy: 'comprar',
      conclude: 'concluir',
      paymentHint:
        'as formas de pagamento abaixo, podem ser editadas apenas na sua carteira.',
    },
    wallet: {
      label: 'Carteira',
      myCards: 'Meus Cartões',
      emptyCards: 'Nenhum cartão adicionado',
    },
    recurring: {
      intervals: {
        month: 'Mensalmente',
        dayly: 'Diariamente',
        year: 'Anualmente',
      },
    },
  },
  payment: {
    title: 'Conta de Pagamento',
    amount: 'valor',
    balance: 'Balanço',
    wallet: {
      title: 'Carteira e Transações',
      empty: 'Carteira Vazia',
    },
    pre_infos: {
      title: 'Ao criar sua conta de pagamento, estarão disponíveis:',
      click_create_account: 'Clique abaixo para criar sua conta',
      infos: {
        store_subscription: '1 - Loja/Assinatura --> Planos de assinatura Sauver.',
        store_telemedicine: '2 - Loja/Telemedicina --> Crédito Unitário e/ou pacotes de créditos.',
        profile_wallet: '3 - Perfil/Carteira e Transações --> Seus Cartões e transações.',
      },
    },
    currency: {
      en: '$',
      br: 'r$',
    },
    types: {
      sauver: 'Conta de pagamento Sauver',
      stripe: 'Conta de pagamento Stripe',
    },
    status: {
      succeeded: 'Pagamento Realizado',
      failed: 'Falha no Pagamento',
      paid: 'Pago',
    },
    actions: {
      create: 'Criar conta',
      update: 'Atualizar Conta',
      processing: 'processando pagamento',
      pay: 'Pagar',
    },
    customer: {
      name: 'Nome',
      surname: 'Sobrenome',
      email: 'Email',
    },
    account: {
      status: {
        active: 'ativo',
        inactive: 'inativo',
        available: 'Disponível',
        unavailable: 'Indisponível',
      },
      messages: {
        errors: {
          email_required: 'Necessário Email',
        },
        success: {
          register_gateway: 'Cliente Registrado no Gateway',
          create_payment_account: 'Conta de Pagamento criada com Sucesso',
        },
      },
    },
    address: {
      address: 'Endereço',
      country: 'Pais',
      zipcode: 'Código Postal',
      city: 'Cidade',
      state: 'Estado',
    },
    paymentOptions: {
      bankCheck: 'Pagamento em Cheque',
      creditCard: 'Pagamento em Cartão de Crédito',
      debitCard: 'Pagamento em Cartão de Débito',
      money: 'Pagamento em Dinheiro',
      paymentOptionsSub: 'Opções de pagamento',
      acceptPaymentOnApp: 'Aceita pagamento no App',
      acceptCashPayment: 'Aceita dinheiro',
      acceptPaymentOnDelivery: 'Aceita pagamento na entrega',
      placeholderCards: 'Adicione os cartões aceitos',
    },
    card: {
      cardInformation: 'Informações do Cartão',
      card_details: 'Detalhes do Cartão',
      hint: 'insira as informações do cartão.',
      no_transactions: 'Sem Transações',
      fields: {
        flag: 'Bandeira',
        registration_date: 'Data de Cadastro',
        month_expiration: 'Mês de Expiração',
        year_expiration: 'Ano de Expiração'
      },
      actions: {
        main: 'Tornar Principal?',
        create: 'Adicionar Cartão',
        save: 'Salvar Cartão',
        saveQuestion: 'Salvar este cartão na sua Carteira?',
        edit: 'Editar Cartão',
        delete: 'Excluir Cartão',
        setDefault: 'Tornar este seu cartão principal?',
        message: {
          card_default: 'Marcando este cartão como principal',
          card_update_success: 'Cartão alterado com sucesso',
          card_remove: 'Removendo este cartão',
          card_remove_success: 'Cartão removido com sucesso'
        }
      },
    },
    to: {
      wallet: 'Ir para Carteira e Transações',
    },
    transactions: {
      title: 'Transações',
      label: 'Minhas Transações',
      recents: 'Transações Recentes',
    },
    errors: {
      nullFields: 'Campos vazios no Cartão',
      nullCustomer: 'Não encontramos uma conta para este usuário.',
      card_reference: 'Erro de referência para este cartão',
      card_update: 'Erro ao alterar cartão',
      card_remove: 'Erro ao remover cartão',
    },
  },
  timeElapsed: {
    12: '12 horas',
    24: '24 horas',
    48: '2 dias',
    120: '5 dias',
    240: '10 dias',
    720: '30 dias',
    label: 'Período dos pedidos',
  },
  telemedicine: {
    configHeader: 'configurações',
    configVideo: 'opções de vídeo',
    configAudio: 'opções de áudio',
    configRecord: 'opções de gravação',
    label: 'telemedicina',
    title: 'Telemedicina',
    count: 'chamadas feitas',
    chat: 'mensagens',
    notes: 'notas',
    requestCredits: 'requisitar créditos',
    call: 'Chamar',
    initRoom: 'iniciar sala',
    disconnect: 'Desligar',
    pause: 'Pausar',
    offerConnection: 'Chamar',
    answerConnection: 'Atender chamada',
    waitingConnection: 'Aguardando conexão',
    changeMicrophoneOn: 'ligar microfone',
    changeMicrophoneOff: 'desligar microfone',
    changeVideoOn: 'ligar câmera',
    changeVideoOff: 'desligar câmera',
    changeRecordOn: 'gravar',
    changeRecordOff: 'parar gravação',
    config: 'configurações',
    tabs: {
      labels: {
        dashboard: 'Dashboard',
        credits: 'Créditos',
        logs: 'Registros',
        calls: 'Chamadas',
        store: 'Loja',
      },
    },
    medicalRecords: {
      label: 'prontuários',
      labelSoon: 'prontuários (em breve)',
    },
    calls: {
      emptyFiles: 'nenhum',
      allCalls: 'Todas chamadas',
      label: 'Chamadas',
      callId: 'Identificador:',
      missed: 'Perdidas',
      callTo: 'Ligar para',
      details: 'Detalhes desta ligação:',
      patient: 'Paciente',
      patientDetails: 'Detalhes do Paciente',
      callDetails: 'Detalhes desta chamada',
      recents: 'Recentes',
      attachedFiles: 'Arquivos anexados:',
      story: 'eventos:',
      noDescription: 'sem notas para esta chamada',
      empty: 'sem registro de chamadas..',
      emptyMedicalRecords: 'sem registro de prontuários..',
      emptyDetails: 'visualize aqui os detalhes das suas chamadas',
    },
    callEvents: {
      begin: 'Ínicio',
      endByDoctor: 'Finalizada pelo Médico',
      endByPatient: 'Finalizada pelo Paciente',
      ended: 'Finalizada',
      disconnected: 'Desconectado',
      reconnected: 'Reconectado',
    },
    messages: {
      endByPatient: 'Chamada encerrada pelo paciente',
      endByDoctor: 'Chamada encerrada por você.',
      endByDoctorOnPatient: 'Chamada encerrada pelo Médico.',
      rejected: 'Chamada recusada pelo paciente.',
      notAnswed: 'Chamada não atendida',
      lostConnection: 'Conexão perdida',
      connectionClosed: 'Conexão finalizada',
    },
    credits: {
      label: 'Créditos',
      currentCredits: 'Créditos atuais deste usuário',
      userTransactions: 'Transações deste usuário',
      emptyTransactions: 'Nenhuma transação existente..',
      buyHint: 'Ação indisponível. Entre em contato para requisitar mais créditos.',
      storeLabel: 'Créditos Telemedicina',
      errors: {
        credit_condition: 'Crédito não pode ser inferior ao já existente.',
      }
    },
    cart: {
      label: 'Carrinho',
    },
  },
  tele: {
    offerConnection: 'Iniciar Chamada',
    answerConnection: 'Aceitar Chamada',
    waitingConnection: 'Esperando Chamada',
    errorWaitConnection: 'Espere o paciente iniciar a chamada',
    hangUp: 'Encerrar Chamada',
    errorNavigator: 'Não foi possível obter imagem, seu navegador está atualizado?',
    errorHttp: 'Utilize https para acessar esse recurso',
    errorStartingCam: 'Erro ao iniciar a câmera',
    errorStartingScreen: 'Erro ao iniciar o compatilhamento de tela',
    errorStartRemoteVideo: 'Erro ao iniciar componete de video remoto',
    errorStartLocalVideo: 'Erro ao iniciar componete de video local',
    errorOnRecord: 'Erro ao iniciar Gravação',
    errorUnknown: 'Erro desconhecido',
    errorTypeRecord: 'Não foi encontrado um tipo de mídia compatível no seu navegador para efetuar a gravação',
    warnOnRecordType: 'Não foi encontrado um tipo de mídia compatível no seu navegador para efetuar a gravação, vamos tentar gravar em webm',
    warnChangeTypeOnRecord: 'Essa mudança fará efeito na próxima gravação',
    warnNoTrackToRecord: 'Nenhuma mídia disponível para gravação',
    selectVideo: 'Selecione o vídeo',
    selectInputAudio: 'Selecione o microfone',
    selectOutputAudio: 'Selecione o autofalante',
    selectRecordType: 'Selecione o tipo de gravação',
    startSharedScreen: 'Compartilhar de tela',
    stopSharedScreen: 'Parar compartilhar de tela',
    configHeader: 'Configurações',
    configVideo: 'Câmera',
    configAudio: 'Áudio',
    configRecord: 'Gravação',
    config: 'Config',
    changeMicrophoneOn: 'Mute',
    changeMicrophoneOff: 'Unmute',
    changeVideoOn: 'Parar Vídeo',
    changeVideoOff: 'Iniciar Vídeo',
    changeShareScreenOn: 'Parar tela',
    changeShareScreenOff: 'Compartilhar tela',
    changeRecordOn: 'Parar gravação',
    changeRecordOff: 'Iniciar gravação',
    connectionState: {
      new: 'Inicializando...',
      connecting: 'Conectando...',
      connected: 'Conectado',
      disconnected: 'Desconectado',
      failed: 'Falha na conexão',
      closed: 'Conexão encerrada',
    },
  },
  navBar: {
    signIn: 'Cadastre-se',
    logIn: 'Entrar',
    logOut: 'Sair',
    orSignIn: 'Ou cadastre-se',
    singIn: 'Torne-se um parceiro',
    home: 'Início',
    about: 'Quem somos',
    forCompanies: 'para clínicas',
    forClients: 'para médicos',
    forVisitants: 'para pacientes',
    prices: 'Preços',
    blogs: 'Blog',
    contactUs: 'Contato',
    solutions: 'Soluções',
    solutionsTips: {
      patient: {
        title: 'Sou paciente',
        text: 'Tenha em mãos exames, e atendimentos.',
        call: 'Saiba mais...',
      },
      doctor: {
        title: 'Sou Médico',
        text: 'Quero fazer atendimentos por videochamada, prescrever exames',
        call: 'Facilite o atendimento...',
      },
      company: {
        title: 'Sou Clínica',
        text: 'Quero subir exames de pacientes, vincular médicos.',
        call: 'Descubra as vantagens...',
      },
    },
  },
  home: {
    yourData: 'Seus dados e cuidados médicos em um só lugar',
    downloadApp: 'Baixe o aplicativo e cadastre-se para ter acesso aos resultados dos seus exames e aos serviços de saúde que você procura em qualquer lugar',
    exam: 'Faça seus exames com mais praticidade',
    schedule: 'Agende seus exames nas unidades dos laboratórios parceiros e receba os resultados de seus exames pelo aplicativo em até 24h.',
    do: 'Encontre especialistas e agende suas consultas',
    find: 'Encontre os médicos mais próximos de você, agende as consultas pelo aplicativo e seja atendido presencialmente ou por vídeo.',
    forward: 'Encaminhe receitas e compre medicamentos',
    send: 'Envie receitas, orce e compre pelo aplicativo nas farmácias parceiras para receber medicamentos em casa ou retirar no local.',
    tips: 'Dicas de saúde e artigos de especialistas',
    practiality: 'Além da praticidade de marcar consultas, agendar exames e receber o resultado pelo aplicativo, o Sauver está repleto de conteúdos para você se informar sobre os mais diversos tratamentos, sanar suas dúvidas e ficar por dentro das novidades da saúde.',
    doAsk: 'Faça perguntas e receba respostas',
    talk: 'Converse com seu médico entre uma consulta e outra  pelo aplicativo e sane gratuitamente qualquer dúvida pontual com os especialistas do Sauver disponíveis naquele momento.',
    consult: ' Consultas e retornos por áudio e vídeo',
    beyond: 'Além de agendar consultas presenciais, atendimentos cotidianos podem ser realizados por áudio e vídeo pelo aplicativo - se o médico julgar apropriado e o paciente concordar - evitando filas e deslocamento.',
  },
  visitantHome: {
    template: {
      title: '',
      memo: '',
      content: '',
    },
    oldWelcome: {
      title: 'Reuna seus dados e atendimentos médicos em um só lugar',
      memo: 'Solucões para Médicos, Clínicas e Pacientes:',
      content:
        'Encontre os médicos mais próximos de você, agende as consultas pleo aplicativo e seja atendido presencialmente ou por vídeo',
    },
    welcome: {
      title: 'Conheça o Sauver',
      memo: 'Soluções para médicos, clínicas e pacientes:',
      content:
        'Esse é um lugar único para pacientes, médicos e empresas da saúde. Uma plataforma tecnológica, segura e protegida onde é possível reunir dados, resultados de exames, diagnósticos e todo o progresso do atendimento médico.',
    },
    inline1: {
      title: 'Único para pacientes',
      memo: '',
      content:
        'Aqui você pode encontrar os médicos mais próximos ou especialistas de sua preferência, agendar consultas e então escolher pelo atendimento presencial ou por vídeo. Também é possível conferir, por qualquer dispositivo, o resultado dos seus exames.',
    },
    inline2: {
      title: 'Único para médicos',
      memo: '',
      content:
        'Este site com funções de aplicativo permite que você atenda de qualquer lugar. Onde estiver, é completamente viável repassar orientações, prescrições e encaminhamentos e dar mais agilidade ao atendimento do seu paciente. Um sistema inteligente que garante a segurança do envio e do armazenamento dos dados conforme a Lei Geral de Proteção de Dados (LGPD).',
    },
    inline3: {
      title: 'Único para empresas',
      memo: '',
      content:
        'Clínicas, hospitais e consultórios conseguem realizar laudos e diagnósticos por meio da telemedicina do Sauver e, ainda nesta plataforma, fazer a entrega dos exames para os pacientes.',
    },
    practicalExams: {
      title: 'Exames com praticidade',
      memo: 'Praticidade no seu dia a dia',
      content:
        'Agende seus exames nas unidades dos laboratórios parceiros e receba os resultados de seus exames pelo aplicativo emm até 24h.',
    },
    teleConsultation: {
      title: 'Consultas por Telemedicina',
      memo: 'Segurança e Organização',
      content:
        'Encontre os médicos mais próximos de você, agende as consultas pelo aplicativo, seja atendido presencialmente ou por video chamada.',
    },
    exams: {
      title:
        'Armazene, organize e acompanhe os resultados dos seus exames médicos',
      memo: 'muito mais conhecimento!',
      content:
        'Salve seus exames na núvem para obter um maior controle de suas informações, ter acesso imediato aos seus dados e monitorar a evolução da sua saude',
    },
    telemedicine: {
      title: 'Atendimento médico a distância com praticidade e segurança',
      memo: '',
      content:
        'Tire proveito da tecnologia para se proteger e ser atendido pelo seu médico sem sair de casa com o recurso da telemedicina do Sauver.',
    },
    tips: {
      mobileCall: 'Atendimento em vídeochamada pelo aplicativo',
      signatureExams: 'Receitas e pedidos de exames assinados digitalmente',
      privacy: 'Privacidade, segurança e acesso protegido aos dados',
      regulated: '100% de acordo com a regulamentação do CFM',
      chat: 'Converse com seu médico pelo chat',
      examsUpload: 'Envie resultados de exame em PDF ou pela câmera',
      doctorAccess: 'Dê acesso aos resultados de exames ao seu médico',
      folder: 'Crie pastas para organizar exames como achar melhor',
      companies: 'Receba resultados de exames de laboratórios parceiros',
      healthGraph: 'Visualize em gráficos a evolução de seus resultados',
      findNear: 'Encontre clínicas e médicos próximos a você',
      healthBlog: 'Conteúdos educacionais e dicas de saúde',
    },
    othersResources: {
      headline: 'Facilidades',
      title: 'Conheça outros recursos e facilidades do Sauver para você',
      memo: '',
      content: '',
    },
  },
  doctorHome: {
    welcome: {
      title: 'Mais versatilidade e praticidade para os seus atendimentos',
      memo: 'Tecnologia a seu favor',
      content:
        'Traga ainda mais tecnologia para o seu consultório com os recursos de telemedicina e gerenciamento de informações do Sauver.',
    },
    signature: {
      title: 'Assinatura Digital',
      memo: 'Fique por dentro, com o Chat Sauver.',
      content:
        'O Bird ID é um Certificado Digital em nuvem. Uma solução inovadora que traz liberdade total e segurança sem igual.',
      premium: {
        content:
          'Ganhe um Certificado Digital para suas prescrições incluso no plano Premium',
      },
    },
    telemedicine: {
      title: 'Consultas por Vídeo',
      memo: 'Telemedicina Sauver, a videoconferência que conecta médicos e pacientes.',
      content:
        'economize tempo e distância conversando online com seu médico em uma consulta por vídeo chamada.',
      premium: {
        content: 'ganhe créditos mensalmente no plano Premium',
      },
    },
    schedule: {
      title: 'Agenda Sauver',
      memo: 'mais organização para o seu dia a dia',
      content:
        'economize tempo e distância conversando online com seu médico em uma consulta por vídeo chamada.',
      premium: {
        content: 'em breve disponível para web',
      },
    },
  },
  companyHome: {
    welcome: {
      title: 'Quem somos',
      memo: 'Que pacientes, médicos e empresas tenham aqui e sempre uma boa experiência.',
      content:
        'Uma startup sul-mato-grossense criada por 3 médicos conectados com a necessidade de seus pacientes que querem ter acesso a todas as informações sobre seu tratamento.',
      content2:
        'E com a Sauver eles podem. O Sauver chegou para gerar valor na prestação de serviços de saúde e, para isso, se beneficia da facilidade entregue pela revolução digital.',
      content3:
        'Que pacientes, médicos e empresas tenham aqui e sempre uma boa experiência.',
    },
    advantages: {
      title: 'Descubra as vantagens de trazer a sua empresa para o Sauver',
      memo: 'mais facilidade para tua empresa',
      content:
        'Traga ainda mais tecnologia para o seu consultório com os recursos de telemedicina e gerenciamento de informações do Sauver',
    },
    connectedSolutions: {
      title: 'Soluções Conectadas Sauver',
    },
    inlineOne: {
      title: 'Exames com praticidade',
      content:
        'Depois da teleconsulta, é só agendar por aqui seus exames nas unidades dos laboratórios parceiros e receber os resultados onde estiver.',
    },
    inlineTwo: {
      title: 'Para guardar seu exame',
      content:
        'Seus exames podem ser salvos na nuvem para conquistar organização, agilidade e maior controle de suas informações, e assim monitorar a evolução da sua saúde.',
    },
    inlineThree: {
      title: 'Assinatura digital',
      content:
        'Também compõem as soluções Sauver a assinatura digital para médicos e empresas. Essa identidade garante autenticidade, economia e sustentabilidade.',
    },
    aboutLines: {
      line1:
        'Saúde Digital de Verdade <br> De verdade porque é preciso. <br> Porque é tecnológico. <br> Porque é eficiente. <br> Porque é integral. <br> Porque é preventiva. <br> Porque mesmo à distância, é humana.',
    },
  },
  contactUs: {
    headline: 'Fale com a gente',
    title: 'Estamos prontos para resolver suas dúvidas',
    memo: 'Conheça os canais de atendimento que disponibilizamos para que você entre em contato com a gente.',
    content: 'Traga ainda mais tecnologia e praticidade para sua vida.',
    request: {
      title: 'Envie sua dúvida ou sugestão',
      actionLabel: 'Enviar',
    },
    response: {
      success: 'Mensagem enviada com sucesso',
      error: 'Erro ao enviar sua mensagem',
    }
  },
  footer: {
    appsStore: 'Aplicativos',
    appIos: 'Sauver para iOS',
    appAndroid: 'Sauver para Android',
    devWorks: 'Avançado',
    integrations: 'Integrações',
    developers: 'Desenvolvedores',
    institucional: 'Institucional',
    aboutUs: 'Quem somos',
    workUs: 'Trabalhe conosco',
    successCases: 'Casos de sucesso',
    press: 'Imprensa',
    contact: 'Contato',
    solutions: 'Soluções',
    toPatients: 'Para pacientes',
    toDoctors: 'Para médicos',
    toCompanies: 'Para empresas',
    pricesPlans: 'Planos e Preços',
    contents: 'Conteúdos',
    helpCenter: 'Central de Ajuda',
    podcast: 'Podcast',
    blog: 'Blog',
  },
  emailReset: {
    subject: 'Resetar senha',
    head: 'Email de redefinição de senha',
    body: '',
    link: 'Resetar senha',
  },
  profileStatus: {
    PENDING: 'Pendente',
    APPROVED: 'Aprovado',
    REJECTED: 'Rejeitado',
    INCOMPLETE: 'incompleto',
    UNKNOWN: 'indefinido',
    PREUSER: 'pré-usuário',
    NOTSEND: 'não enviado',
    CANCELED: 'Cancelado',
  },
  examsOrders: {
    notification: {
      newExamOrder: 'Novo pedido de exame',
      ExamOrderBody: 'Novo pedido de exames, Dr. ',
    },
  },
  apiErrorCode: {
    ok: 'Sucesso',
    permissionDenied: 'Permissão negada',
    statusError: 'Estado inválido',
    inputError: 'Erro nos parâmetros recebidos',
    unknown: 'Erro desconhecido',
    serverError: 'Erro interno no servidor',
    exhaustedAttempts: 'Muitas tentativas realizadas sem sucesso',
    clientError: 'Erro ao tentar acessar o servidor',

    requireOtp: 'Token OTP necessário',
    revokeToke: 'Error ao revogar token',
    signUserNotFound: 'Usuário não encontrado no BirdID ou no VoultID',
    pdfGenerateError: 'Erro ao gerar pdf da prescrição',
    uploadBucketError: 'Erro ao gravar prescrição',
    signatureError: 'Erro ao assinar documento',
    activateTokenError: 'Erro ao utilizar o token',
  },
  format: {
    data: 'DD/MM/YYYY',
    dateTime: 'DD/MM/YYYY HH:mm:ss',
    time: 'HH:mm:ss',
  },
}
