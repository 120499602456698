export default {
  routeLogged: {
    ADMIN: 'admin/users_clients',
    CLIENT: 'client/dashboard',
    COMPANY: 'company/profile',
  },
  routeNotLogged: {
    ADMIN: 'admin/login',
    CLIENT: 'client/login',
    COMPANY: 'company/login',
  },
  routeLogOut: {
    ADMIN: 'admin/login',
    CLIENT: 'client/login',
    COMPANY: 'company/login',
  },
}
