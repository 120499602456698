<script>
  export default {
    props: {
    },
  }

  // class="md-simple md-success md-lg"
</script>

<template>
  <v-btn
      solo
    color="primary"
    :class="$style.button"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<style lang="scss" module>
@import '@design';
.button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
}
</style>
