/**
 * Usage in CSS:
 * Não pode ser utilizado transparência, dizem que irão adicionar transparencia no Vuetify 3
 *
 * .myClass {
 *   color: var(--v-[name]-base, [defaultValue]);
 * }
 *
 * Example:
 * .myClass {
 *   color: var(--v-navBarColor-base, #FF00FF);
 * }
 *
 *
 * Usage in Vuetify:
 *
 *  <VBtn color="navBarColor">
 *    Apenas um botão
 *  </VBtn>
 *
 *  <VBtn color="primary">
 *    Apenas um botão
 *  </VBtn>
 *
 *
 * Obs: As cores base do vue podem ser utilizadas também como classes, as cores customizadas não podem ser utilizadas de tal forma:
 *  <VBtn class="primary">
 *    Apenas um botão
 *  </VBtn>
 *  <div class="primary--text">
 *    Apenas um botão
 *  </VBtn>
 *
 *
 * Obs2: É gerado o arquivo src/design/theme_compiled.css para melhorar o autocomplete da IDE, esse arquivo não deve ser utilizado
 * para nenhuma outra funcionalidade
 *
 */


module.exports = {
  light: {
    // main colors vue
    primary: '#0a84ff',
    secondary: '#ff5052', // ainda não definido
    accent: '#82b1ff', // ainda não definido
    anchor: '#1976d2',
    error: '#ff5252',
    info: '#2196f3',
    success: '#4caf50',
    warning: '#fb8c00',
    background: '#F8F8F8',


    // NavBar
    navBarBg: '#F0F0F0',
    navBarText: '#000000',
    navBarTextSelected: '#FFFFFF',
    navBarItemSelectedBg: '#000000',
    navBarIcon: '#000000',
    navBarIconSelected: '#FFFFFF',

    // Geral
    label: '#FFFFFF',
    text: '#000000',
    textBg: '#FFFFFF',
    textRemove: '#ff0c18',
    buttonRemove: '#ff0c16',
    formHeadBg: '#fbfbfd',
    formSplitter: '#e2e2e3',
    formBodyBg: '#f9f9f9',
    formBorder: '#f1f1f2',
    buttonText: '#eeecec',
    buttonBg: '#000000',
    scroll: '#5a5a5a',
    scrollBg: '#f0f0f0',
    listItemBg: '#F4F4F5',
    listItemSelectedBg: '#cdcdcd',
    listItemText: '#000000',
    listItemBorder: '#f1f1f2',
    listItemTextSecondary: '#353535',
    modalBg: '#F8F8F8',
    modalHeaderBg: '#e9e7e7',
    eventHeaderBg: '#e9e7e7',


    swal2Bg: '#e1e1e1',
    swal2Bg2: '#c8c8c8',
    swal2Text: '#19191a',
    swal2Text2: '#323234',
    swal2Border: '#505050',

    eventProduced: '#32D74A',
    eventOver: '#BE59F0',
    eventReceived: '#0C84FE',
    eventInProcessing: '#947c06',
    eventLoss: '#FF443A',
    eventDiscount: '#E400FA',

    eventEmpty: '#8E8E93',
    eventEmpty2: '#e1e1e1',
    eventOk: '#31D158',
    eventWarning: '#FED609',
    eventError: '#FF453A',

    eventCSInvert1: '#e1e1e1',
    eventCSInvert2: '#000000',
    eventCSAwaitingCheck: '#5BC7FA',
    eventCSWaitSendGuia: '#FF9E0B',
    eventCSWaitPayment: '#0C84FE',
    eventCSWaitSendRecourse: '#FF9E0B',
    eventCSWaitRecourse: '#BE59F0',
    eventCSFinished: '#32D74B',
    eventCSReceived: '#32D74B',
    eventCSLosed: '#FF453A',
    eventCSCuted: '#ED930A',

  },
 /* dark: {
    // main colors vue
    primary: '#8AE800',
    secondary: '#424242', // ainda não definido
    accent: '#ff4081', // ainda não definido
    anchor: '#2196f3',
    error: '#ff5252',
    info: '#2196f3',
    success: '#4caf50',
    warning: '#fb8c00',
    background: '#1C1C1E',

    // NavBar
    navBarBg: '#131313',
    navBarText: '#FFFFFF',
    navBarTextSelected: '#000000',
    navBarItemSelectedBg: '#8AE800',
    navBarIcon: '#545457',
    navBarIconSelected: '#000000',


    // Geral
    label: '#8F8F94',
    text: '#FFFFFF',
    textBg: '#000000',
    textRemove: '#DC2727',
    buttonRemove: '#FF0916',
    formHeadBg: '#131315',
    formSplitter: '#262628',
    formBodyBg: '#1C1C1F',
    buttonText: '#000000',
    buttonBg: '#09FE5E',
    scroll: '#5a5a5a',
    scrollBg: '#121212',
    listItemBg: '#313134',
    listItemSelectedBg: '#54545a',
    listItemText: '#FFFFFF',
    listItemBorder: '#626268',
    listItemTextSecondary: '#9d9da5',
    modalBg: '#1C1C1E',
    modalHeaderBg: '#2b2b2d',
    eventHeaderBg: '#000000',
   // formBorder: 'rgba(255,255,255,0)',

    swal2Bg: '#19191a',
    swal2Bg2: '#323234',
    swal2Text: '#e1e1e1',
    swal2Text2: '#c8c8c8',
    swal2Border: '#505050',

    eventProduced: '#32D74A',
    eventOver: '#BE59F0',
    eventReceived: '#0C84FE',
    eventInProcessing: '#F8D00B',
    eventLoss: '#FF443A',
    eventDiscount: '#E400FA',

    eventEmpty: '#8E8E93',
    eventEmpty2: '#e1e1e1',
    eventOk: '#31D158',
    eventWarning: '#FED609',
    eventError: '#FF453A',


    eventCSInvert1: '#e1e1e1',
    eventCSInvert2: '#000000',
    eventCSAwaitingCheck: '#5BC7FA',
    eventCSWaitSendGuia: '#FF9E0B',
    eventCSWaitPayment: '#0C84FE',
    eventCSWaitSendRecourse: '#FF9E0B',
    eventCSWaitRecourse: '#BE59F0',
    eventCSFinished: '#32D74B',
    eventCSReceived: '#32D74B',
    eventCSLosed: '#FF453A',
    eventCSCuted: '#ED930A',
  },

*/
}
