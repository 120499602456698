'use strict'

let toExport = {}

if (!process.env.GCLOUD_PROJECT_ON_BUILD && process.env.GCLOUD_PROJECT) {
  process.env.GCLOUD_PROJECT_ON_BUILD = process.env.GCLOUD_PROJECT
}

let links = {
  site: {
    name: 'Site',
    link: 'https://sauver.med.br',
  },
  facebook: {
    name: 'Facebook',
    link: 'https://www.facebook.com/Sauver-112736837169018',
  },
  instagram: {
    name: 'Instagram',
    link: 'https://www.instagram.com/sauver_med',
  },
  linkedin: {
    name: 'Linkedin',
    link: 'https://www.linkedin.com/company/sauver/',
  },
}

const android = {
  linkStore: 'https://play.google.com/store/apps/details?id=br.com.justworks.sauver',
  storeCode: '',
  id: 'br.com.justworks.sauver',
  uri: 'vnd.sauver://',
  name: 'Sauver',
  packageName: 'br.com.justworks.sauver',
}

const ios = {
  linkStore: 'https://apps.apple.com/br/app/sauver/id1516890732',
  storeCode: 'id1516890732',
  id: 'id1516890732',
  uri: 'vnd.sauver://',
  name: 'Sauver',
  packageName: '',
}

const doctorAndroid = {
  linkStore: 'https://play.google.com/store/apps/details?id=br.com.justworks.sauverdoctor',
  storeCode: '',
  id: 'br.com.justworks.sauverdoctor',
  uri: 'vnd.sauver://',
  name: 'Sauver Doctor',
  packageName: 'br.com.justworks.sauverdoctor',
}

const doctorIOS = {
  linkStore: 'https://apps.apple.com/ao/app/sauver-doctors/id1575287362',
  storeCode: 'id1575287362',
  id: 'id1575287362',
  uri: 'vnd.sauver://',
  name: 'Sauver Doctor',
  packageName: '',
}

toExport.listConfig = {
  default: {
    name: 'Default ALGO DE ERRADO NÃO ESTA CERTO',
    maps: {
      key: 'AIzaSyB6e8Ib4hDkP3M1LxXaa3vPwdOgTuL1Fk4',
    },
    firebase: null,
    fbId: '',

    title: 'Sauver',
    description: 'Sauver',

    verLaboratorios:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,
    saibaMais:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,
    verFarmacias:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,

    baseUrl: 'https://sauver.med.br',
    links: links,
    ios: ios,
    android: android,
    doctorAndroid,
    doctorIOS,
    rtc: {
      iceServers: [
        {
          urls: [
            'stun:stun.l.google.com:19302',
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302',
          ],
        },
      ],
      iceCandidatePoolSize: 10,
      rtc: null,
    },
  },
  uhealthjw: {
    name: 'Sauver PRODUCTION',
    maps: {
      key: 'AIzaSyB6e8Ib4hDkP3M1LxXaa3vPwdOgTuL1Fk4',
    },
    title: 'Sauver',
    description: 'Sauver',

    verLaboratorios:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,
    saibaMais:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,
    verFarmacias:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,

    baseUrl: 'https://sauver.med.br',
    firebase: {
      apiKey: 'AIzaSyB6e8Ib4hDkP3M1LxXaa3vPwdOgTuL1Fk4',
      authDomain: 'uhealthjw.firebaseapp.com',
      databaseURL: 'https://uhealthjw.firebaseio.com',
      projectId: 'uhealthjw',
      storageBucket: 'uhealthjw.appspot.com',
      messagingSenderId: '638777031296',
      appId: '1:638777031296:web:eb0bea7036ae1e2e284465',
      measurementId: 'G-ELX7E1KL1G',
    },
    stripe: {
      id: 'acct_1IEOtYKLJvEpvomN',
      test: 'pk_test_51IEOtYKLJvEpvomNEG1FE7FRnzQ8WkJq6sKlLUkwd5HsdDkFmxg11wKZb5UcHRAFYbRjeH1cDWX6OwZhvYPj9QXD00U85mDYlT',
      live: 'pk_live_51IEOtYKLJvEpvomNJr7NMDYWVXqWWcR3FtJ5arczYoEjBrySpGLokCqqju2rzDHmVlXirm4uAIR1E9JGvG4ZLWeg00uPp3aLhy',
    },
    fbId: '',
    links: links,
    ios: ios,
    android: android,
    doctorAndroid,
    doctorIOS,
    rtc: {
      iceServers: [
        {
          urls: [
            'stun:stun.l.google.com:19302',
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302',
          ],
        },
      ],
      iceCandidatePoolSize: 10,
    },
  },
  uhealthjwdev: {
    name: 'Sauver DEVELOPER',
    maps: {
      key: 'AIzaSyB6e8Ib4hDkP3M1LxXaa3vPwdOgTuL1Fk4',
    },
    title: '(DEV)Sauver',
    description: 'Sauver Dev',

    verLaboratorios:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,
    saibaMais:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,
    verFarmacias:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,

    baseUrl: 'https://dev.sauver.med.br',
    firebase: {
      apiKey: 'AIzaSyDbec8dkGqUaYv4C7SDYiEbMNQUFpaxfro',
      authDomain: 'uhealthjwdev.firebaseapp.com',
      databaseURL: 'https://uhealthjwdev-default-rtdb.firebaseio.com',
      projectId: 'uhealthjwdev',
      storageBucket: 'uhealthjwdev.appspot.com',
      messagingSenderId: '435885627871',
      appId: '1:435885627871:web:19fc83582c59fce838cc6a',
      measurementId: 'G-BGBNJ9CFF7',
    },
    stripe: {
      id: 'acct_1IEOtYKLJvEpvomN',
      test: 'pk_test_51IEOtYKLJvEpvomNEG1FE7FRnzQ8WkJq6sKlLUkwd5HsdDkFmxg11wKZb5UcHRAFYbRjeH1cDWX6OwZhvYPj9QXD00U85mDYlT',
      live: 'pk_live_51IEOtYKLJvEpvomNJr7NMDYWVXqWWcR3FtJ5arczYoEjBrySpGLokCqqju2rzDHmVlXirm4uAIR1E9JGvG4ZLWeg00uPp3aLhy',
    },
    fbId: '',
    links: links,
    ios: ios,
    android: android,
    doctorAndroid,
    doctorIOS,
    rtc: {
      iceServers: [
        {
          urls: [
            'stun:stun.l.google.com:19302',
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302',
          ],
        },
      ],
      iceCandidatePoolSize: 10,
    },
  },
  developer: {
    name: 'Sauver DEVELOPER',
    maps: {
      key: 'AIzaSyB6e8Ib4hDkP3M1LxXaa3vPwdOgTuL1Fk4',
    },
    title: '(DEV)Sauver',
    description: 'Sauver Dev',

    verLaboratorios:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,
    saibaMais:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,
    verFarmacias:
      'https://app.zeplin.io/project/5980cb1abfb5fde723fb8559/screen/5e8bd3ec8874b0220dd79311' /* Colocar o site certo */,

    baseUrl: 'https://dev.sauver.med.br',
    firebase: {
      apiKey: 'AIzaSyDbec8dkGqUaYv4C7SDYiEbMNQUFpaxfro',
      authDomain: 'uhealthjwdev.firebaseapp.com',
      databaseURL: 'https://uhealthjwdev-default-rtdb.firebaseio.com',
      projectId: 'uhealthjwdev',
      storageBucket: 'uhealthjwdev.appspot.com',
      messagingSenderId: '435885627871',
      appId: '1:435885627871:web:19fc83582c59fce838cc6a',
      measurementId: 'G-BGBNJ9CFF7',
    },
    stripe: {
      //pk_test_51IEOtYKLJvEpvomNEG1FE7FRnzQ8WkJq6sKlLUkwd5HsdDkFmxg11wKZb5UcHRAFYbRjeH1cDWX6OwZhvYPj9QXD00U85mDYlT
      id: 'acct_1IEOtYKLJvEpvomN',
      test: 'pk_test_51IEOtYKLJvEpvomNEG1FE7FRnzQ8WkJq6sKlLUkwd5HsdDkFmxg11wKZb5UcHRAFYbRjeH1cDWX6OwZhvYPj9QXD00U85mDYlT',
      live: 'pk_live_51IEOtYKLJvEpvomNJr7NMDYWVXqWWcR3FtJ5arczYoEjBrySpGLokCqqju2rzDHmVlXirm4uAIR1E9JGvG4ZLWeg00uPp3aLhy',
    },
    fbId: '',
    links: links,
    ios: ios,
    android: android,
    doctorAndroid,
    doctorIOS,
    rtc: {
      iceServers: [
        {
          urls: [
            'stun:stun1.l.google.com:19302',
            'stun:stun2.l.google.com:19302',
          ],
        },
      ],
      iceCandidatePoolSize: 10,
    },
  },
}
toExport.config = toExport.listConfig['default']

if (process.env.GCLOUD_PROJECT_ON_BUILD === 'uhealthjwdev') {
  toExport.config = toExport.listConfig['uhealthjwdev']
  console.log(
    'Server config start configName: ' +
      toExport.config.name +
      ' on project: ' +
      (process.env.GCLOUD_PROJECT_ON_BUILD || '')
  )
} else if (process.env.GCLOUD_PROJECT_ON_BUILD === 'uhealthjw') {
  toExport.config = toExport.listConfig['uhealthjw']
  console.log(
    'Server config start configName: ' +
      toExport.config.name +
      ' on project: ' +
      (process.env.GCLOUD_PROJECT_ON_BUILD || '')
  )
} else {
  toExport.config = toExport.listConfig['developer']
  console.log(
    'Server config start configName: ' +
      toExport.config.name +
      ' on project: ' +
      (process.env.GCLOUD_PROJECT_ON_BUILD || '')
  )
}

// console.log('Server config start configName: ' + toExport.config.name);

toExport.appConfig = toExport.config

module.exports = toExport
