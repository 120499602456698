import "@common/ExtensionsJS";
import Vue from 'vue';
import '@src/db.js' // Precisa ser antes do import do store
import "@src/plugins/axios";
import App from "@src/app.vue";
import router from "@src/router";
import store from "@src/state";
import vuetify from "./plugins/vuetify";
import Vuelidate from 'vuelidate';
// import "roboto-fontface/css/roboto/roboto-fontface.css";
import '@fortawesome/fontawesome-free/css/all.css'
import "@mdi/font/css/materialdesignicons.css";
import i18n from "@src/plugins/i18n";
import pluginGlobal from '@src/plugins/global.js';
// import CKEditorPlugin from '@src/plugins/ckeditor.js';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import '@src/plugins/vuefire';
import VueTheMask from 'vue-the-mask';
import '@components/_globals';
import money from 'v-money';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueExpandableImage from 'vue-expandable-image'
// import Carousel3d from 'vue-carousel-3d'
import { config, listConfig } from '@src/config'

const _ = require('lodash');

const fcmI18n = require('@i18n')

import moment from 'moment'

// const Ckeditor = new CKEditorPlugin();

Vue.use(pluginGlobal);
Vue.use(Vuelidate)
Vue.use(VueTheMask);
Vue.use(money);
// Vue.use(Carousel3d);
// Vue.use(Ckeditor);
Vue.use(CKEditor);

Vue.use(VueGoogleMaps, {
  load: {
    key: config.maps.key,
    libraries: 'places,drawing', // This is required if you use the Autocomplete plugin
    language: i18n.locale,
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    // If you want to set the version, you can do so:
    // v: '3.26',
  },

  // If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  // autobindAllEvents: false,

  // If you want to manually install components, e.g.
  // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then disable the following:
  // installComponents: true,
});

Vue.use(VueExpandableImage);

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
// import 'sweetalert2/dist/sweetalert2.min.css';

import '@src/design/sweetalert2Theme.scss';

import 'nprogress/nprogress.css';

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

require('moment/locale/pt-br');
window.moment = moment;
window._ = _;

/*
// Reseta os Services workers se houver algum problema com a atualização dos mesmos
if(window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations()
    .then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister();
      }
    });
}
 */

export const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  beforeMount() {
    const locale = this.$i18n.locale;
    if(fcmI18n.languages[locale]) {
      this.$vuetify.lang.current = fcmI18n.languages[locale].vuetify;
    }
  },
  render: h => h(App)
}).$mount("#app");

if(window) {
  window.vueApp = app;
}

if (module.hot) {
  module.hot.accept(['@i18n/en-US', '@i18n/pt-BR'], function () {
    i18n.setLocaleMessage('en-US', require('@i18n/en-US').default)
    i18n.setLocaleMessage('pt-BR', require('@i18n/pt-BR').default)
    // Or the following hot updates via $i18n property
    // app.$i18n.setLocaleMessage('en', require('./en').default)
    // app.$i18n.setLocaleMessage('ja', require('./ja').default)
  })
}





/*

router.beforeEach((to, from, next) => {
  let currentUser = auth().currentUser
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !currentUser) {
    next('Login')
  } else if (!requiresAuth && currentUser) {
    next()
  } else {
    next()
  }
})

let app

// Initialize the app after Firebase has iniitalized
auth().onAuthStateChanged(function (user) {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      render: h => h(App)
    })
  }
})

*/

/*

let performance = null
// TODO FCM register bugs only on production
if (
  process.env.NODE_ENV === 'production' &&
  process.env.NODE_ENV !== 'production'
) {
  Vue.config.errorHandler = function (err, vm, info) {
    // Toast.error(error.message)
    console.warn('errorHandler', err.message)
    // send error as event to google analytcs...
    if (err) {
      console.warn('errorHandler stack', err.stack)
      console.warn('errorHandler info', info)
    }
  }

  window.onerror = function (msg, url, lineNo, columnNo, error) {
    // maybe we need to also catch errors here and send to GA
    console.warn('onerror', msg, url, lineNo, columnNo, error)
  }
}

performance = firebase.performance()
Vue.mixin({
  methods: {
    $performance: function () {
      return performance
    },
  },
})

 */
