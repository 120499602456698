import store from '@state'
import defaults from '@/src/router/defaults'

export default [
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('@views/home')),
  },
  {
    path: '/about',
    name: 'about',
    component: () => lazyLoadView(import('@views/visitant/about')),
  },
  {
    path: '/solutions/patients',
    name: 'solutions/patient',
    component: () => lazyLoadView(import('@views/visitant/landing')),
  },
  {
    path: '/solutions/doctors',
    name: 'solutions/doctors',
    component: () => lazyLoadView(import('@views/client/landing')),
  },
  {
    path: '/solutions/companies',
    name: 'solutions/companies',
    component: () => lazyLoadView(import('@views/company/landing')),
  },
  {
    path: '/prices',
    name: 'prices',
    component: () => lazyLoadView(import('@views/visitant/commercial')),
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: () => lazyLoadView(import('@views/visitant/blog')),
  },
  {
    path: '/contact',
    name: 'contactUs',
    component: () => lazyLoadView(import('@views/visitant/contact')),
  },
  {
    path: '/nativeContact',
    name: 'nativeContact',
    component: () => lazyLoadView(import('@views/visitant/contact')),
  },
  {
    path: '/login',
    redirect: {
      name: 'company/login',
    },
  },
  {
    path: '/register',
    redirect: {
      name: 'company/register',
    },
  },

  // COMPANY
  {
    path: '/company/register',
    name: 'company/register',
    component: () => lazyLoadView(import('@views/company/register')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: defaults.routeLogged[store.getters['auth/loggedIn']],
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },

  {
    path: '/company',
    redirect: {
      name: 'company/login',
    },
  },
  {
    path: '/empresa',
    redirect: {
      name: 'company/login',
    },
  },
  {
    path: '/clinica',
    redirect: {
      name: 'company/login',
    },
  },
  {
    path: '/company/login',
    name: 'company/login',
    component: () => lazyLoadView(import('@views/company/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: defaults.routeLogged[store.getters['auth/loggedIn']],
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },

  {
    path: '/company/profile',
    name: 'company/profile',
    component: () => lazyLoadView(import('@views/company/company_profile')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
    },
  },
  {
    path: '/company/users/:id?',
    name: 'company/users',
    component: () => lazyLoadView(import('@views/company/company_users')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
    },
  },
  {
    path: '/company/doctors/:id?',
    name: 'company/doctors',
    component: () => lazyLoadView(import('@views/company/company_doctors')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
    },
  },
  {
    path: '/company/clients/:id?',
    name: 'company/clients',
    component: () => lazyLoadView(import('@views/company/company_clients')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
    },
  },

  {
    path: '/company/exams_clients/:id?',
    name: 'company/exams_clients',
    component: () => lazyLoadView(import('@views/company/exams_clients')),
    meta: {
      authRequired: true,
      workspace: 'COMPANY',
    },
  },

  // CLIENT
  {
    path: '/client',
    redirect: {
      name: 'client/login',
    },
  },
  {
    path: '/doctor',
    redirect: {
      name: 'client/login',
    },
  },
  {
    path: '/doutor',
    redirect: {
      name: 'client/login',
    },
  },
  {
    path: '/patient',
    redirect: {
      name: 'client/login',
    },
  },
  {
    path: '/paciente',
    redirect: {
      name: 'client/login',
    },
  },
  {
    path: '/medico',
    redirect: {
      name: 'client/login',
    },
  },

  {
    path: '/client/login',
    name: 'client/login',
    component: () => lazyLoadView(import('@views/client/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: defaults.routeLogged[store.getters['auth/loggedIn']],
          })
        } else {
          // Continue to the login page
          next()
        }
      },
      supperTestFCM: true,
    },
  },
  {
    path: '/client/register',
    name: 'client/register',
    component: () => lazyLoadView(import('@views/client/register')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: defaults.routeLogged[store.getters['auth/loggedIn']],
          })
        } else {
          // Continue to the login page
          next()
        }
      },
      supperTestFCM: true,
    },
  },
  {
    path: '/client/recover-password',
    name: 'recoverPassword',
    component: () => lazyLoadView(import('@views/client/RecoverPassword')),
  },
  {
    path: '/client/dashboard',
    name: 'client/dashboard',
    component: () => lazyLoadView(import('@views/client/dashboard')),
    props: true,
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  // {
  //   path: '/client/profile',
  //   name: 'client/profile',
  //   component: () => lazyLoadView(import('@views/client/profile')),
  //   meta: {
  //     authRequired: true,
  //     workspace: 'CLIENT',
  //   },
  // },
  {
    path: '/client/profile',
    name: 'client/profile',
    component: () => lazyLoadView(import('@views/client/profileRebrand')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  {
    path: '/client/telemedicine',
    name: 'client/telemedicine',
    component: () => lazyLoadView(import('@views/client/telemedicine')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  {
    path: '/client/store',
    name: 'client/store',
    component: () => lazyLoadView(import('@views/client/store')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  {
    path: '/client/schedule',
    name: 'client/schedule',
    component: () => lazyLoadView(import('@views/client/schedule')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  {
    path: '/client/videoRoom/:id?',
    name: 'client/videoRoom',
    component: () => lazyLoadView(import('@views/client/videoRoom')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  {
    path: '/client/exams/:id?',
    name: 'client/exams',
    component: () => lazyLoadView(import('@views/client/exams')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  {
    path: '/client/exams_clients/:id?',
    name: 'client/exams_clients',
    component: () => lazyLoadView(import('@views/client/exams_clients')),
    props: true,
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  {
    path: '/client/prescriptions/:id?',
    name: 'client/prescriptions',
    component: () => lazyLoadView(import('@views/client/prescriptions')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  {
    path: '/client/exam_request/:id?',
    name: 'client/exam_request',
    component: () => lazyLoadView(import('@views/client/exams_requests')),
    props: true,
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  {
    path: '/client/exams_templates/:id?',
    name: 'client/exams_templates',
    component: () => lazyLoadView(import('@views/client/exams_templates')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },
  {
    path: '/client/prescriptions_clients/:id?',
    name: 'client/prescriptions_clients',
    component: () =>
      lazyLoadView(import('@views/client/prescriptions_clients')),
    meta: {
      authRequired: true,
      workspace: 'CLIENT',
    },
  },

  // ADMIN
  {
    path: '/admin/test',
    name: 'admin/test',
    component: () => lazyLoadView(import('@views/admin/TestView')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin',
    redirect: {
      name: 'admin/login',
    },
  },
  {
    path: '/admin/login',
    name: 'admin/login',
    component: () => lazyLoadView(import('@views/admin/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: defaults.routeLogged[store.getters['auth/loggedIn']],
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/admin/categories/:id?',
    name: 'admin/categories',
    component: () => lazyLoadView(import('@views/admin/categories')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },

  {
    path: '/admin/users/:id?',
    name: 'admin/users',
    component: () => lazyLoadView(import('@views/admin/users')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/users_clients/:id?',
    name: 'admin/users_clients',
    component: () => lazyLoadView(import('@views/admin/users_clients')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm'],
    },
    params: {},
  },
  {
    path: '/admin/companies/:id?',
    name: 'admin/companies',
    component: () => lazyLoadView(import('@views/admin/companies')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm'],
    },
    params: {},
  },
  {
    path: '/admin/products/:id?',
    name: 'admin/products',
    component: () => lazyLoadView(import('@views/admin/products')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm'],
    },
    params: {},
  },
  {
    path: '/admin/telemedicine/:id?',
    name: 'admin/telemedicine',
    component: () => lazyLoadView(import('@views/admin/StoreTelemedicine')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm'],
    },
    params: {},
  },
  {
    path: '/admin/cards/:id?',
    name: 'admin/cards',
    component: () => lazyLoadView(import('@views/admin/cards')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/tags/:id?',
    name: 'admin/tags',
    component: () => lazyLoadView(import('@views/admin/tags')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/blog/:id?',
    name: 'admin/blog',
    component: () => lazyLoadView(import('@views/admin/blog')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/faq/:id?',
    name: 'admin/faq',
    component: () => lazyLoadView(import('@views/admin/faq')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/doubts/:id?',
    name: 'admin/doubts',
    component: () => lazyLoadView(import('@views/admin/doubts')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/pages/:id?',
    name: 'admin/pages',
    component: () => lazyLoadView(import('@views/admin/pages')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/blog_categories/:id?',
    name: 'admin/blog_categories',
    component: () => lazyLoadView(import('@views/admin/blog_categories')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/medicament_upload/:id?',
    name: 'admin/medicament_upload',
    component: () => lazyLoadView(import('@views/admin/medicament_upload')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    // TUSS OPTION
    path: '/admin/tuss_upload/:id?',
    name: 'admin/tuss_upload',
    component: () => lazyLoadView(import('@views/admin/tuss_upload')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/insurances/:id?',
    name: 'admin/insurances',
    component: () => lazyLoadView(import('@views/admin/insurances')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm'],
    },
    params: {},
  },
  {
    path: '/admin/exam_templates/:id?',
    name: 'admin/exam_templates',
    component: () => lazyLoadView(import('@views/admin/exam_templates')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm'],
    },
    params: {},
  },
  {
    path: '/admin/health_data_fields/:id?',
    name: 'admin/health_data_fields',
    component: () => lazyLoadView(import('@views/admin/health_data_fields')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm'],
    },
    params: {},
  },
  {
    path: '/admin/units/:id?',
    name: 'admin/units',
    component: () => lazyLoadView(import('@views/admin/units')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm'],
    },
    params: {},
  },
  {
    path: '/admin/specialities/:id?',
    name: 'admin/specialities',
    component: () => lazyLoadView(import('@views/admin/specialities')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
      rule: ['adm'],
    },
    params: {},
  },
  {
    path: '/admin/exam_categories/:id?',
    name: 'admin/exam_categories',
    component: () => lazyLoadView(import('@views/admin/exam_categories')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/db_view',
    name: 'admin/db_view',
    component: () => lazyLoadView(import('@views/admin/DatabaseView')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/clients_pending_doctor/:id?',
    name: 'admin/clients_pending_doctor',
    component: () =>
      lazyLoadView(import('@views/admin/users_clients_pending_doctor')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },
  {
    path: '/admin/clients_pending_profile/:id?',
    name: 'admin/clients_pending_profile',
    component: () =>
      lazyLoadView(import('@views/admin/users_clients_pending_profile')),
    meta: {
      authRequired: true,
      workspace: 'ADMIN',
    },
  },

  // GENERIC
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => lazyLoadView(import('@views/resetPassword')),
    meta: {},
  },

  // VISITANT

  {
    path: '/post/:id?',
    name: 'post',
    component: () => lazyLoadView(import('@views/visitant/post')),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/page/:id?',
    name: 'page',
    component: () => lazyLoadView(import('@views/visitant/page')),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/blog/:id?',
    name: 'blog',
    component: () => lazyLoadView(import('@views/visitant/blog')),
    meta: {
      authRequired: false,
    },
  },

  /* {
    path: '/profile',
    name: 'profile',
    component: () => lazyLoadView(import('@views/old/profile')),
    meta: {
      authRequired: true,
    },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  }, */
  {
    path: '/profile/:username',
    name: 'username-profile',
    component: () => lazyLoadView(import('@views/old/profile')),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store
          // Try to fetch the user's information by their username
          .dispatch('users/fetchUser', {
            username: routeTo.params.username,
          })
          .then((user) => {
            // Add the user to the route params, so that it can
            // be provided as a prop for the view component below.
            routeTo.params.user = user
            // Continue to the route.
            next()
          })
          .catch(() => {
            // If a user with the provided username could not be
            // found, redirect to the 404 page.
            next({
              name: '404',
              params: {
                resource: 'User',
              },
            })
          })
      },
    },
    // Set the user from the route params, once it's set in the
    // beforeResolve route guard.
    props: (route) => ({
      user: route.params.user,
    }),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        let routeTmp = defaults.routeLogOut[store.getters['auth/loggedIn']]
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: routeTmp || 'login',
              }
            : {
                ...routeFrom,
              }
        )
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => lazyLoadView(import('@views/_404')),
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    name: '*',
    // redirect: '404',
    component: () => lazyLoadView(import('@views/_404')),
  },
  {
    path: '/test',
    name: 'test',
    component: () => lazyLoadView(import('@views/test')),
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView, params = {}) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading'),
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout'),
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
