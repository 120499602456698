import firebase from 'firebase/app';

export const state = {
  registerSuccess: false,
  registerFail: false,
}

export const getters = {
  getRegisterSuccess: () => state.registerSuccess,
  getRegisterFail: () => state.registerFail,
};

export const actions = {
  async registerCompany({ dispatch }, payload) {
    await dispatch('createAuthenticationCompanyAccount', {
      email: payload.contact,
      password: payload.password,
      name: payload.name,
      companyName: payload.corporateName,
    });

    await dispatch('createFirestoreCompanyAccount', payload);
  },

  async createAuthenticationCompanyAccount({ commit }, authenticationData) {
    await firebase.functions().httpsCallable('auth-companyRegister')(authenticationData);
  },

  async createFirestoreCompanyAccount({ commit }, companyData) {
    const company = await firebase.firestore().collection('companies')
                                              .where('contact', '==', companyData.contact)
                                              .get();

    const companyId = company.docs[0].id;

    delete companyData.password;

    try {
      await firebase.firestore().collection('companies').doc(companyId).set(companyData, { merge: true });

      await commit('setCompanyRegisterSuccessMessage', false);
      await commit('setCompanyRegisterSuccessMessage', true);
    } catch (error) {
      await commit('setCompanyRegisterFailMessage', false);
      await commit('setCompanyRegisterFailMessage', true);
    }
  },
};

export const mutations = {
  setCompanyRegisterSuccessMessage($state, status) {
    const stateCopy = $state;
    stateCopy.registerSuccess = status;
  },

  setCompanyRegisterFailMessage($state, status) {
    const stateCopy = $state;
    stateCopy.registerFail = status;
  },
};
