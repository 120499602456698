import firebase from 'firebase/app';
import moment from 'moment';
// import {chatService} from '@services';



// let configWeb = null;
export const WORKSPACE = require ('@common/status').WORKSPACE;


export const state = {
  highlighteds: [],
  contacts: [],
  current: null,
  currentId: null,
  currentMessages: [],
  userKey: null,
  newMessages: 0,
};

// let userId = null;
let unsubscribe = null;
let workspace = null;
let userId = null;
let companyId = null;

let unsubscribeMessages = null;

let preventUpdateLoopProfile = {};

export const actions = {
  init ({ commit , rootState}) {
    // console.log('chat init');
  },

  async startChat({commit, rootState, state, dispatch}, {user, type='user'}) {
    if(type==='user') {
      if(!user.id) {
        console.error('Id not found', user);
        return;
      }
      if(user.id===state.userKey) {
        console.error('Não é possível conversar consigo mesmo', user);
        return;
      }
      let exist = null;

      // let usersObjs = await firebase.firestore().collection('chatTalks').where("userIds", "array-contains", id).where("userIds", "array-contains", userId).get();
      // usersObjs.forEach((userObj)=>{
      //   exist = true;
      // });
      for(let contact of state.contacts) {
        if(contact.userIds && contact.userIds.includes(user.id)) {
          exist = contact.id;
        }
      }
      if(exist) {
        dispatch('setCurrent', exist);
      }
      else {
        let id = firebase.firestore().collection('chatTalks').doc().id;
        let obj = {
          companyId: companyId || '',
          last: {},
          userIds: [user.id, companyId?'COMPANY':userId],
          users: {}
        };
        obj.users[user.id] = {
          id: user.id,
          profileImageUrl: user.imgUrl,
          name: user.name
        };
        let cUser = rootState.auth.currentUser;
        if(!cUser || !cUser.id) {
          console.error('cUser.id not found');
          return;
        }
        obj.users[companyId?'COMPANY':userId] = {
          id: cUser.id,
          profileImageUrl: cUser.profileImageUrl || '',
          name: (cUser.name || '') + (cUser.lastName?' '+cUser.lastName:'')
        };
        await firebase.firestore().collection('chatTalks').doc(id).set(obj);

        dispatch('setCurrent', id);
      }

    }
    else {
      console.error('type not implemented: ', type);
    }

  },
  async sendMsg({commit , rootState, state}, {chatId, msg}) {
    let chat = state.contacts.find((a)=>a.id===chatId);
    if(chat) {
      let promises = [];
      let msgObj = {
        msg: msg,
        date: firebase.firestore.FieldValue.serverTimestamp(),
        type: msg,
        sender: state.userKey,
        viewed: false
      };
      promises.push(firebase.firestore().collection('chatTalks').doc(chatId).collection('messages').add(msgObj));
      promises.push(firebase.firestore().collection('chatTalks').doc(chatId).set({last:msgObj}, {merge: true}));
      await Promise.all(promises);
    }
    else {
      console.error('Erro, chat not found');
    }
  },

  async setCurrent({commit , rootState, state, dispatch}, chatId) {
  //  console.log('chatId', chatId);
    let chat = state.contacts.find((a)=>a.id===chatId);
    if(!chat) {
      const chatObj = await firebase.firestore().collection('chatTalks').doc(chatId).get();
      if(!chatObj.exists) {
        console.error('chat not found');
        return false;
      }
      let chatTmp = chatObj.data();
      chatTmp.id = chatObj.id;
      if(!rootState.auth.currentUser || !rootState.auth.currentUser.id) {
        console.error('currentUser Not Found');
        return false;
      }
      if(chatTmp.userIds && chatTmp.userIds.includes(rootState.auth.currentUser.id)) {
        chat = chatTmp;
      }
      else {
        console.error('currentUser Not Found on that chat');
        return false;
      }

    }
    if(chatId!==state.currentId) {
      if(unsubscribeMessages) {
        unsubscribeMessages();
      }
      unsubscribeMessages = firebase.firestore().collection('chatTalks').doc(chatId).collection('messages').onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          if (change.type === "added" || change.type === "modified") {
            let value = {...change.doc.data(), id: change.doc.id, isLocal: change.doc.metadata.hasPendingWrites};
            if(!value.date) {
              if(change.doc.metadata.hasPendingWrites) {
                value.dateMoment = moment();
              }
              else {
                console.error('Msg without date');
                return;
              }
            }
            else {
              value.dateMoment = moment(value.date.toDate());
            }
            value.dateDay = value.dateMoment.format('YYYYMMDD');
            commit('addMessage', value);
            if(!value.viewed && value.sender!==state.userKey) {
              await firebase.firestore().collection('chatTalks').doc(chatId).collection('messages').doc(value.id).update({viewed: true});
            }
          }
          else if(change.type === "removed") {
            commit('removeMessage', change.doc.id);
          }
          else {
            console.error('Dados estranhos recebidos');
          }
        });
      });
      dispatch('readMsg');
    }
    commit('addHighlighteds', chat);
    commit('setCurrent', chat);
    commit('resetMessages');
    if(!chat.highlighted) {
      await firebase.firestore().collection('chatTalks').doc(chatId).update({highlighted: true});
    }
  },
  async readMsg({commit, rootState, state}) {
    if(!state.currentId) {
      return;
    }
    let chat = state.contacts.find((a)=>a.id===state.currentId);
    if(!chat) {
      console.error('chat not found');
      return false;
    }
    if(chat.last && chat.last.sender && !chat.last.viewed && rootState.auth && rootState.auth.currentUser && rootState.auth.currentUser.id!==chat.last.sender){
      await firebase.firestore().collection('chatTalks').doc(state.currentId).set({last: {viewed: true}}, {merge: true});
    }
  },

  async close({commit , rootState, state}, chatId) {
    let chat = state.contacts.find((a)=>a.id===chatId);
    if(!chat) {
      console.error('chat not found');
      commit('setCurrent', null);
      return false;
    }

    commit('removeHighlighteds', chat);
    if(state.currentId===chatId) {
      if(unsubscribeMessages) {
        unsubscribeMessages();
        unsubscribeMessages = null;
      }
      commit('setCurrent', null);
    }
    if(chat.highlighted) {
      await firebase.firestore().collection('chatTalks').doc(chatId).update({highlighted: false});
    }
  },

  async changeAuth({commit, rootState, state, dispatch}) {
   // console.log('changeAuth', rootState.auth);

    let hasChange = false;
    if (workspace !== rootState.auth.workspace) {
      hasChange = true;
    }
    else if (!rootState.auth.currentUser || userId !== rootState.auth.currentUser.id) {
      hasChange = true;
    }
    else if (rootState.auth.currentWorkspace === WORKSPACE.COMPANY && (!rootState.auth.currentCompany || companyId !== rootState.auth.currentCompany.id)) {
      hasChange = true;
    }

    if (!hasChange) {
      return;
    }

    if (unsubscribe) {
      unsubscribe();
    }
    commit('resetChat');

    workspace = rootState.auth.currentWorkspace;
    let ref = null;
    switch (workspace) {
      case WORKSPACE.CLIENT:
        if (rootState.auth.currentUser.id) {
          userId = rootState.auth.currentUser.id;
          ref = firebase.firestore().collection('chatTalks').where("userIds", "array-contains", userId);
          commit('setUserKey', userId);
        }
        break;
      case WORKSPACE.COMPANY:
        if (rootState.auth.currentCompany.id) {
          companyId = rootState.auth.currentCompany.id;
          ref = firebase.firestore().collection('chatTalks').where("companyId", "==", companyId);
          commit('setUserKey', 'COMPANY');
        }
        break;
      case WORKSPACE.ADMIN:
        ref = firebase.firestore().collection('chatTalks').where("isAdmin", "==", true);
        commit('setUserKey', 'ADMIN');
    }
    if (!workspace || !ref) {
      console.log('Not get chatTalks: '+workspace);
      return;
    }

    unsubscribe = ref.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          let value = {...change.doc.data(), id: change.doc.id};
          value.usersShow = [];
          for(const userId in value.users) {
            if(!value.userIds.includes(userId)) {
              delete value.users[userId];
            }
            else if(state.userKey!==userId) {
              value.usersShow.push(value.users[userId]);
            }
          }

          let cUser = rootState.auth.currentUser || {};

          let userDb = {
            id: value.users[state.userKey].id || '',
            profileImageUrl: value.users[state.userKey].profileImageUrl || '',
            name: value.users[state.userKey].name || ''
          };

          let userTmp = {
            id: cUser.id || '',
            profileImageUrl: cUser.profileImageUrl || '',
            name: (cUser.name || '') + (cUser.lastName?' '+cUser.lastName:'')
          };

          if(!value.users[state.userKey] || !_.isEqual(userDb, userTmp)) {
            if(!preventUpdateLoopProfile[value.id]) {
              preventUpdateLoopProfile[value.id] = 0;
            }
            preventUpdateLoopProfile[value.id]++;
            if(preventUpdateLoopProfile[value.id]<6) {
              console.log('Update profile on chatId', value.id);
              let userTmp2 = {};
              userTmp2[userTmp.id] = userTmp;
              firebase.firestore().collection('chatTalks').doc(value.id).set({
                users: userTmp2
              }, {merge: true});
            }
            else {
              console.error('Detected Possible Loop update profile, chatId', value.id);
            }


          }
          commit('addChat', value);
          if(state.currentId===value.id) {
            dispatch('readMsg');
          }
        }
        else if(change.type === "removed") {
          commit('removeChat', change.doc.id);
        }
        else {
          console.error('Dados estranhos recebidos');
        }
      });
    });
  },
};

export const mutations = {
  resetChat (state) {
    state.contacts = [];
    state.highlighteds = [];
    state.currentMessages = [];
    state.current = null;
    state.currentId = null;
    state.userKey = null;
    state.newMessages = 0;
    preventUpdateLoopProfile = [];
  },
  setUserKey(state, value) {
    state.userKey = value;
  },
  addChat (state, newValue) {
    let key = state.contacts.findIndex((a)=>a.id===newValue.id);
    if(key>=0) {
      state.contacts.splice(key, 1, newValue);
    }
    else {
      state.contacts.push(newValue);
    }
    state.newMessages = state.contacts.reduce(function(result, contact){
      if(contact.last && contact.last.msg && !contact.last.viewed && state.userKey!==contact.last.sender){
        return result+1;
      }
      return result;
    }, 0);

  //  console.log('state.newMessages', state.newMessages);
    let key2 = state.highlighteds.findIndex((a)=>a.id===newValue.id);
    if(key2>=0) {
      if(newValue.highlighted) {
        state.contacts.splice(key, 1, newValue);
      }
      else {
        state.highlighteds.splice(key2, 1);
      }
    }
    else {
      if(newValue.highlighted) {
        state.highlighteds.push(newValue);
      }
    }

  },
  removeChat (state, chatId) {
    let key = state.contacts.findIndex((a)=>a.id===chatId);
    if(key) {
      state.contacts.splice(key, 1);
    }
    let key2 = state.highlighteds.findIndex((a)=>a.id===chatId);
    if(key2) {
      state.highlighteds.splice(key2, 1);
    }
  },
  addHighlighteds(state, chat) {
    let key = state.highlighteds.findIndex((a)=>a.id===chat.id);
    if(key<0) {
      state.highlighteds.push(chat);
    }
  },
  removeHighlighteds(state, chat) {
    let key = state.highlighteds.findIndex((a)=>a.id===chat.id);
    if(key>=0) {
      state.highlighteds.splice(key, 1);
    }
  },
  setCurrent(state, chat) {
    if(chat) {
      state.current = chat;
      state.currentId = chat.id;
    }
    else {
      state.current = null;
      state.currentId = null;
      state.currentMessages = [];
    }
  },
  resetMessages(state) {
    state.currentMessages = [];
  },
  addMessage(state, message) {
    let key = state.currentMessages.findIndex((a)=>a.id===message.id);
    if(key>=0) {
    //  state.currentMessages[key] = message;
    //  state.currentMessages = Array.from(state.currentMessages);

      state.currentMessages.splice(key, 1, message);
    }
    else {
      let i = 0;
      for( i=0; i<state.currentMessages.length;i++) {
        if(message.date && state.currentMessages[i].date && state.currentMessages[i].date.seconds>message.date.seconds) {
          break;
        }
      }
      state.currentMessages.splice(i, 0, message);
     // state.currentMessages = Array.from(state.currentMessages);
    }
  },
  removeMessage(state, message) {
    // TODO remove message
    console.warn('TODO remove message');
  }

};
