<script>
  let {languages, setLocale} = require('@i18n');

  export default {
    props: {
      showName: {
        type: Boolean,
        default: false,
      }
    },

    data () {
      return {
        langs: languages,
        sessionMenu: {
          opened: false,
          X: 0,
          y: 0,
        },
      }
    },

    computed: {
      localeSelected : {
        get() {
          return this.langs[this.$i18n.locale] || sessionStorage.getItem('locale');
        },
        set(value) {
          const ref = (value.hreflang || '').replace('_','-');
          if(this.langs[ref]) {
            value.hreflang = ref;
            this.$i18n.locale = ref;
            window.VueI18n.localeDatabase = this.langs[ref].database;
            sessionStorage.setItem('locale', JSON.stringify(value));
          }
          document.documentElement.setAttribute('lang', value);
        }
      }
    },

    beforeMount() {
      // this.localeSelected = this.languages[this.$i18n.locale] || null;
      const loc = sessionStorage.getItem('locale') || null;
      if(loc) {
        // console.log('here', JSON.parse(loc));
        this.localeSelected = JSON.parse(loc);
      } else {
        const locModel = {
          database: 'ptbr',
          flag: 'br',
          hreflang: 'pt-BR',
          name: 'Português',
          vuetify: 'pt',
        };
        this.localeSelected = locModel;
        sessionStorage.setItem('locale', JSON.stringify(locModel))
      }
    },

    methods: {
      changeLanguage(el) {
        // console.log('lang',el);
        this.localeSelected = el;
      },

      openSessionMenu(e) {
        e.preventDefault();
        this.sessionMenu["opened"] = false;
        this.sessionMenu["X"] = e.clientX
        this.sessionMenu["Y"] = e.clientY
        this.$nextTick(() => {
          this.sessionMenu["opened"] = true;
        });
      },
    }
  }
</script>

<template>
  <div :class="[$style.localeChanger, 'locale-localeChanger']">
    <div v-if="langs" :class="$style.localeField">

      <div v-if="localeSelected" class="lang-preview">
        <img v-if="localeSelected.flag" :class="['flag-image', $style.flag]" :src="require('@assets/flags/'+localeSelected.flag+'.png')"/>
        <button
          class="button--lang"
          @click="openSessionMenu"
        >
          <v-icon class="button-icon" color="blue darken-3" small>mdi-autorenew</v-icon>
        </button>
      </div>

      <v-menu
        v-model="sessionMenu.opened"
        :position-x="sessionMenu.X"
        :position-y="sessionMenu.Y"
        absolute
        :close-on-content-click="true"
        content-class="styledMenu"
        bottom
        nudge-left="220"
        max-width="250px"
      >
        <v-list class="styledMenu--containerList">
          <v-list-item class="styledMenu--containerList__head">
            <span class="text-body-3 text-bold text-gray">{{$t('form.lang.label')}}</span>
          </v-list-item>

          <v-list-item v-for="(lang, index) in langs" :key="index" :class="['styledMenu--containerList__item', $style.flagSpan]" @click="changeLanguage(lang)">
            <v-list-item-icon class="styleItem__content-icon">
              <img v-if="lang.flag" class="flag-image" :src="require('@assets/flags/'+lang.flag+'.png')"/>
            </v-list-item-icon>

            <v-list-item-content class="styleItem__content">
              <span class="text-body-2 text-bold text-gray">{{lang.name?lang.name:''}}</span>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>

    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';

.lang-preview {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid $soft-gray-1;
  border-radius: 5px;
  padding: 4px;

  .button--lang {
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: -4px;
    right: -8px;
    z-index: $layer-1;
    border: 1px solid $soft-gray-1;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px rgba($blue-scale-3, .3);
    background-color: rgba(#fff, .9);
    margin: 0;
  }

  .flag-image {
    z-index: $layer-root;
  }
}

.styleItem {
  border: 1px solid red;

  &:hover {
    cursor: pointer;
  }

  &__content-icon {
    width: fit-content;
    min-width: 60px;
    padding: 0;
    margin: 0;
    // border: 1px solid blue;
  }
}

.flag-image {
  border-radius: 3px;
  max-height: 32px;
  width: auto;
  max-width: 60px;
  object-fit: cover;
  // cursor: pointer;
}

</style>

<style lang="scss" module>
  @import '@design';

  .localeChanger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 100px;
  }

  .select {
    // border: 1px solid $color-primary;
    width: fit-content;
    max-width: 40px;
  }

  .flagSpan span{
    justify-content: space-between;
    flex-direction: row;
  }

</style>
