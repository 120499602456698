import Vue from "vue";
import VueI18n from "vue-i18n";
import localeEnUS from '@i18n/en-US'
import localePtBR from '@i18n/pt-BR'
const fcmI18n = require('@i18n');

let {i18nConfig, getLocale, languages} = fcmI18n;

let locale = getLocale();
document.documentElement.setAttribute('lang', locale);
Vue.use(VueI18n);

let fcmMissingKey = null;
if(process.env.NODE_ENV !== 'production') {
  window.fcmMissingKeys = {};
  fcmMissingKey = function(language, key) {
    window.fcmMissingKeys[key] = ''
  }
}

const i18n = new VueI18n({
  ...i18nConfig,
  locale: locale,
  messages: {
    'en-US': localeEnUS,
    'pt-BR': localePtBR,
  },
  missing: fcmMissingKey,
});

if(languages[locale]) {
  i18n.localeDatabase = languages[locale].database;
}

window.VueI18n = i18n;
export default i18n;