import firebase from 'firebase/app';

export const state = {
  hasNotification: false,
};

export const getters = {
  getHasNotification: () => (state.hasNotification),
};

export const actions = {
  async updateHasNotification( { commit } ) {
    commit('setHasNotification', false);

    const doubts = await firebase.firestore().collection('clientsDoubts').where('seen', '==', false).get();

    commit('setHasNotification', doubts.docs.length > 0)
  },
  async seenDoubt({ commit }, doubtId) {
    await firebase.firestore().collection('clientsDoubts').doc(doubtId).update({ seen: true });
  },
};

export const mutations = {
  setHasNotification($state, boolean) {
    const stateCopy = $state;
    stateCopy.hasNotification = boolean;
  },
};
