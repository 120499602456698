'use strict'
// Object.defineProperty(exports, "__esModule", { value: true });
let toExport = {}

// baseado nos status do iugu
toExport.PaymentStatus = {
  pending: 'pending',
  paid: 'paid',
  canceled: 'canceled',
  draft: 'draft', // rascunho
  partially_paid: 'partially_paid',
  refunded: 'refunded',
  expired: 'expired',
  in_protest: 'in_protest',
  chargeback: 'chargeback',
  in_analysis: 'in_analysis',

  invalidPaymentMethod: 'invalidPaymentMethod',
  invalidPaymentCard: 'invalidPaymentCard',
  invalidPaymentUser: 'invalidPaymentUser',
  invalidPaymentUnknown: 'invalidPaymentUnknown',
  invalidPaymentAlreadyEarly: 'invalidPaymentAlreadyEarly',

  partial: 'partial',
}

toExport.OrderStatus = {
  waitPayment: 'waitPayment',
  waitConfirm: 'waitConfirm',
  confirmed: 'confirmed',
  inPreparation: 'inPreparation',
  waitTakeout: 'waitTakeout',
  onDelivery: 'onDelivery',
  deliveryReady: 'deliveryReady',
  takeoutReady: 'takeoutReady',
  canceled: 'canceled',
}

toExport.companyOrderStatus = {
  waitPayment: {
    id: 'waitPayment',
    color: '#d3c806',
  },
  waitConfirm: {
    id: 'waitConfirm',
    color: '#d3c806',
    companyEdit: true,
  },
  confirmed: {
    id: 'confirmed',
    color: '#2473C2',
    companyEdit: true,
  },
  inPreparation: {
    id: 'inPreparation',
    color: '#5a2997',
    companyEdit: true,
  },
  waitDelivery: {
    id: 'waitDelivery',
    color: '#F18400',
    companyEdit: true,
  },
  waitTakeout: {
    id: 'waitTakeout',
    color: '#F18400',
    companyEdit: true,
  },
  onDelivery: {
    id: 'onDelivery',
    color: '#F18400',
    companyEdit: true,
  },
  deliveryReady: {
    id: 'deliveryReady',
    color: '#0FA435',
    companyEdit: true,
  },
  takeoutReady: {
    id: 'takeoutReady',
    color: '#0FA435',
    companyEdit: true,
  },
  canceled: {
    id: 'canceled',
    color: '#FF0000',
    companyEdit: true,
  },
}

toExport.companyOrderDeliveryType = {
  pickup: {
    id: 'pickup',
    icon: 'pan_tool',
  },
  delivery: {
    id: 'delivery',
    icon: 'motorcycle',
  },
  onSite: {
    id: 'onSite',
    icon: 'pan_tool',
  },
}

toExport.companyOrderStatusSequence = {
  pickup: [
    'waitConfirm',
    'confirmed',
    'inPreparation',
    'waitTakeout',
    'takeoutReady',
  ],
  delivery: [
    'waitConfirm',
    'confirmed',
    'inPreparation',
    'onDelivery',
    'deliveryReady',
  ],
  onSite: [
    'waitConfirm',
    'confirmed',
    'inPreparation',
    'waitTakeout',
    'takeoutReady',
  ],
}

toExport.companyOrderPaymentStatus = {
  wait: {
    id: 'wait',
    color: '#d3c806',
  },
  waitConfirm: {
    id: 'waitConfirm',
    color: '#d3c806',
  },
  confirm: {
    id: 'confirm',
    color: '#0FA435',
  },
  canceledUser: {
    id: 'canceledUser',
    color: '#FF0000',
  },
  canceledCompany: {
    id: 'canceledCompany',
    color: '#FF0000',
  },
  operatorCardCanceled: {
    id: 'operatorCardCanceled',
    color: '#FF0000',
  },
  extorted: {
    id: 'extorted',
    color: '#FF0000',
  },
  orderCanceled: {
    id: 'orderCanceled',
    color: '#FF0000',
  },
}

toExport.rules = {
  adm: ['adm', 'sell'],
  cli: [],
  comp: ['adm', 'edit'],
}

toExport.apiErrorCode = {
  ok: 'ok',
  permissionDenied: 'permissionDenied',
  statusError: 'statusError',
  inputError: 'inputError',
  unknown: 'unknown',
  serverError: 'serverError',
  exhaustedAttempts: 'exhaustedAttempts',
  clientError: 'clientError',

  requireOtp: 'requireOtp',
  revokeToken: 'revokeToken',
  signUserNotFound: 'signUserNotFound',
  pdfGenerateError: 'pdfGenerateError',
  uploadBucketError: 'uploadBucketError',
  signatureError: 'signatureError',
  activateTokenError: 'activateTokenError',
}

// response example: {isOk:false, errorCode: orderErrorCode.unknown, msg:''}

toExport.OrderPaymentMethodType = {
  OnApp: 'OnApp',
  whenReceive: 'whenReceive',
}

toExport.OrderControlStatus = {
  onEdit: 'onEdit',
  waitFinishPayment: 'waitFinishPayment', // Order não pode ser editada
  paymentComplete: 'paymentComplete',
}

toExport.unitLst = {
  null: null,
  ml: 'ml',
  milhoesmm3: 'milhões/mm³',
  percent: '%',
  gDL: 'g/dL',
  fL: 'fL',
}

toExport.WORKSPACE = {
  COMPANY: 'COMPANY',
  ADMIN: 'ADMIN',
  CLIENT: 'CLIENT',
}

toExport.ProfileStatus = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  INCOMPLETE: 'incomplete',
}

toExport.ScheduleStatus = {
  INCOMPLETE: 'incomplete', // default status, needs to be active by the customer.
  AVAILABLE: 'available', // *p -> CRUD
  LOCKED: 'locked', // locked with estimated unlock time, use case: "waiting for subscription payment".
  GIFTED: 'gifted', // Admin : allow access to schedule. remove access turn status "INACTIVE"
  BLOCKED: 'blocked', // Admin : locked without expected unlock time. *p -> (RUD). use case: "admin blocked access".
  CANCELED: 'canceled', //
}

toExport.ScheduleEventType = {
  EVENT: 'EVENT',
  LOCKER: 'LOCKER',
  RECURRING: 'RECURRING',
}

toExport.ScheduleReminderType = {
  PUNCTUAL: 'punctual',
  BEFORE: 'before',
}

toExport.ScheduleEventStatus = {
  PAST: 'PAST',
  FUTURE: 'FUTURE',
  PRESENT: 'PRESENT',
  RECURRING: 'RECURRING', // only to RECURRING type
  ARCHIVED: 'ARCHIVED',
  LOCKED: 'LOCKED',
}

module.exports = toExport

// exports.default = exports;
// if(typeof module!=='undefined') {
//   module.exports = exports;
// }
